/* CLOSURE CONCILIATION */
export const SET_COUNT_CLOSURES_CONCILIATION = 'SET_COUNT_CLOSURES_CONCILIATION';

export const READ_CLOSURES_CONCILIATION = 'READ_CLOSURES_CONCILIATION';
export const READ_CLOSURES_CONCILIATION_FAILED = 'READ_CLOSURES_CONCILIATION_FAILED';
export const READ_CLOSURES_CONCILIATION_SUCCESS = 'READ_CLOSURES_CONCILIATION_SUCCESS';

export const RETRY_CLOSURES_BANCATLAN = 'RETRY_CLOSURES_BANCATLAN';
export const RETRY_CLOSURES_BANCATLAN_FAILED = 'RETRY_CLOSURES_BANCATLAN_FAILED';
export const RETRY_CLOSURES_BANCATLAN_SUCCESS = 'RETRY_CLOSURES_BANCATLAN_SUCCESS';

export const RETRY_CLOSURE = 'RETRY_CLOSURE';
export const RETRY_CLOSURE_FAILED = 'RETRY_CLOSURE_FAILED';
export const RETRY_CLOSURE_SUCCESS = 'RETRY_CLOSURE_SUCCESS';

export const UPLOAD_RESUME_CLOSURE = 'UPLOAD_RESUME_CLOSURE';
export const UPLOAD_RESUME_CLOSURE_FAILED = 'UPLOAD_RESUME_CLOSURE_FAILED';
export const UPLOAD_RESUME_CLOSURE_SUCCESS = 'UPLOAD_RESUME_CLOSURE_SUCCESS';
export const UPLOAD_RESUME_CLOSURE_RESET = 'UPLOAD_RESUME_CLOSURE_RESET';

export const GET_RESUMES_TO_EXPORT = 'GET_RESUMES_TO_EXPORT';
export const GET_RESUMES_TO_EXPORT_FAILED = 'GET_RESUMES_TO_EXPORT_FAILED';
export const GET_RESUMES_TO_EXPORT_SUCCESS = 'GET_RESUMES_TO_EXPORT_SUCCESS';
