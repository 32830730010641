import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import fetchJSON from '../../utils/fetchJSON';

import { GET_GLOBAL_CONFIGS, UPDATE_GLOBAL_CONFIGS } from '../../constants/actionTypes';

import { getGlobalConfigsFailed, getGlobalConfigsSuccess, updateGlobalConfigsFailed, updateGlobalConfigsSuccess } from './actions';

function* getGlobalConfigs({ payload: data }) {
  const options = {
    data,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/configs`, options);

    yield put(getGlobalConfigsSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(getGlobalConfigsFailed(message));
  }
}
function* updateGlobalConfigs({ payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/configs`, options);

    yield put(updateGlobalConfigsSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(updateGlobalConfigsFailed(message));
  }
}

export function* watchGetGlobalConfigs() {
  yield takeEvery(GET_GLOBAL_CONFIGS, getGlobalConfigs);
}
export function* watchUpdateGlobalConfigs() {
  yield takeEvery(UPDATE_GLOBAL_CONFIGS, updateGlobalConfigs);
}

function* GlobalConfigSaga() {
  yield all([fork(watchGetGlobalConfigs), fork(watchUpdateGlobalConfigs)]);
}

export default GlobalConfigSaga;
