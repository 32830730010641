/** PRODUCTS **/
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';

export const READ_PRODUCTS = 'READ_PRODUCTS';
export const READ_PRODUCTS_FAILED = 'READ_PRODUCTS_FAILED';
export const READ_PRODUCTS_SUCCESS = 'READ_PRODUCTS_SUCCESS';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
