import {
  SET_COUNT_CURRENCIES,
  CREATE_CURRENCY,
  CREATE_CURRENCY_FAILED,
  CREATE_CURRENCY_SUCCESS,
  READ_CURRENCIES,
  READ_CURRENCIES_FAILED,
  READ_CURRENCIES_SUCCESS,
  UPDATE_CURRENCY,
  UPDATE_CURRENCY_FAILED,
  UPDATE_CURRENCY_SUCCESS,
  DELETE_CURRENCY,
  DELETE_CURRENCY_FAILED,
  DELETE_CURRENCY_SUCCESS
} from '../../constants/actionTypes';

const INIT_COUNT_CURRENCIES = {
  totalCurrencies: 0
};
const INIT_CREATE_CURRENCY_STATE = {
  loading: false,
  error: undefined,
  currencies: undefined
};
const INIT_READ_CURRENCIES_STATE = {
  loading: false,
  error: undefined,
  currencies: undefined
};

export const CreateCurrency = (state = INIT_CREATE_CURRENCY_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_CURRENCY:
      return {
        loading: true,
        error: undefined,
        currency: undefined
      };
    case CREATE_CURRENCY_FAILED:
      return {
        loading: false,
        error: payload,
        currency: undefined
      };
    case CREATE_CURRENCY_SUCCESS:
      return {
        loading: false,
        error: undefined,
        currency: payload
      };
    default:
      return state;
  }
};

export const CountCurrencies = (state = INIT_COUNT_CURRENCIES, { type, payload }) => {
  switch (type) {
    case SET_COUNT_CURRENCIES:
      return {
        totalCurrencies: payload
      };
    default:
      return state;
  }
};

export const ReadCurrencies = (state = INIT_READ_CURRENCIES_STATE, { type, payload }) => {
  switch (type) {
    case READ_CURRENCIES:
      return {
        loading: true,
        error: undefined,
        currencies: undefined
      };
    case READ_CURRENCIES_FAILED:
      return {
        loading: false,
        error: payload,
        currencies: undefined
      };
    case READ_CURRENCIES_SUCCESS:
      return {
        loading: false,
        error: undefined,
        currencies: payload
      };
    default:
      return state;
  }
};
export const UpdateCurrency = (state = INIT_CREATE_CURRENCY_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_CURRENCY:
      return {
        loading: true,
        error: undefined,
        currency: undefined
      };
    case UPDATE_CURRENCY_FAILED:
      return {
        loading: false,
        error: payload,
        currency: undefined
      };
    case UPDATE_CURRENCY_SUCCESS:
      return {
        loading: false,
        error: undefined,
        currency: payload
      };
    default:
      return state;
  }
};
export const DeleteCurrency = (state = INIT_CREATE_CURRENCY_STATE, { type, payload }) => {
  switch (type) {
    case DELETE_CURRENCY:
      return {
        loading: true,
        error: undefined,
        currency: undefined
      };
    case DELETE_CURRENCY_FAILED:
      return {
        loading: false,
        error: payload,
        currency: undefined
      };
    case DELETE_CURRENCY_SUCCESS:
      return {
        loading: false,
        error: undefined,
        currency: payload
      };
    default:
      return state;
  }
};
