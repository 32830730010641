import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import PublicLayout from '../layouts/PublicLayout';
import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './routes';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {publicProtectedFlattenRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <PublicLayout>
                <route.element />
              </PublicLayout>
            }
          />
        ))}

        {authProtectedFlattenRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={<PrivateRoute pageTitle={route.name} roles={route.roles} component={route.element} roleTypes={route.roleTypes} />} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
