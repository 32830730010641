import {
  GET_GLOBAL_CONFIGS,
  GET_GLOBAL_CONFIGS_FAILED,
  GET_GLOBAL_CONFIGS_SUCCESS,
  UPDATE_GLOBAL_CONFIGS,
  UPDATE_GLOBAL_CONFIGS_FAILED,
  UPDATE_GLOBAL_CONFIGS_SUCCESS
} from '../../constants/actionTypes';

export const getGlobalConfigs = (payload) => ({
  payload,
  type: GET_GLOBAL_CONFIGS
});
export const getGlobalConfigsFailed = (error) => ({
  payload: error,
  type: GET_GLOBAL_CONFIGS_FAILED
});
export const getGlobalConfigsSuccess = (payload) => ({
  payload,
  type: GET_GLOBAL_CONFIGS_SUCCESS
});

export const updateGlobalConfigs = (payload) => ({
  payload,
  type: UPDATE_GLOBAL_CONFIGS
});
export const updateGlobalConfigsFailed = (error) => ({
  payload: error,
  type: UPDATE_GLOBAL_CONFIGS_FAILED
});
export const updateGlobalConfigsSuccess = (payload) => ({
  payload,
  type: UPDATE_GLOBAL_CONFIGS_SUCCESS
});
