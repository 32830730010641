import {
  SET_COUNT_CHANNELS,
  CREATE_CHANNEL,
  CREATE_CHANNEL_FAILED,
  CREATE_CHANNEL_SUCCESS,
  READ_CHANNELS,
  READ_CHANNELS_FAILED,
  READ_CHANNELS_SUCCESS,
  UPDATE_CHANNEL,
  UPDATE_CHANNEL_FAILED,
  UPDATE_CHANNEL_SUCCESS,
  DELETE_CHANNEL,
  DELETE_CHANNEL_FAILED,
  DELETE_CHANNEL_SUCCESS
} from '../../constants/actionTypes';

export const setCountChannels = (payload) => ({ payload, type: SET_COUNT_CHANNELS });

export const createChannel = (payload) => ({ payload, type: CREATE_CHANNEL });
export const createChannelFailed = (payload) => ({ payload, type: CREATE_CHANNEL_FAILED });
export const createChannelSuccess = (payload) => ({ payload, type: CREATE_CHANNEL_SUCCESS });

export const readChannels = (payload) => ({ payload, type: READ_CHANNELS });
export const readChannelsFailed = (payload) => ({ payload, type: READ_CHANNELS_FAILED });
export const readChannelsSuccess = (payload) => ({ payload, type: READ_CHANNELS_SUCCESS });

export const updateChannel = (_id, payload) => ({ _id, payload, type: UPDATE_CHANNEL });
export const updateChannelFailed = (payload) => ({ payload, type: UPDATE_CHANNEL_FAILED });
export const updateChannelSuccess = (payload) => ({ payload, type: UPDATE_CHANNEL_SUCCESS });

export const deleteChannel = (_id) => ({ _id, type: DELETE_CHANNEL });
export const deleteChannelFailed = (payload) => ({ payload, type: DELETE_CHANNEL_FAILED });
export const deleteChannelSuccess = (payload) => ({ payload, type: DELETE_CHANNEL_SUCCESS });
