import {
  SET_COUNT_ISSUER_BINS,
  CREATE_ISSUER_BINS,
  CREATE_ISSUER_BINS_FAILED,
  CREATE_ISSUER_BINS_SUCCESS,
  SEARCH_ISSUER_BINS,
  SEARCH_ISSUER_BINS_FAILED,
  SEARCH_ISSUER_BINS_SUCCESS,
  RESET_SEARCH_ISSUER_BINS,
  READ_ISSUER_BINS,
  READ_ISSUER_BINS_FAILED,
  READ_ISSUER_BINS_SUCCESS,
  UPDATE_STATUS_ISSUER_BINS,
  UPDATE_STATUS_ISSUER_BINS_FAILED,
  UPDATE_STATUS_ISSUER_BINS_SUCCESS,
  UPDATE_ISSUER_BINS,
  UPDATE_ISSUER_BINS_FAILED,
  UPDATE_ISSUER_BINS_SUCCESS,
  DELETE_ISSUER_BINS,
  DELETE_ISSUER_BINS_FAILED,
  DELETE_ISSUER_BINS_SUCCESS
} from '../../constants/actionTypes';

const INIT_COUNT_ISSUER_BINS_STATE = {
  totalIssuerBins: 0
};

const INIT_SEARCH_ISSUER_BINS_STATE = {
  loading: false,
  error: undefined,
  issuerBinFound: undefined,
  currentIssuerBin: undefined
};

const INIT_ISSUER_BINS_STATE = {
  loading: false,
  error: undefined,
  issuerBin: undefined
};
const INIT_READ_ISSUER_BINS_STATE = {
  loading: false,
  error: undefined,
  issuerBins: undefined
};

export const CreateIssuerBins = (
  state = INIT_ISSUER_BINS_STATE,
  { type, payload }
) => {
  switch (type) {
    case CREATE_ISSUER_BINS:
      return {
        loading: true,
        error: undefined,
        issuerBin: undefined
      };
    case CREATE_ISSUER_BINS_FAILED:
      return {
        loading: false,
        error: payload,
        issuerBin: undefined
      };
    case CREATE_ISSUER_BINS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        issuerBin: payload
      };
    default:
      return state;
  }
};

export const CountIssuerBins = (
  state = INIT_COUNT_ISSUER_BINS_STATE,
  { type, payload }
) => {
  switch (type) {
    case SET_COUNT_ISSUER_BINS:
      return {
        totalIssuerBins: payload
      };
    default:
      return state;
  }
};

export const SearchIssuerBins = (
  state = INIT_SEARCH_ISSUER_BINS_STATE,
  { type, payload }
) => {
  switch (type) {
    case SEARCH_ISSUER_BINS:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case SEARCH_ISSUER_BINS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case SEARCH_ISSUER_BINS_SUCCESS: {
      const { issuerBin, issuerBinFound } = payload;
      return {
        ...state,
        loading: false,
        issuerBinFound,
        error: undefined,
        currentIssuerBin: issuerBin
      };
    }
    case RESET_SEARCH_ISSUER_BINS:
      return { ...INIT_SEARCH_ISSUER_BINS_STATE };
    default:
      return state;
  }
};

export const ReadIssuerBins = (
  state = INIT_READ_ISSUER_BINS_STATE,
  { type, payload }
) => {
  switch (type) {
    case READ_ISSUER_BINS:
      return {
        loading: true,
        error: undefined,
        issuerBins: undefined
      };
    case READ_ISSUER_BINS_FAILED:
      return {
        loading: false,
        error: payload,
        issuerBins: undefined
      };
    case READ_ISSUER_BINS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        issuerBins: payload
      };
    default:
      return state;
  }
};

export const UpdateStatusIssuerBins = (
  state = INIT_ISSUER_BINS_STATE,
  { type, payload }
) => {
  switch (type) {
    case UPDATE_STATUS_ISSUER_BINS:
      return {
        loading: true,
        error: undefined,
        issuerBin: undefined
      };
    case UPDATE_STATUS_ISSUER_BINS_FAILED:
      return {
        loading: false,
        error: payload,
        issuerBin: undefined
      };
    case UPDATE_STATUS_ISSUER_BINS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        issuerBin: payload
      };
    default:
      return state;
  }
};

export const UpdateIssuerBins = (
  state = INIT_ISSUER_BINS_STATE,
  { type, payload }
) => {
  switch (type) {
    case UPDATE_ISSUER_BINS:
      return {
        loading: true,
        error: undefined,
        issuerBin: undefined
      };
    case UPDATE_ISSUER_BINS_FAILED:
      return {
        loading: false,
        error: payload,
        issuerBin: undefined
      };
    case UPDATE_ISSUER_BINS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        issuerBin: payload
      };
    default:
      return state;
  }
};
export const DeleteIssuerBins = (
  state = INIT_READ_ISSUER_BINS_STATE,
  { type, payload }
) => {
  switch (type) {
    case DELETE_ISSUER_BINS:
      return {
        loading: true,
        error: undefined,
        issuerBin: undefined
      };
    case DELETE_ISSUER_BINS_FAILED:
      return {
        loading: false,
        error: payload,
        issuerBin: undefined
      };
    case DELETE_ISSUER_BINS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        issuerBin: payload
      };
    default:
      return state;
  }
};
