import fetchJSON from '../../utils/fetchJSON';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as Paygate from '@clinpays/paygate';
import { createNotification, NOTIFICATION_TYPE_ERROR } from 'react-redux-notify';
import { createMessageNotify } from '../../utils/notify';

import {
  CREATE_LINK_PAYMENT,
  UPDATE_LINK_PAYMENT,
  CANCEL_LINK_PAYMENT,
  PROCESS_LINK_PAYMENT,
  READ_LINK_PAYMENT,
  READ_ALL_LINKS_PAYMENT,
  REGISTER_POS_TRANSACTION,
  SEND_EMAIL_POS_LINK_PAYMENT,
  PROCESS_PUBLIC_POS_LINK_PAYMENT,
  READ_WALLETS_BY_ACCOUNT,
  PROCESS_ACCOUNT_SETTINGS,
  PAYMENT_VERIFICATION_WALLET,
  VALIDATE_OTP_TIGO_MONEY
} from '../../constants/actionTypes';

import {
  readLinkPaymentFailed,
  readLinkPaymentSuccess,
  readAllLinksPaymentFailed,
  readAllLinksPaymentSuccess,
  setCountLinksPayment,
  createLinkPaymentFailed,
  createLinkPaymentSuccess,
  updateLinkPaymentFailed,
  updateLinkPaymentSuccess,
  cancelLinkPaymentFailed,
  cancelLinkPaymentSuccess,
  processLinkPaymentFailed,
  processLinkPaymentSuccess,
  registerPOSTransactionFailed,
  registerPOSTransactionSuccess,
  sendEmailPOSLinkPaymentFailed,
  sendEmailPOSLinkPaymentSuccess,
  processPublicPOSLinkPaymentFailed,
  processPublicPOSLinkPaymentSuccess,
  readWalletsByAccountFailed,
  readWalletsByAccountSuccess,
  processAccountSettingsFailed,
  processAccountSettingsSuccess,
  paymentVerificationWalletFailed,
  paymentVerificationWalletSuccess,
  validateLinkPaymentOTPFailed,
  validateLinkPaymentOTPSuccess
} from './actions';

function* readLinkPayment({ _id, payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/v2/links/checkout/${_id}`, options);

    const { _id: idPaymentLink, account } = response;

    if (idPaymentLink && account) {
      response['status'] = 'PENDING';
    }
    yield put(readLinkPaymentSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(readLinkPaymentFailed(message));
  }
}

function* readWalletsByAccount({ _id, payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/accounts/${_id}/wallets`, options);

    const { data } = response;

    yield put(readWalletsByAccountSuccess(data));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(readWalletsByAccountFailed(message));
  }
}

function* readAllLinksPayment({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/pos`, options);
    const { rows = 0, data } = response;
    yield put(setCountLinksPayment(rows));
    yield put(readAllLinksPaymentSuccess(data || response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(readAllLinksPaymentFailed(message));
  }
}

function* createLinkPayment({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, `/pos`, options);

    yield put(createLinkPaymentSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createLinkPaymentFailed(message));
  }
}

function* postAccountSettings({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/accounts/settings', options);

    yield put(processAccountSettingsSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(processAccountSettingsFailed(message));
  }
}

function* updateLinkPayment({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/pos/${_id}`, options);

    yield put(updateLinkPaymentSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(updateLinkPaymentFailed(message));
  }
}

function* cancelLinkPayment({ _id, payload: data }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/pos/${_id}`, options);

    yield put(cancelLinkPaymentSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(cancelLinkPaymentFailed(message));
  }
}

function* processLinkPayment({ _id: account, payload: data }) {
  try {
    yield call(Paygate.configure_link, account, process.env.REACT_APP_SDK_ENV);

    const kount = yield call(Paygate.dataCollector, account._id, data.safeIdentifier, data.currency, process.env.REACT_APP_SDK_ENV);

    const response = yield call(Paygate.payOrder, { ...data, ...kount, channelCode: '1003' });

    if (response.linkError) {
      throw new Error(response.linkError.message);
    }

    yield put(processLinkPaymentSuccess(response));
  } catch ({ message }) {
    yield put(processLinkPaymentFailed(message));
  }
}

function* verificationWalletLinkPayment({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/wallets/paymentVerification', options);

    yield put(paymentVerificationWalletSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(paymentVerificationWalletFailed(message));
  }
}

function* registerPOSTransaction({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, `/payments`, options);

    yield put(registerPOSTransactionSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(registerPOSTransactionFailed(message));
  }
}

function* sendEmailPOSLinkPayment({ posLinkID, payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, `/pos/${posLinkID}/sendEmail`, options);

    yield put(sendEmailPOSLinkPaymentSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(sendEmailPOSLinkPaymentFailed(message));
  }
}

function* processPublicPOSLinkPayment({ posLinkID, payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, `/links/checkout/${posLinkID}/process`, options);

    yield put(processPublicPOSLinkPaymentSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(processPublicPOSLinkPaymentFailed(message));
  }
}

function* validateOTPPOSLinkPayment({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/payments/wallets/transaction-verification', options);

    yield put(validateLinkPaymentOTPSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(validateLinkPaymentOTPFailed({ message, redirectURL: error.redirectURL, walletAttempts: error.walletAttempts }));
  }
}

export function* watchGetPOSLinkPayment() {
  yield takeEvery(READ_LINK_PAYMENT, readLinkPayment);
}

export function* watchReadAllLinksPayment() {
  yield takeEvery(READ_ALL_LINKS_PAYMENT, readAllLinksPayment);
}

export function* watchReadWalletsByAccount() {
  yield takeEvery(READ_WALLETS_BY_ACCOUNT, readWalletsByAccount);
}

export function* watchProcessLinkPayment() {
  yield takeEvery(PROCESS_LINK_PAYMENT, processLinkPayment);
}

export function* watchCreateLinkPayment() {
  yield takeEvery(CREATE_LINK_PAYMENT, createLinkPayment);
}

export function* watchPostAccountSettings() {
  yield takeEvery(PROCESS_ACCOUNT_SETTINGS, postAccountSettings);
}

export function* watchVerificationWalletLinkPayment() {
  yield takeEvery(PAYMENT_VERIFICATION_WALLET, verificationWalletLinkPayment);
}

export function* watchUpdateLinkPayment() {
  yield takeEvery(UPDATE_LINK_PAYMENT, updateLinkPayment);
}

export function* watchCancelLinkPayment() {
  yield takeEvery(CANCEL_LINK_PAYMENT, cancelLinkPayment);
}

export function* watchRegisterPOSTransaction() {
  yield takeEvery(REGISTER_POS_TRANSACTION, registerPOSTransaction);
}

export function* watchSendEmailPOSLinkPayment() {
  yield takeEvery(SEND_EMAIL_POS_LINK_PAYMENT, sendEmailPOSLinkPayment);
}

export function* watchProcessPublicPOSLinkPayment() {
  yield takeEvery(PROCESS_PUBLIC_POS_LINK_PAYMENT, processPublicPOSLinkPayment);
}

export function* watchValidateOTPPOSLinkPayment() {
  yield takeEvery(VALIDATE_OTP_TIGO_MONEY, validateOTPPOSLinkPayment);
}

function* posSaga() {
  yield all([
    fork(watchGetPOSLinkPayment),
    fork(watchReadAllLinksPayment),
    fork(watchReadWalletsByAccount),
    fork(watchProcessLinkPayment),
    fork(watchCreateLinkPayment),
    fork(watchPostAccountSettings),
    fork(watchUpdateLinkPayment),
    fork(watchCancelLinkPayment),
    fork(watchRegisterPOSTransaction),
    fork(watchSendEmailPOSLinkPayment),
    fork(watchProcessPublicPOSLinkPayment),
    fork(watchVerificationWalletLinkPayment),
    fork(watchValidateOTPPOSLinkPayment)
  ]);
}

export default posSaga;
