import {
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER_ACCOUNT,
  REGISTER_USER_ACCOUNT_FAILED,
  REGISTER_USER_ACCOUNT_SUCCESS,
  RECOVERY_USER_PASSWORD,
  RECOVERY_USER_PASSWORD_FAILED,
  RECOVERY_USER_PASSWORD_SUCCESS,
  RECOVERY_USER_PASSWORD_DECRYPT,
  RECOVERY_USER_PASSWORD_DECRYPT_FAILED,
  RECOVERY_USER_PASSWORD_DECRYPT_SUCCESS,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_FAILED,
  UPDATE_USER_PASSWORD_SUCCESS
} from '../../constants/actionTypes';

export const loginUser = (payload) => ({
  payload,
  type: LOGIN_USER
});
export const loginUserSuccess = (user) => ({
  payload: user,
  type: LOGIN_USER_SUCCESS
});
export const loginUserFailed = (error) => ({
  payload: error,
  type: LOGIN_USER_FAILED
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history }
});

export const registerUserAccount = (payload) => ({
  payload,
  type: REGISTER_USER_ACCOUNT
});
export const registerUserAccountFailed = (error) => ({
  payload: error,
  type: REGISTER_USER_ACCOUNT_FAILED
});
export const registerUserAccountSuccess = (user) => ({
  payload: user,
  type: REGISTER_USER_ACCOUNT_SUCCESS
});

export const recoveryUserPassword = (payload) => ({ payload, type: RECOVERY_USER_PASSWORD });
export const recoveryUserPasswordFailed = (payload) => ({ payload, type: RECOVERY_USER_PASSWORD_FAILED });
export const recoveryUserPasswordSuccess = (payload) => ({ payload, type: RECOVERY_USER_PASSWORD_SUCCESS });

export const recoveryUserPasswordDecrypt = (payload) => ({ payload, type: RECOVERY_USER_PASSWORD_DECRYPT });
export const recoveryUserPasswordDecryptFailed = (payload) => ({ payload, type: RECOVERY_USER_PASSWORD_DECRYPT_FAILED });
export const recoveryUserPasswordDecryptSuccess = (payload) => ({ payload, type: RECOVERY_USER_PASSWORD_DECRYPT_SUCCESS });

export const updateUserPassword = (hash, payload) => ({ hash, payload, type: UPDATE_USER_PASSWORD });
export const updateUserPasswordFailed = (payload) => ({ payload, type: UPDATE_USER_PASSWORD_FAILED });
export const updateUserPasswordSuccess = (payload) => ({ payload, type: UPDATE_USER_PASSWORD_SUCCESS });
