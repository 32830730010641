import fetchJSON from '../../utils/fetchJSON';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';

import {
  setCountAccounts,
  getMyAccountFailed,
  getMyAccountSuccess,
  readAccountIDFailed,
  readAccountIDSuccess,
  getAllAccountsFailed,
  getAllAccountsSuccess,
  registerAccountFailed,
  registerAccountSuccess,
  updateAccountFailed,
  updateAccountSuccess,
  uploadLogoAccountFailed,
  uploadLogoAccountSuccess,
  getAccountTerminalsFailed,
  getAccountTerminalsSuccess,
  createAccountTerminalFailed,
  createAccountTerminalSuccess,
  updateAccountTerminalFailed,
  updateAccountTerminalSuccess,
  deleteAccountTerminalFailed,
  deleteAccountTerminalSuccess,
  getCardinalOnboardingFailed,
  getCardinalOnboardingSuccess
} from './actions';

import {
  UPDATE_ACCOUNT,
  GET_MY_ACCOUNT,
  READ_ACCOUNT_ID,
  GET_ALL_ACCOUNTS,
  REGISTER_ACCOUNT,
  UPLOAD_LOGO_ACCOUNT,
  GET_ACCOUNT_TERMINALS,
  CREATE_ACCOUNT_TERMINAL,
  UPDATE_ACCOUNT_TERMINAL,
  DELETE_ACCOUNT_TERMINAL,
  CARDINAL_ONBOARDING
} from '../../constants/actionTypes';
import { createNotification, NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify';
import { createMessageNotify } from '../../utils/notify';

function* register({ payload }) {
  const options = {
    data: payload,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/accounts', options);
    yield put(registerAccountSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(registerAccountFailed(message));
  }
}

function* getMyAccount({ payload }) {
  const { _id } = payload;

  const options = {
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/accounts/${_id}`, options);
    yield put(getMyAccountSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(getMyAccountFailed(message));
  }
}

function* getAllAccounts({ payload }) {
  const options = {
    method: 'GET',
    params: payload
  };

  try {
    const { rows = 0, data = [] } = yield call(fetchJSON, '/accounts', options);
    yield put(getAllAccountsSuccess(data));
    yield put(setCountAccounts(rows));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(getAllAccountsFailed(message));
  }
}

function* readAccountID({ accountID }) {
  const options = {
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/accounts/${accountID}`, options);
    yield put(readAccountIDSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(readAccountIDFailed(message));
  }
}

function* updateAccount({ accountID, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/accounts/${accountID}`, options);
    yield put(updateAccountSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(updateAccountFailed(message));
  }
}

function* uploadLogoAccount({ accountID, payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, `/accounts/${accountID}/logos`, options);
    yield put(uploadLogoAccountSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Tamaño máximo permitido 100kb';
    }

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(uploadLogoAccountFailed(message));
  }
}

function* getAccountTerminals({ payload, accountID }) {
  const options = {
    params: payload,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/accounts/${accountID}/terminals`, options);
    yield put(getAccountTerminalsSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(getAccountTerminalsFailed(message));
  }
}

function* createAccountTerminal({ payload, accountID }) {
  const options = {
    data: payload,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/accounts/${accountID}/terminals`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Terminal creada correctamente')));
    yield put(createAccountTerminalSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(createAccountTerminalFailed(message));
  }
}

function* updateAccountTerminal({ payload, accountID, terminalID }) {
  const options = {
    data: payload,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/accounts/${accountID}/terminals/${terminalID}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Terminal actualizada correctamente')));
    yield put(updateAccountTerminalSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(updateAccountTerminalFailed(message));
  }
}

function* deleteAccountTerminal({ accountID, terminalID, eraseType }) {
  const options = {
    method: 'DELETE',
    data: { type: eraseType }
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/accounts/${accountID}/terminals/${terminalID}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, response.message)));
    yield put(deleteAccountTerminalSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(deleteAccountTerminalFailed(message));
  }
}

function* getCardinalOnboarding({ terminal, account }) {
  const options = {
    data: { account, terminal },
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/cardinal/onboarding', options);
    yield put(getCardinalOnboardingSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(getCardinalOnboardingFailed(message));
  }
}

export function* watchGetMyAccount() {
  yield takeEvery(GET_MY_ACCOUNT, getMyAccount);
}

export function* watchGetAllAccounts() {
  yield takeEvery(GET_ALL_ACCOUNTS, getAllAccounts);
}

export function* watchReadAccountID() {
  yield takeEvery(READ_ACCOUNT_ID, readAccountID);
}

export function* watchRegisterAccount() {
  yield takeEvery(REGISTER_ACCOUNT, register);
}

export function* watchUpdateAccount() {
  yield takeEvery(UPDATE_ACCOUNT, updateAccount);
}

export function* watchUploadLogoAccount() {
  yield takeEvery(UPLOAD_LOGO_ACCOUNT, uploadLogoAccount);
}

export function* watchGetAccountTerminals() {
  yield takeEvery(GET_ACCOUNT_TERMINALS, getAccountTerminals);
}

export function* watchUpdateAccountTerminal() {
  yield takeEvery(UPDATE_ACCOUNT_TERMINAL, updateAccountTerminal);
}

export function* watchCreateAccountTerminal() {
  yield takeEvery(CREATE_ACCOUNT_TERMINAL, createAccountTerminal);
}

export function* watchDeleteAccountTerminal() {
  yield takeEvery(DELETE_ACCOUNT_TERMINAL, deleteAccountTerminal);
}

export function* watchCardinalOnboarding() {
  yield takeEvery(CARDINAL_ONBOARDING, getCardinalOnboarding);
}

function* accountSaga() {
  yield all([
    fork(watchGetMyAccount),
    fork(watchReadAccountID),
    fork(watchGetAllAccounts),
    fork(watchRegisterAccount),
    fork(watchUpdateAccount),
    fork(watchUploadLogoAccount),
    fork(watchGetAccountTerminals),
    fork(watchUpdateAccountTerminal),
    fork(watchCreateAccountTerminal),
    fork(watchDeleteAccountTerminal),
    fork(watchCardinalOnboarding)
  ]);
}

export default accountSaga;
