import {
  SET_COUNT_PAID_LIQUIDATIONS,
  SET_COUNT_PENDING_LIQUIDATIONS,
  CREATE_LIQUIDATIONS,
  CREATE_LIQUIDATIONS_FAILED,
  CREATE_LIQUIDATIONS_SUCCESS,
  GET_ALL_LIQUIDATIONS,
  GET_ALL_LIQUIDATIONS_FAILED,
  GET_ALL_LIQUIDATIONS_SUCCESS,
  GET_ALL_PAID_LIQUIDATIONS,
  GET_ALL_PAID_LIQUIDATIONS_FAILED,
  GET_ALL_PAID_LIQUIDATIONS_SUCCESS,
  READ_LIQUIDATION,
  READ_LIQUIDATION_FAILED,
  READ_LIQUIDATION_SUCCESS,
  UPDATE_LIQUIDATION,
  UPDATE_LIQUIDATION_FAILED,
  UPDATE_LIQUIDATION_SUCCESS,
  PAY_LIQUIDATIONS,
  PAY_LIQUIDATIONS_FAILED,
  PAY_LIQUIDATIONS_SUCCESS,
  CALCULATE_LIQUIDATIONS,
  CALCULATE_LIQUIDATIONS_FAILED,
  CALCULATE_LIQUIDATIONS_SUCCESS,
  EXPORT_LIQUIDATIONS,
  EXPORT_LIQUIDATIONS_FAILED,
  EXPORT_LIQUIDATIONS_SUCCESS,
  EXPORT_PAID_LIQUIDATIONS,
  EXPORT_PAID_LIQUIDATIONS_FAILED,
  EXPORT_PAID_LIQUIDATIONS_SUCCESS,
  CANCEL_LIQUIDATION,
  CANCEL_LIQUIDATION_FAILED,
  CANCEL_LIQUIDATION_SUCCESS,
  RECALCULATE_COMMISSIONS,
  RECALCULATE_COMMISSIONS_FAILED,
  RECALCULATE_COMMISSIONS_SUCCESS,
  CHANGE_TO_PENDING_LIQUIDATION,
  CHANGE_TO_PENDING_LIQUIDATION_FAILED,
  CHANGE_TO_PENDING_LIQUIDATION_SUCCESS
} from '../../constants/actionTypes';

const INIT_COUNT_PAID_LIQUIDATION_STATE = {
  totalPaidLiquidations: 0
};

const INIT_COUNT_PENDING_LIQUIDATION_STATE = {
  totalPendingdLiquidations: 0
};

const INIT_STATE_CREATE_LIQUIDATION = {
  loading: false,
  error: undefined,
  liquidation: undefined
};
const INIT_STATE_GET_ALL_LIQUIDATION = {
  loading: false,
  error: undefined,
  liquidations: undefined
};
const INIT_STATE_GET_ALL_PAID_LIQUIDATION = {
  loading: false,
  error: undefined,
  paidliquidations: undefined
};

const INIT_STATE_READ_LIQUIDATION = {
  loading: false,
  error: undefined,
  liquidation: undefined
};
const INIT_STATE_UPDATE_LIQUIDATION = {
  loading: false,
  error: undefined,
  liquidation: undefined
};
const INIT_STATE_PAY_LIQUIDATIONS = {
  loading: false,
  error: undefined,
  liquidations: undefined
};
const INIT_STATE_CALCULATE_LIQUIDATIONS = {
  loading: false,
  error: undefined,
  commissions: undefined
};

const INIT_STATE_CANCEL_LIQUIDATION = {
  loading: false,
  error: undefined,
  liquidation: undefined
};

const INIT_STATE_RECALCULATE_COMMISSIONS = {
  loading: false,
  error: undefined,
  result: undefined
};

const INIT_STATE_CHANGE_TO_PENDING_LIQUIDATION = {
  loading: false,
  error: undefined,
  liquidation: undefined
};

export const CountPaidLiquidations = (state = INIT_COUNT_PAID_LIQUIDATION_STATE, { type, payload }) => {
  switch (type) {
    case SET_COUNT_PAID_LIQUIDATIONS:
      return {
        ...state,
        totalPaidLiquidations: payload
      };
    default:
      return state;
  }
};

export const CountPendingLiquidations = (state = INIT_COUNT_PENDING_LIQUIDATION_STATE, { type, payload }) => {
  switch (type) {
    case SET_COUNT_PENDING_LIQUIDATIONS:
      return {
        ...state,
        totalPendingdLiquidations: payload
      };
    default:
      return state;
  }
};

export const CreateLiquidation = (state = INIT_STATE_CREATE_LIQUIDATION, { type, payload }) => {
  switch (type) {
    case CREATE_LIQUIDATIONS:
      return {
        ...state,
        loading: true,
        error: undefined,
        liquidation: undefined
      };
    case CREATE_LIQUIDATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        liquidation: payload
      };
    case CREATE_LIQUIDATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        liquidation: undefined
      };
    default:
      return { ...state };
  }
};
export const GetAllLiquidation = (state = INIT_STATE_GET_ALL_LIQUIDATION, { type, payload }) => {
  switch (type) {
    case GET_ALL_LIQUIDATIONS:
      return {
        ...state,
        loading: true,
        error: undefined,
        liquidations: undefined
      };
    case GET_ALL_LIQUIDATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        liquidations: payload
      };
    case GET_ALL_LIQUIDATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        liquidations: undefined
      };
    default:
      return { ...state };
  }
};
export const GetAllPaidLiquidation = (state = INIT_STATE_GET_ALL_PAID_LIQUIDATION, { type, payload }) => {
  switch (type) {
    case GET_ALL_PAID_LIQUIDATIONS:
      return {
        ...state,
        loading: true,
        error: undefined,
        paidliquidations: undefined
      };
    case GET_ALL_PAID_LIQUIDATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        paidliquidations: payload
      };
    case GET_ALL_PAID_LIQUIDATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        paidliquidations: undefined
      };
    default:
      return { ...state };
  }
};

export const ReadLiquidation = (state = INIT_STATE_READ_LIQUIDATION, { type, payload }) => {
  switch (type) {
    case READ_LIQUIDATION:
      return {
        ...state,
        loading: true,
        error: undefined,
        liquidation: undefined
      };
    case READ_LIQUIDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        liquidation: payload
      };
    case READ_LIQUIDATION_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        liquidation: undefined
      };
    default:
      return { ...state };
  }
};
export const UpdateLiquidation = (state = INIT_STATE_UPDATE_LIQUIDATION, { type, payload }) => {
  switch (type) {
    case UPDATE_LIQUIDATION:
      return {
        ...state,
        loading: true,
        error: undefined,
        liquidation: undefined
      };
    case UPDATE_LIQUIDATION_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        liquidation: undefined
      };
    case UPDATE_LIQUIDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        liquidation: payload
      };
    default:
      return state;
  }
};
export const PayLiquidations = (state = INIT_STATE_PAY_LIQUIDATIONS, { type, payload }) => {
  switch (type) {
    case PAY_LIQUIDATIONS:
      return {
        ...state,
        loading: true,
        error: undefined,
        liquidations: undefined
      };
    case PAY_LIQUIDATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        liquidations: payload
      };
    case PAY_LIQUIDATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        liquidations: undefined
      };
    default:
      return { ...state };
  }
};
export const CalculateLiquidations = (state = INIT_STATE_CALCULATE_LIQUIDATIONS, { type, payload }) => {
  switch (type) {
    case CALCULATE_LIQUIDATIONS:
      return {
        loading: true,
        error: undefined,
        commissions: undefined
      };
    case CALCULATE_LIQUIDATIONS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        commissions: payload
      };
    case CALCULATE_LIQUIDATIONS_FAILED:
      return {
        loading: false,
        error: payload,
        commissions: undefined
      };
    default:
      return { ...state };
  }
};
export const ExportLiquidations = (state = INIT_STATE_CALCULATE_LIQUIDATIONS, { type, payload }) => {
  switch (type) {
    case EXPORT_LIQUIDATIONS:
      return {
        loading: true,
        error: undefined,
        closure: undefined
      };
    case EXPORT_LIQUIDATIONS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        closure: payload
      };
    case EXPORT_LIQUIDATIONS_FAILED:
      return {
        loading: false,
        error: payload,
        closure: undefined
      };
    default:
      return { ...state };
  }
};

export const GetExportPaidLiquidations = (state = INIT_STATE_CALCULATE_LIQUIDATIONS, { type, payload }) => {
  switch (type) {
    case EXPORT_PAID_LIQUIDATIONS:
      return {
        loading: true,
        error: undefined,
        closures: undefined
      };
    case EXPORT_PAID_LIQUIDATIONS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        closures: payload
      };
    case EXPORT_PAID_LIQUIDATIONS_FAILED:
      return {
        loading: false,
        error: payload,
        closures: undefined
      };
    default:
      return { ...state };
  }
};

export const CancelLiquidation = (state = INIT_STATE_CANCEL_LIQUIDATION, { type, payload }) => {
  switch (type) {
    case CANCEL_LIQUIDATION:
      return {
        loading: true,
        error: undefined,
        liquidation: undefined
      };
    case CANCEL_LIQUIDATION_SUCCESS:
      return {
        loading: false,
        error: undefined,
        liquidation: payload
      };
    case CANCEL_LIQUIDATION_FAILED:
      return {
        loading: false,
        error: payload,
        liquidation: undefined
      };
    default:
      return { ...state };
  }
};

export const RecalculateCommissions = (state = INIT_STATE_RECALCULATE_COMMISSIONS, { type, payload }) => {
  switch (type) {
    case RECALCULATE_COMMISSIONS:
      return {
        loading: true,
        error: undefined,
        result: undefined
      };
    case RECALCULATE_COMMISSIONS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        result: payload
      };
    case RECALCULATE_COMMISSIONS_FAILED:
      return {
        loading: false,
        error: payload,
        result: undefined
      };
    default:
      return { ...state };
  }
};

export const ChangeToPendingPaidLiquidation = (state = INIT_STATE_CHANGE_TO_PENDING_LIQUIDATION, { type, payload }) => {
  switch (type) {
    case CHANGE_TO_PENDING_LIQUIDATION:
      return {
        loading: true,
        error: undefined,
        liquidation: undefined
      };
    case CHANGE_TO_PENDING_LIQUIDATION_SUCCESS:
      return {
        loading: false,
        error: undefined,
        liquidation: payload
      };
    case CHANGE_TO_PENDING_LIQUIDATION_FAILED:
      return {
        loading: false,
        error: payload,
        liquidation: undefined
      };
    default:
      return { ...state };
  }
};
