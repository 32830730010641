import {
  SET_COUNT_ACCOUNTS,
  GET_MY_ACCOUNT,
  GET_MY_ACCOUNT_FAILED,
  GET_MY_ACCOUNT_SUCCESS,
  REGISTER_ACCOUNT,
  REGISTER_ACCOUNT_FAILED,
  REGISTER_ACCOUNT_SUCCESS,
  READ_ACCOUNT_ID,
  READ_ACCOUNT_ID_FAILED,
  READ_ACCOUNT_ID_SUCCESS,
  GET_ALL_ACCOUNTS,
  GET_ALL_ACCOUNTS_FAILED,
  GET_ALL_ACCOUNTS_SUCCESS,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_FAILED,
  UPDATE_ACCOUNT_SUCCESS,
  UPLOAD_LOGO_ACCOUNT,
  UPLOAD_LOGO_ACCOUNT_FAILED,
  UPLOAD_LOGO_ACCOUNT_SUCCESS,
  GET_ACCOUNT_TERMINALS,
  GET_ACCOUNT_TERMINALS_FAILED,
  GET_ACCOUNT_TERMINALS_SUCCESS,
  CREATE_ACCOUNT_TERMINAL,
  CREATE_ACCOUNT_TERMINAL_FAILED,
  CREATE_ACCOUNT_TERMINAL_SUCCESS,
  UPDATE_ACCOUNT_TERMINAL,
  UPDATE_ACCOUNT_TERMINAL_FAILED,
  UPDATE_ACCOUNT_TERMINAL_SUCCESS,
  DELETE_ACCOUNT_TERMINAL,
  DELETE_ACCOUNT_TERMINAL_FAILED,
  DELETE_ACCOUNT_TERMINAL_SUCCESS,
  CARDINAL_ONBOARDING,
  CARDINAL_ONBOARBING_FAILED,
  CARDINAL_ONBOARBING_SUCCESS
} from '../../constants/actionTypes';

export const setCountAccounts = (payload) => ({ payload, type: SET_COUNT_ACCOUNTS });

export const registerAccount = (payload) => ({
  payload,
  type: REGISTER_ACCOUNT
});
export const registerAccountFailed = (error) => ({
  payload: error,
  type: REGISTER_ACCOUNT_FAILED
});
export const registerAccountSuccess = (account) => ({
  payload: account,
  type: REGISTER_ACCOUNT_SUCCESS
});

export const getMyAccount = (payload) => ({
  payload,
  type: GET_MY_ACCOUNT
});
export const getMyAccountFailed = (error) => ({
  payload: error,
  type: GET_MY_ACCOUNT_FAILED
});
export const getMyAccountSuccess = (account) => ({
  payload: account,
  type: GET_MY_ACCOUNT_SUCCESS
});

export const readAccountID = (accountID) => ({
  accountID,
  type: READ_ACCOUNT_ID
});
export const readAccountIDFailed = (error) => ({
  payload: error,
  type: READ_ACCOUNT_ID_FAILED
});
export const readAccountIDSuccess = (account) => ({
  payload: account,
  type: READ_ACCOUNT_ID_SUCCESS
});

export const getAllAccounts = (payload) => ({
  payload,
  type: GET_ALL_ACCOUNTS
});
export const getAllAccountsFailed = (error) => ({
  payload: error,
  type: GET_ALL_ACCOUNTS_FAILED
});
export const getAllAccountsSuccess = (account) => ({
  payload: account,
  type: GET_ALL_ACCOUNTS_SUCCESS
});

export const updateAccount = (accountID, payload) => ({
  payload,
  accountID,
  type: UPDATE_ACCOUNT
});
export const updateAccountFailed = (error) => ({
  payload: error,
  type: UPDATE_ACCOUNT_FAILED
});
export const updateAccountSuccess = (account) => ({
  payload: account,
  type: UPDATE_ACCOUNT_SUCCESS
});

export const uploadLogoAccount = (accountID, payload) => ({
  payload,
  accountID,
  type: UPLOAD_LOGO_ACCOUNT
});
export const uploadLogoAccountFailed = (error) => ({
  payload: error,
  type: UPLOAD_LOGO_ACCOUNT_FAILED
});
export const uploadLogoAccountSuccess = (account) => ({
  payload: account,
  type: UPLOAD_LOGO_ACCOUNT_SUCCESS
});

export const createAccountTerminal = (accountID, payload) => ({
  payload,
  accountID,
  type: CREATE_ACCOUNT_TERMINAL
});
export const createAccountTerminalFailed = (payload) => ({
  payload,
  type: CREATE_ACCOUNT_TERMINAL_FAILED
});
export const createAccountTerminalSuccess = (payload) => ({
  payload,
  type: CREATE_ACCOUNT_TERMINAL_SUCCESS
});

export const getAccountTerminals = (accountID, payload) => ({
  payload,
  accountID,
  type: GET_ACCOUNT_TERMINALS
});
export const getAccountTerminalsFailed = (payload) => ({
  payload,
  type: GET_ACCOUNT_TERMINALS_FAILED
});
export const getAccountTerminalsSuccess = (payload) => ({
  payload,
  type: GET_ACCOUNT_TERMINALS_SUCCESS
});

export const updateAccountTerminal = (accountID, terminalID, payload) => ({
  payload,
  accountID,
  terminalID,
  type: UPDATE_ACCOUNT_TERMINAL
});
export const updateAccountTerminalFailed = (payload) => ({
  payload,
  type: UPDATE_ACCOUNT_TERMINAL_FAILED
});
export const updateAccountTerminalSuccess = (payload) => ({
  payload,
  type: UPDATE_ACCOUNT_TERMINAL_SUCCESS
});

export const deleteAccountTerminal = (terminalID, accountID, eraseType) => ({
  accountID,
  terminalID,
  eraseType,
  type: DELETE_ACCOUNT_TERMINAL
});
export const deleteAccountTerminalFailed = (payload) => ({
  payload,
  type: DELETE_ACCOUNT_TERMINAL_FAILED
});
export const deleteAccountTerminalSuccess = (payload) => ({
  payload,
  type: DELETE_ACCOUNT_TERMINAL_SUCCESS
});

export const getCardinalOnboarding = (account, terminal) => ({
  terminal,
  account,
  type: CARDINAL_ONBOARDING
});
export const getCardinalOnboardingFailed = (payload) => ({
  payload,
  type: CARDINAL_ONBOARBING_FAILED
});
export const getCardinalOnboardingSuccess = (payload) => ({
  payload,
  type: CARDINAL_ONBOARBING_SUCCESS
});
