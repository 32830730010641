export const REGISTER_USER_ACCOUNT = 'REGISTER_USER_ACCOUNT';
export const REGISTER_USER_ACCOUNT_FAILED = 'REGISTER_USER_ACCOUNT_FAILED';
export const REGISTER_USER_ACCOUNT_SUCCESS = 'REGISTER_USER_ACCOUNT_SUCCESS';

export const CREATE_USER_ACCOUNT = 'CREATE_USER_ACCOUNT';
export const CREATE_USER_ACCOUNT_FAILED = 'CREATE_USER_ACCOUNT_FAILED';
export const CREATE_USER_ACCOUNT_SUCCESS = 'CREATE_USER_ACCOUNT_SUCCESS';

export const READ_USERS_ACCOUNT = 'READ_USERS_ACCOUNT';
export const READ_USERS_ACCOUNT_FAILED = 'READ_USERS_ACCOUNT_FAILED';
export const READ_USERS_ACCOUNT_SUCCESS = 'READ_USERS_ACCOUNT_SUCCESS';

export const READ_USER_ACCOUNT_ID = 'READ_USER_ACCOUNT_ID';
export const READ_USER_ACCOUNT_ID_FAILED = 'READ_USER_ACCOUNT_ID_FAILED';
export const READ_USER_ACCOUNT_ID_SUCCESS = 'READ_USER_ACCOUNT_ID_SUCCESS';

export const UPDATE_USER_ACCOUNT = 'UPDATE_USER_ACCOUNT';
export const UPDATE_USER_ACCOUNT_FAILED = 'UPDATE_USER_ACCOUNT_FAILED';
export const UPDATE_USER_ACCOUNT_SUCCESS = 'UPDATE_USER_ACCOUNT_SUCCESS';

export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT';
export const DELETE_USER_ACCOUNT_FAILED = 'DELETE_USER_ACCOUNT_FAILED';
export const DELETE_USER_ACCOUNT_SUCCESS = 'DELETE_USER_ACCOUNT_SUCCESS';

export const UNLOCK_USER_ACCOUNT = 'UNLOCK_USER_ACCOUNT';
export const UNLOCK_USER_ACCOUNT_FAILED = 'UNLOCK_USER_ACCOUNT_FAILED';
export const UNLOCK_USER_ACCOUNT_SUCCESS = 'UNLOCK_USER_ACCOUNT_SUCCESS';
