import {
  READ_LINK_PAYMENT,
  READ_LINK_PAYMENT_FAILED,
  READ_LINK_PAYMENT_SUCCESS,
  SET_COUNT_LINKS_PAYMENT,
  CREATE_LINK_PAYMENT,
  CREATE_LINK_PAYMENT_FAILED,
  CREATE_LINK_PAYMENT_SUCCESS,
  UPDATE_LINK_PAYMENT,
  UPDATE_LINK_PAYMENT_FAILED,
  UPDATE_LINK_PAYMENT_SUCCESS,
  CANCEL_LINK_PAYMENT,
  CANCEL_LINK_PAYMENT_FAILED,
  CANCEL_LINK_PAYMENT_SUCCESS,
  PROCESS_LINK_PAYMENT,
  PROCESS_LINK_PAYMENT_FAILED,
  PROCESS_LINK_PAYMENT_SUCCESS,
  REGISTER_POS_TRANSACTION,
  REGISTER_POS_TRANSACTION_FAILED,
  REGISTER_POS_TRANSACTION_SUCCESS,
  READ_ALL_LINKS_PAYMENT,
  READ_ALL_LINKS_PAYMENT_FAILED,
  READ_ALL_LINKS_PAYMENT_SUCCESS,
  SEND_EMAIL_POS_LINK_PAYMENT,
  SEND_EMAIL_POS_LINK_PAYMENT_FAILED,
  SEND_EMAIL_POS_LINK_PAYMENT_SUCCESS,
  PROCESS_PUBLIC_POS_LINK_PAYMENT,
  PROCESS_PUBLIC_POS_LINK_PAYMENT_FAILED,
  PROCESS_PUBLIC_POS_LINK_PAYMENT_SUCCESS,
  READ_WALLETS_BY_ACCOUNT,
  READ_WALLETS_BY_ACCOUNT_FAILED,
  READ_WALLETS_BY_ACCOUNT_SUCCESS,
  PAYMENT_VERIFICATION_WALLET,
  PAYMENT_VERIFICATION_WALLET_FAILED,
  PAYMENT_VERIFICATION_WALLET_SUCCESS,
  PROCESS_ACCOUNT_SETTINGS,
  PROCESS_ACCOUNT_SETTINGS_FAILED,
  PROCESS_ACCOUNT_SETTINGS_SUCCESS,
  VALIDATE_OTP_TIGO_MONEY,
  VALIDATE_OTP_TIGO_MONEY_FAILED,
  VALIDATE_OTP_TIGO_MONEY_SUCCESS
} from '../../constants/actionTypes';

const INIT_COUNT_LINKS_PAYMENTS_STATE = {
  totalLinksPayment: 0
};

const INIT_STATE_LINK_PAYMENT = {
  loading: false,
  error: undefined,
  linkPayment: undefined,
  linksPayment: undefined,
  wallets: [],
  processAccountSettingsData: undefined,
  paymentVerification: undefined
};

const INIT_STATE_POS_TRANSACTION = {
  loading: false,
  transaction: {}
};
const INIT_STATE_PROCESS_POS_LINK = {
  loading: false,
  error: undefined,
  posLinkPayment: undefined
};
const INIT_STATE_SEND_EMAIL_POS_LINK = {
  loading: false,
  error: undefined,
  posLinkPayment: undefined
};
const INIT_STATE_PROCESS_PUBLIC_POS_LINK = {
  loading: false,
  error: undefined,
  posLinkPayment: undefined
};
const INIT_STATE_VALIDATE_OTP_POS_LINK = {
  loading: false,
  error: undefined,
  validation: undefined
};

export const CountLinksPayment = (state = INIT_COUNT_LINKS_PAYMENTS_STATE, { type, payload }) => {
  switch (type) {
    case SET_COUNT_LINKS_PAYMENT:
      return {
        totalLinksPayment: payload
      };
    default:
      return { ...state };
  }
};

export const ReadLinkPayment = (state = INIT_STATE_LINK_PAYMENT, { type, payload }) => {
  switch (type) {
    case READ_LINK_PAYMENT:
      return {
        loading: true,
        error: undefined,
        linkPayment: undefined
      };
    case READ_LINK_PAYMENT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        linkPayment: payload
      };
    case READ_LINK_PAYMENT_FAILED:
      return {
        loading: false,
        error: payload,
        linkPayment: undefined
      };
    default:
      return { ...state };
  }
};

export const ReadWalletsByAccount = (state = INIT_STATE_LINK_PAYMENT, { type, payload }) => {
  switch (type) {
    case READ_WALLETS_BY_ACCOUNT:
      return {
        loading: true,
        error: undefined,
        linkPayment: undefined,
        wallets: []
      };

    case READ_WALLETS_BY_ACCOUNT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        linkPayment: undefined,
        wallets: payload
      };

    case READ_WALLETS_BY_ACCOUNT_FAILED:
      return {
        loading: false,
        error: payload,
        linkPayment: undefined,
        wallets: []
      };

    default:
      return { ...state };
  }
};

export const ReadAllLinksPayment = (state = INIT_STATE_LINK_PAYMENT, { type, payload }) => {
  switch (type) {
    case READ_ALL_LINKS_PAYMENT:
      return {
        loading: true,
        error: undefined,
        linksPayment: undefined
      };
    case READ_ALL_LINKS_PAYMENT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        linksPayment: payload
      };
    case READ_ALL_LINKS_PAYMENT_FAILED:
      return {
        error: payload,
        loading: false,
        linksPayment: undefined
      };
    default:
      return { ...state };
  }
};

export const CreateLinkPayment = (state = INIT_STATE_LINK_PAYMENT, { type, payload }) => {
  switch (type) {
    case CREATE_LINK_PAYMENT:
      return {
        loading: true,
        error: undefined,
        linkPayment: undefined
      };
    case CREATE_LINK_PAYMENT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        linkPayment: payload
      };
    case CREATE_LINK_PAYMENT_FAILED:
      return {
        loading: false,
        error: payload,
        linkPayment: undefined
      };
    default:
      return { ...state };
  }
};

export const ProcessAccountSettings = (state = INIT_STATE_LINK_PAYMENT, { type, payload }) => {
  switch (type) {
    case PROCESS_ACCOUNT_SETTINGS:
      return {
        loading: true,
        error: undefined,
        linkPayment: undefined,
        accountSettingsData: undefined
      };
    case PROCESS_ACCOUNT_SETTINGS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        linkPayment: undefined,
        accountSettingsData: payload
      };
    case PROCESS_ACCOUNT_SETTINGS_FAILED:
      return {
        loading: false,
        error: payload,
        linkPayment: undefined,
        accountSettingsData: undefined
      };
    default:
      return { ...state };
  }
};

export const PaymentVerifcationWallet = (state = INIT_STATE_LINK_PAYMENT, { type, payload }) => {
  switch (type) {
    case PAYMENT_VERIFICATION_WALLET:
      return {
        loading: true,
        error: undefined,
        linkPayment: undefined,
        accountSettingsData: undefined,
        paymentVerification: undefined
      };
    case PAYMENT_VERIFICATION_WALLET_SUCCESS:
      return {
        loading: false,
        error: undefined,
        linkPayment: undefined,
        accountSettingsData: undefined,
        paymentVerification: payload
      };
    case PAYMENT_VERIFICATION_WALLET_FAILED:
      return {
        loading: false,
        error: payload,
        linkPayment: undefined,
        accountSettingsData: undefined,
        paymentVerification: undefined
      };
    default:
      return { ...state };
  }
};

export const UpdateLinkPayment = (state = INIT_STATE_LINK_PAYMENT, { type, payload }) => {
  switch (type) {
    case UPDATE_LINK_PAYMENT:
      return {
        loading: true,
        error: undefined,
        linkPayment: undefined
      };
    case UPDATE_LINK_PAYMENT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        linkPayment: payload
      };
    case UPDATE_LINK_PAYMENT_FAILED:
      return {
        loading: false,
        error: payload,
        linkPayment: undefined
      };
    default:
      return { ...state };
  }
};

export const CancelLinkPayment = (state = INIT_STATE_LINK_PAYMENT, { type, payload }) => {
  switch (type) {
    case CANCEL_LINK_PAYMENT:
      return {
        loading: true,
        error: undefined,
        linkPayment: undefined
      };
    case CANCEL_LINK_PAYMENT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        linkPayment: payload
      };
    case CANCEL_LINK_PAYMENT_FAILED:
      return {
        loading: false,
        error: payload,
        linkPayment: undefined
      };
    default:
      return { ...state };
  }
};

export const ProcessLinkPayment = (state = INIT_STATE_PROCESS_POS_LINK, { type, payload }) => {
  switch (type) {
    case PROCESS_LINK_PAYMENT:
      return {
        loading: true,
        error: undefined,
        linkPayment: undefined
      };
    case PROCESS_LINK_PAYMENT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        linkPayment: payload
      };
    case PROCESS_LINK_PAYMENT_FAILED:
      return {
        loading: false,
        error: payload,
        linkPayment: undefined
      };
    default:
      return { ...state };
  }
};

export const ProcessPublicPOSLinkPayment = (state = INIT_STATE_PROCESS_PUBLIC_POS_LINK, { type, payload }) => {
  switch (type) {
    case PROCESS_PUBLIC_POS_LINK_PAYMENT:
      return {
        ...state,
        loading: true,
        error: undefined,
        posLinkPayment: undefined
      };
    case PROCESS_PUBLIC_POS_LINK_PAYMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        posLinkPayment: undefined
      };
    case PROCESS_PUBLIC_POS_LINK_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        posLinkPayment: payload
      };
    default:
      return { ...state };
  }
};

export const POSTransaction = (state = INIT_STATE_POS_TRANSACTION, action) => {
  switch (action.type) {
    case REGISTER_POS_TRANSACTION:
      return { ...state, loading: true };
    case REGISTER_POS_TRANSACTION_SUCCESS:
      return { ...state, transaction: action.payload, loading: false, error: null };
    case REGISTER_POS_TRANSACTION_FAILED:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};

export const SendEmailPOSLinkPayment = (state = INIT_STATE_SEND_EMAIL_POS_LINK, { type, payload }) => {
  switch (type) {
    case SEND_EMAIL_POS_LINK_PAYMENT:
      return {
        ...state,
        loading: true,
        error: undefined,
        posLinkPayment: undefined
      };
    case SEND_EMAIL_POS_LINK_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        posLinkPayment: payload
      };
    case SEND_EMAIL_POS_LINK_PAYMENT_FAILED:
      return {
        ...state,
        error: payload,
        loading: false,
        posLinkPayment: undefined
      };
    default:
      return { ...state };
  }
};

export const ValidateOTPPosLinkPayment = (state = INIT_STATE_VALIDATE_OTP_POS_LINK, { type, payload }) => {
  switch (type) {
    case VALIDATE_OTP_TIGO_MONEY:
      return {
        ...state,
        loading: true,
        error: undefined,
        validation: undefined
      };
    case VALIDATE_OTP_TIGO_MONEY_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        validation: undefined
      };
    case VALIDATE_OTP_TIGO_MONEY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        validation: payload
      };
    default:
      return { ...state };
  }
};
