import {
  SET_COUNT_WALLETS,
  RESET_CREATED_WALLET,
  CREATE_WALLET,
  CREATE_WALLET_FAILED,
  CREATE_WALLET_SUCCESS,
  READ_WALLETS,
  READ_WALLETS_FAILED,
  READ_WALLETS_SUCCESS,
  UPDATE_WALLET,
  UPDATE_WALLET_FAILED,
  UPDATE_WALLET_SUCCESS,
  DELETE_WALLET,
  DELETE_WALLET_FAILED,
  DELETE_WALLET_SUCCESS
} from '../../constants/actionTypes';

const INIT_COUNT_WALLETS_STATE = {
  totalWallets: 0
};

const INIT_CREATE_WALLET_STATE = {
  loading: false,
  error: undefined,
  wallet: undefined
};
const INIT_READ_WALLETS_STATE = {
  loading: false,
  error: undefined,
  wallets: undefined,
  totalWallets: 0
};

export const CreateWallet = (state = INIT_CREATE_WALLET_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_WALLET:
      return {
        loading: true,
        error: undefined,
        wallet: undefined
      };
    case CREATE_WALLET_FAILED:
      return {
        loading: false,
        error: payload,
        wallet: undefined
      };
    case CREATE_WALLET_SUCCESS:
      return {
        loading: false,
        error: undefined,
        wallet: payload
      };
    case RESET_CREATED_WALLET:
      return {
        ...state,
        wallet: undefined
      };
    default:
      return state;
  }
};

export const CountWallets = (state = INIT_COUNT_WALLETS_STATE, { type, payload }) => {
  switch (type) {
    case SET_COUNT_WALLETS:
      return {
        ...state,
        totalWallets: payload
      };
    default:
      return state;
  }
};

export const ReadWallets = (state = INIT_READ_WALLETS_STATE, { type, payload }) => {
  switch (type) {
    case READ_WALLETS:
      return {
        loading: true,
        error: undefined,
        wallets: undefined
      };
    case READ_WALLETS_FAILED:
      return {
        loading: false,
        error: payload,
        wallets: undefined
      };
    case READ_WALLETS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        wallets: payload
      };
    default:
      return state;
  }
};
export const UpdateWallet = (state = INIT_CREATE_WALLET_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_WALLET:
      return {
        loading: true,
        error: undefined,
        wallet: undefined
      };
    case UPDATE_WALLET_FAILED:
      return {
        loading: false,
        error: payload,
        wallet: undefined
      };
    case UPDATE_WALLET_SUCCESS:
      return {
        loading: false,
        error: undefined,
        wallet: payload
      };
    default:
      return state;
  }
};
export const ToggleStateWallet = (state = INIT_CREATE_WALLET_STATE, { type, payload }) => {
  switch (type) {
    case DELETE_WALLET:
      return {
        loading: true,
        error: undefined,
        wallet: undefined
      };
    case DELETE_WALLET_FAILED:
      return {
        loading: false,
        error: payload,
        wallet: undefined
      };
    case DELETE_WALLET_SUCCESS:
      return {
        loading: false,
        error: undefined,
        wallet: payload
      };
    default:
      return state;
  }
};
