import {
  SET_COUNT_CATEGORIES,
  CREATE_CATEGORIES,
  CREATE_CATEGORIES_FAILED,
  CREATE_CATEGORIES_SUCCESS,
  CATEGORIES_REFRESH,
  CATEGORIES_REFRESH_SUCCESS,
  CATEGORIES_REFRESH_FAILED,
  READ_CATEGORIES,
  READ_CATEGORIES_FAILED,
  READ_CATEGORIES_SUCCESS,
  UPDATE_CATEGORIES,
  UPDATE_CATEGORIES_FAILED,
  UPDATE_CATEGORIES_SUCCESS,
  UPDATE_STATUS_CATEGORY,
  UPDATE_STATUS_CATEGORY_FAILED,
  UPDATE_STATUS_CATEGORY_SUCCESS,
  UPLOAD_IMAGE_CATEGORY,
  UPLOAD_IMAGE_CATEGORY_FAILED,
  UPLOAD_IMAGE_CATEGORY_SUCCESS,
  DELETE_CATEGORIES,
  DELETE_CATEGORIES_FAILED,
  DELETE_CATEGORIES_SUCCESS,
  MODAL_NEW_CATEGORIES_OPEN,
  MODAL_UPDATE_CATEGORIES_OPEN,
  MODAL_CATEGORIES_CLOSE
} from '../../constants/actionTypes';

const INIT_STATE = {
  logo: undefined,
  loading: false,
  error: undefined
};

const INIT_COUNT_CATEGORIES_STATE = {
  totalCategories: 0
};

const INIT_UPDATE_STATUS_CATEGORY_STATE = {
  loading: false,
  error: undefined,
  updatedStatus: undefined
};

const INIT_CREATE_CATEGORIES_STATE = {
  loading: false,
  error: undefined,
  category: undefined
};
const INIT_READ_CATEGORIES_STATE = {
  loading: false,
  loadingRefresh: false,
  error: undefined,
  categories: undefined
};

const INIT_MODAL_NEW_CATEGORY_STATE = {
  isNewOpen: false
};
const INIT_MODAL_UPDATE_CATEGORY_STATE = {
  isUpdateOpen: false
};

export const CountCategories = (
  state = INIT_COUNT_CATEGORIES_STATE,
  { type, payload }
) => {
  switch (type) {
    case SET_COUNT_CATEGORIES:
      return {
        totalCategories: payload
      };
    default:
      return { ...state };
  }
};

export const CreateCategory = (
  state = INIT_CREATE_CATEGORIES_STATE,
  { type, payload }
) => {
  switch (type) {
    case CREATE_CATEGORIES:
      return {
        loading: true,
        error: undefined,
        category: undefined
      };
    case CREATE_CATEGORIES_FAILED:
      return {
        loading: false,
        error: payload,
        category: undefined
      };
    case CREATE_CATEGORIES_SUCCESS:
      return {
        loading: false,
        error: undefined,
        category: payload
      };
    default:
      return { ...state };
  }
};

export const ReadCategories = (
  state = INIT_READ_CATEGORIES_STATE,
  { type, payload }
) => {
  switch (type) {
    case READ_CATEGORIES:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case READ_CATEGORIES_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        categories: undefined
      };
    case READ_CATEGORIES_SUCCESS:
      return {
        ...state,
        error: undefined,
        categories: payload,
        loading: false
      };
    case CATEGORIES_REFRESH:
      return {
        ...state,
        error: undefined,
        loadingRefresh: true
      };
    case CATEGORIES_REFRESH_SUCCESS: {
      const newState = [...state.categories, ...payload];
      return {
        ...state,
        categories: newState,
        loadingRefresh: false
      };
    }
    case CATEGORIES_REFRESH_FAILED:
      return {
        ...state,
        error: payload,
        loadingRefresh: false
      };

    default:
      return { ...state };
  }
};

export const UpdateCategory = (
  state = INIT_CREATE_CATEGORIES_STATE,
  { type, payload }
) => {
  switch (type) {
    case UPDATE_CATEGORIES:
      return {
        loading: true,
        error: undefined,
        category: undefined
      };
    case UPDATE_CATEGORIES_FAILED:
      return {
        loading: false,
        error: payload,
        category: undefined
      };
    case UPDATE_CATEGORIES_SUCCESS:
      return {
        loading: false,
        error: undefined,
        category: payload
      };
    default:
      return { ...state };
  }
};

export const UpdateStatusCategory = (
  state = INIT_UPDATE_STATUS_CATEGORY_STATE,
  { type, payload }
) => {
  switch (type) {
    case UPDATE_STATUS_CATEGORY:
      return {
        loading: true,
        error: undefined,
        updatedStatus: undefined
      };
    case UPDATE_STATUS_CATEGORY_FAILED:
      return {
        loading: false,
        error: payload,
        updatedStatus: false
      };
    case UPDATE_STATUS_CATEGORY_SUCCESS:
      return {
        loading: false,
        error: undefined,
        updatedStatus: true
      };
    default:
      return { ...state };
  }
};

export const uploadImageCategory = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case UPLOAD_IMAGE_CATEGORY:
      return {
        ...state,
        loading: true,
        error: undefined,
        logo: undefined
      };
    case UPLOAD_IMAGE_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        logo: false
      };
    case UPLOAD_IMAGE_CATEGORY_SUCCESS:
      return {
        loading: false,
        error: undefined,
        logo: payload
      };
    default:
      return { ...state };
  }
};

export const DeleteCategory = (
  state = INIT_CREATE_CATEGORIES_STATE,
  { type, payload }
) => {
  switch (type) {
    case DELETE_CATEGORIES:
      return {
        loading: true,
        error: undefined,
        category: undefined
      };
    case DELETE_CATEGORIES_FAILED:
      return {
        loading: false,
        error: payload,
        category: undefined
      };
    case DELETE_CATEGORIES_SUCCESS:
      return {
        loading: false,
        error: undefined,
        category: payload
      };
    default:
      return { ...state };
  }
};

export const NewCategoryModalStatus = (
  state = INIT_MODAL_NEW_CATEGORY_STATE,
  { type }
) => {
  switch (type) {
    case MODAL_NEW_CATEGORIES_OPEN:
      return {
        isNewOpen: true
      };
    case MODAL_CATEGORIES_CLOSE:
      return {
        isNewOpen: false
      };
    default:
      return { ...state };
  }
};
export const UpdateCategoryModalStatus = (
  state = INIT_MODAL_UPDATE_CATEGORY_STATE,
  { type }
) => {
  switch (type) {
    case MODAL_UPDATE_CATEGORIES_OPEN: {
      return {
        isUpdateOpen: true
      };
    }

    case MODAL_CATEGORIES_CLOSE:
      return {
        isUpdateOpen: false
      };
    default:
      return { ...state };
  }
};
