import AppRoutes from './routes/AppRoutes';

// Themes
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import './assets/scss/DefaultTheme.scss';

const App = () => <AppRoutes />;

export default App;
