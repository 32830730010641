import {
  SET_COUNT_CLOSURES_CONCILIATION,
  RETRY_CLOSURES_BANCATLAN,
  RETRY_CLOSURES_BANCATLAN_FAILED,
  RETRY_CLOSURES_BANCATLAN_SUCCESS,
  RETRY_CLOSURE,
  RETRY_CLOSURE_FAILED,
  RETRY_CLOSURE_SUCCESS,
  READ_CLOSURES_CONCILIATION,
  READ_CLOSURES_CONCILIATION_FAILED,
  READ_CLOSURES_CONCILIATION_SUCCESS,
  UPLOAD_RESUME_CLOSURE,
  UPLOAD_RESUME_CLOSURE_FAILED,
  UPLOAD_RESUME_CLOSURE_SUCCESS,
  UPLOAD_RESUME_CLOSURE_RESET,
  GET_RESUMES_TO_EXPORT,
  GET_RESUMES_TO_EXPORT_FAILED,
  GET_RESUMES_TO_EXPORT_SUCCESS
} from '../../constants/actionTypes';

const INIT_STATE_CLOSURES_CONCILIATION = {
  loading: false,
  error: undefined,
  report: undefined,
  reports: undefined,
  resume: undefined,
  resumes: [],
  resumesExport: [],
  totalCount: 0
};

export const CountClosuresConciliation = (state = INIT_STATE_CLOSURES_CONCILIATION, { type, payload }) => {
  switch (type) {
    case SET_COUNT_CLOSURES_CONCILIATION:
      return {
        ...state,
        totalCount: payload
      };
    default:
      return { ...state };
  }
};

export const ReadClosuresConciliation = (state = INIT_STATE_CLOSURES_CONCILIATION, { type, payload }) => {
  switch (type) {
    case READ_CLOSURES_CONCILIATION:
      return {
        loading: true,
        error: undefined,
        reports: undefined
      };
    case READ_CLOSURES_CONCILIATION_SUCCESS:
      return {
        loading: false,
        error: undefined,
        reports: payload
      };
    case READ_CLOSURES_CONCILIATION_FAILED:
      return {
        loading: false,
        error: payload,
        reports: undefined
      };
    default:
      return { ...state };
  }
};

export const RetryClosure = (state = INIT_STATE_CLOSURES_CONCILIATION, { type, payload }) => {
  switch (type) {
    case RETRY_CLOSURE:
      return {
        loading: true,
        error: undefined,
        report: undefined
      };
    case RETRY_CLOSURE_SUCCESS:
      return {
        loading: false,
        error: undefined,
        report: payload
      };
    case RETRY_CLOSURE_FAILED:
      return {
        loading: false,
        error: payload,
        report: undefined
      };
    default:
      return { ...state };
  }
};

export const UploadResume = (state = INIT_STATE_CLOSURES_CONCILIATION, { type, payload, resumes }) => {
  switch (type) {
    case UPLOAD_RESUME_CLOSURE:
      return {
        loading: true,
        error: undefined,
        report: undefined,
        resumes: []
      };
    case UPLOAD_RESUME_CLOSURE_SUCCESS:
      return {
        loading: false,
        error: undefined,
        resume: payload,
        resumes
      };
    case UPLOAD_RESUME_CLOSURE_FAILED:
      return {
        loading: false,
        error: payload,
        report: undefined,
        resume: undefined,
        resumes: []
      };
    case UPLOAD_RESUME_CLOSURE_RESET:
      return {
        loading: true,
        error: undefined,
        report: undefined,
        resumes: []
      };
    default:
      return { ...state };
  }
};

export const GetResumesToExport = (state = INIT_STATE_CLOSURES_CONCILIATION, { type, payload }) => {
  switch (type) {
    case GET_RESUMES_TO_EXPORT:
      return {
        ...state,
        loading: true,
        error: undefined,
        resumesExport: undefined
      };
    case GET_RESUMES_TO_EXPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        resumesExport: payload
      };
    case GET_RESUMES_TO_EXPORT_FAILED:
      return {
        ...state,
        error: payload,
        loading: false,
        resumesExport: undefined
      };
    default:
      return state;
  }
};

export const RetryClosuresBancatlan = (state = INIT_STATE_CLOSURES_CONCILIATION, { type, payload }) => {
  switch (type) {
    case RETRY_CLOSURES_BANCATLAN:
      return {
        loading: true,
        error: undefined,
        report: undefined
      };
    case RETRY_CLOSURES_BANCATLAN_SUCCESS:
      return {
        loading: false,
        error: undefined,
        report: payload
      };
    case RETRY_CLOSURES_BANCATLAN_FAILED:
      return {
        loading: false,
        error: payload,
        report: undefined
      };
    default:
      return { ...state };
  }
};
