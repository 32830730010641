export const SET_COUNT_SECURITY_PROVIDERS = 'SET_COUNT_SECURITY_PROVIDERS';

export const CREATE_SECURITY_PROVIDER = 'CREATE_SECURITY_PROVIDER';
export const CREATE_SECURITY_PROVIDER_FAILED = 'CREATE_SECURITY_PROVIDER';
export const CREATE_SECURITY_PROVIDER_SUCCESS = 'CREATE_SECURITY_PROVIDER_SUCCESS';

export const READ_SECURITY_PROVIDERS = 'READ_SECURITY_PROVIDERS';
export const READ_SECURITY_PROVIDERS_FAILED = 'READ_SECURITY_PROVIDERS_FAILED';
export const READ_SECURITY_PROVIDERS_SUCCESS = 'READ_SECURITY_PROVIDERS_SUCCESS';

export const UPDATE_SECURITY_PROVIDER = 'UPDATE_SECURITY_PROVIDER';
export const UPDATE_SECURITY_PROVIDER_FAILED = 'UPDATE_SECURITY_PROVIDER_FAILED';
export const UPDATE_SECURITY_PROVIDER_SUCCESS = 'UPDATE_SECURITY_PROVIDER_SUCCESS';

export const DELETE_SECURITY_PROVIDER = 'DELETE_SECURITY_PROVIDER';
export const DELETE_SECURITY_PROVIDER_FAILED = 'DELETE_SECURITY_PROVIDER_FAILED';
export const DELETE_SECURITY_PROVIDER_SUCCESS = 'DELETE_SECURITY_PROVIDER_SUCCESS';
