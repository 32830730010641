import fetchJSON from '../../utils/fetchJSON';
import { createMessageNotify } from '../../utils/notify';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { createNotification, NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify';

import { CREATE_WALLET, READ_WALLETS, UPDATE_WALLET, DELETE_WALLET } from '../../constants/actionTypes';

import {
  setCountWallets,
  createWalletFailed,
  createWalletSuccess,
  readWalletsFailed,
  readWalletsSuccess,
  updateWalletFailed,
  updateWalletSuccess,
  toggleStateWalletFailed,
  toggleStateWalletSuccess
} from './actions';

function* createWallet({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/wallets', options);

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Billetera creada correctamente')));
    yield put(createWalletSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(createWalletFailed(message));
  }
}

function* readWallets({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/wallets', options);
    const { rows = 0, data } = response;
    yield put(readWalletsSuccess(data || response));
    yield put(setCountWallets(rows));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Wallets not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(readWalletsFailed(message));
  }
}

function* updateWallet({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/wallets/${_id}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Billetera actualizada correctamente')));
    yield put(updateWalletSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(updateWalletFailed(message));
  }
}

function* toggleStateWallet({ _id, payload: data }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/wallets/${_id}/state`, options);

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, response.message)));
    yield put(toggleStateWalletSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(toggleStateWalletFailed(message));
  }
}

export function* watchCreateWallet() {
  yield takeEvery(CREATE_WALLET, createWallet);
}

export function* watchReadWallets() {
  yield takeEvery(READ_WALLETS, readWallets);
}

export function* watchUpdateWallet() {
  yield takeEvery(UPDATE_WALLET, updateWallet);
}

export function* watchDeleteWallet() {
  yield takeEvery(DELETE_WALLET, toggleStateWallet);
}

function* WalletsSaga() {
  yield all([fork(watchCreateWallet), fork(watchReadWallets), fork(watchUpdateWallet), fork(watchDeleteWallet)]);
}

export default WalletsSaga;
