import {
  SET_COUNT_TRANSACTIONS,
  SET_COUNT_WALLET_TRANSACTIONS,
  GET_TRANSACTIONS_TO_EXPORT,
  GET_TRANSACTIONS_TO_EXPORT_FAILED,
  GET_TRANSACTIONS_TO_EXPORT_SUCCESS,
  GET_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_FAILED,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_WALLET_TRANSACTIONS,
  GET_ALL_WALLET_TRANSACTIONS_FAILED,
  GET_ALL_WALLET_TRANSACTIONS_SUCCESS,
  GET_PENDING_CLOSURE_TRANSACTIONS,
  GET_PENDING_CLOSURE_TRANSACTIONS_FAILED,
  GET_PENDING_CLOSURE_TRANSACTIONS_SUCCESS,
  REVERT_TRANSACTION,
  REVERT_TRANSACTION_FAILED,
  REVERT_TRANSACTION_SUCCESS,
  GET_ONE_TRANSACTION,
  GET_ONE_TRANSACTION_FAILED,
  GET_ONE_TRANSACTION_SUCCESS,
  REPORT_COMMISSIONS_TRANSACTIONS,
  REPORT_COMMISSIONS_TRANSACTIONS_FAILED,
  REPORT_COMMISSIONS_TRANSACTIONS_SUCCESS,
  RECALCULATE_TRANSACTIONS_FEES,
  RECALCULATE_TRANSACTIONS_FEES_FAILED,
  RECALCULATE_TRANSACTIONS_FEES_SUCCESS,
  CHARGEBACK,
  CHARGEBACK_SUCCESS,
  CHARGEBACK_FAILED
} from '../../constants/actionTypes';

export const setCountTransactions = (payload) => ({ payload, type: SET_COUNT_TRANSACTIONS });
export const setCountWalletTransactions = (payload) => ({ payload, type: SET_COUNT_WALLET_TRANSACTIONS });

export const getTransactions = (payload) => ({
  payload,
  type: GET_ALL_TRANSACTIONS
});
export const getTransactionsFailed = (error) => ({
  payload: error,
  type: GET_ALL_TRANSACTIONS_FAILED
});
export const getTransactionsSuccess = (payload) => ({
  payload,
  type: GET_ALL_TRANSACTIONS_SUCCESS
});

export const getWalletTransactions = (payload) => ({
  payload,
  type: GET_ALL_WALLET_TRANSACTIONS
});
export const getWalletTransactionsFailed = (error) => ({
  payload: error,
  type: GET_ALL_WALLET_TRANSACTIONS_FAILED
});
export const getWalletTransactionsSuccess = (payload) => ({
  payload,
  type: GET_ALL_WALLET_TRANSACTIONS_SUCCESS
});

export const getTransactionsToExport = (payload) => ({ payload, type: GET_TRANSACTIONS_TO_EXPORT });
export const getTransactionsToExportFailed = (payload) => ({ payload, type: GET_TRANSACTIONS_TO_EXPORT_FAILED });
export const getTransactionsToExportSuccess = (payload) => ({ payload, type: GET_TRANSACTIONS_TO_EXPORT_SUCCESS });

export const revertTransaction = (transactionID, payload) => ({
  payload,
  transactionID,
  type: REVERT_TRANSACTION
});
export const revertTransactionFailed = (error) => ({
  payload: error,
  type: REVERT_TRANSACTION_FAILED
});
export const revertTransactionSuccess = (payload) => ({
  payload,
  type: REVERT_TRANSACTION_SUCCESS
});

export const chargeBack = (transactionID, payload) => ({ type: CHARGEBACK, transactionID, payload });
export const chargeBackSuccess = (payload) => ({ type: CHARGEBACK_SUCCESS, payload });
export const chargeBackFailed = (error) => ({ type: CHARGEBACK_FAILED, payload: error });

export const getTransactionByID = (payload) => ({
  payload,
  type: GET_ONE_TRANSACTION
});
export const getOneTransactionFailed = (error) => ({
  payload: error,
  type: GET_ONE_TRANSACTION_FAILED
});
export const getOneTransactionSuccess = (payload) => ({
  payload,
  type: GET_ONE_TRANSACTION_SUCCESS
});

export const getReportCommissionsTransactions = (payload) => ({
  payload,
  type: REPORT_COMMISSIONS_TRANSACTIONS
});
export const getReportCommissionsTransactionsFailed = (error) => ({
  payload: error,
  type: REPORT_COMMISSIONS_TRANSACTIONS_FAILED
});
export const getReportCommissionsTransactionsSuccess = (payload) => ({
  payload,
  type: REPORT_COMMISSIONS_TRANSACTIONS_SUCCESS
});

export const getPendingClosureTransactions = (payload) => ({ payload, type: GET_PENDING_CLOSURE_TRANSACTIONS });
export const getPendingClosureTransactionsFailed = (error) => ({ payload: error, type: GET_PENDING_CLOSURE_TRANSACTIONS_FAILED });
export const getPendingClosureTransactionsSuccess = (payload) => ({ payload, type: GET_PENDING_CLOSURE_TRANSACTIONS_SUCCESS });

export const recalculateTransactionsFees = (payload) => ({ payload, type: RECALCULATE_TRANSACTIONS_FEES });
export const recalculateTransactionsFeesFailed = (error) => ({ payload: error, type: RECALCULATE_TRANSACTIONS_FEES_FAILED });
export const recalculateTransactionsFeesSuccess = (payload) => ({ payload, type: RECALCULATE_TRANSACTIONS_FEES_SUCCESS });
