import {
  CREATE_CUSTOMER_CARD,
  CREATE_CUSTOMER_CARD_FAILED,
  CREATE_CUSTOMER_CARD_SUCCESS,
  READ_CUSTOMER_CARDS,
  READ_CUSTOMER_CARDS_FAILED,
  READ_CUSTOMER_CARDS_SUCCESS,
  UPDATE_CUSTOMER_CARD,
  UPDATE_CUSTOMER_CARD_FAILED,
  UPDATE_CUSTOMER_CARD_SUCCESS,
  DELETE_CUSTOMER_CARD,
  DELETE_CUSTOMER_CARD_FAILED,
  DELETE_CUSTOMER_CARD_SUCCESS
} from '../../constants/actionTypes';

const INIT_CREATE_CUSTOMER_CARD_STATE = {
  loading: false,
  error: undefined,
  card: undefined
};
const INIT_READ_CUSTOMER_CARDS_STATE = {
  loading: false,
  error: undefined,
  cards: undefined
};

export const CreateCustomerCard = (state = INIT_CREATE_CUSTOMER_CARD_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_CUSTOMER_CARD:
      return {
        loading: true,
        error: undefined,
        card: undefined
      };
    case CREATE_CUSTOMER_CARD_FAILED:
      return {
        loading: false,
        error: payload,
        card: undefined
      };
    case CREATE_CUSTOMER_CARD_SUCCESS:
      return {
        loading: false,
        error: undefined,
        card: payload
      };
    default:
      return { ...state };
  }
};
export const ReadCustomerCards = (state = INIT_READ_CUSTOMER_CARDS_STATE, { type, payload }) => {
  switch (type) {
    case READ_CUSTOMER_CARDS:
      return {
        loading: true,
        error: undefined,
        cards: undefined
      };
    case READ_CUSTOMER_CARDS_FAILED:
      return {
        loading: false,
        error: payload,
        cards: undefined
      };
    case READ_CUSTOMER_CARDS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        cards: payload
      };
    default:
      return { ...state };
  }
};
export const UpdateCustomerCard = (state = INIT_CREATE_CUSTOMER_CARD_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_CUSTOMER_CARD:
      return {
        loading: true,
        error: undefined,
        card: undefined
      };
    case UPDATE_CUSTOMER_CARD_FAILED:
      return {
        loading: false,
        error: payload,
        card: undefined
      };
    case UPDATE_CUSTOMER_CARD_SUCCESS:
      return {
        loading: false,
        error: undefined,
        card: payload
      };
    default:
      return { ...state };
  }
};
export const DeleteCustomerCard = (state = INIT_CREATE_CUSTOMER_CARD_STATE, { type, payload }) => {
  switch (type) {
    case DELETE_CUSTOMER_CARD:
      return {
        loading: true,
        error: undefined,
        card: undefined
      };
    case DELETE_CUSTOMER_CARD_FAILED:
      return {
        loading: false,
        error: payload,
        card: undefined
      };
    case DELETE_CUSTOMER_CARD_SUCCESS:
      return {
        loading: false,
        error: undefined,
        card: payload
      };
    default:
      return { ...state };
  }
};
