import {
  SET_COUNT_BANKS,
  RESET_CREATED_BANK,
  CREATE_BANK,
  CREATE_BANK_FAILED,
  CREATE_BANK_SUCCESS,
  READ_BANKS,
  READ_BANKS_FAILED,
  READ_BANKS_SUCCESS,
  UPDATE_BANK,
  UPDATE_BANK_FAILED,
  UPDATE_BANK_SUCCESS,
  DELETE_BANK,
  DELETE_BANK_FAILED,
  DELETE_BANK_SUCCESS
} from '../../constants/actionTypes';

const INIT_COUNT_BANKS_STATE = {
  totalBanks: 0
};

const INIT_CREATE_BANK_STATE = {
  loading: false,
  error: undefined,
  bank: undefined
};
const INIT_READ_BANKS_STATE = {
  loading: false,
  error: undefined,
  banks: undefined,
  totalBanks: 0
};

export const CreateBank = (state = INIT_CREATE_BANK_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_BANK:
      return {
        loading: true,
        error: undefined,
        bank: undefined
      };
    case CREATE_BANK_FAILED:
      return {
        loading: false,
        error: payload,
        bank: undefined
      };
    case CREATE_BANK_SUCCESS:
      return {
        loading: false,
        error: undefined,
        bank: payload
      };
    case RESET_CREATED_BANK:
      return {
        ...state,
        bank: undefined
      };
    default:
      return state;
  }
};

export const CountBanks = (state = INIT_COUNT_BANKS_STATE, { type, payload }) => {
  switch (type) {
    case SET_COUNT_BANKS:
      return {
        ...state,
        totalBanks: payload
      };
    default:
      return state;
  }
};

export const ReadBanks = (state = INIT_READ_BANKS_STATE, { type, payload }) => {
  switch (type) {
    case READ_BANKS:
      return {
        loading: true,
        error: undefined,
        banks: undefined
      };
    case READ_BANKS_FAILED:
      return {
        loading: false,
        error: payload,
        banks: undefined
      };
    case READ_BANKS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        banks: payload
      };
    default:
      return state;
  }
};
export const UpdateBank = (state = INIT_CREATE_BANK_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_BANK:
      return {
        loading: true,
        error: undefined,
        bank: undefined
      };
    case UPDATE_BANK_FAILED:
      return {
        loading: false,
        error: payload,
        bank: undefined
      };
    case UPDATE_BANK_SUCCESS:
      return {
        loading: false,
        error: undefined,
        bank: payload
      };
    default:
      return state;
  }
};
export const DeleteBank = (state = INIT_CREATE_BANK_STATE, { type, payload }) => {
  switch (type) {
    case DELETE_BANK:
      return {
        loading: true,
        error: undefined,
        bank: undefined
      };
    case DELETE_BANK_FAILED:
      return {
        loading: false,
        error: payload,
        bank: undefined
      };
    case DELETE_BANK_SUCCESS:
      return {
        loading: false,
        error: undefined,
        bank: payload
      };
    default:
      return state;
  }
};
