import {
  CARDINAL_GET_JWT,
  CARDINAL_GET_JWT_FAILED,
  CARDINAL_GET_JWT_SUCCESS,
  CARDINAL_VALIDATE_JWT,
  CARDINAL_VALIDATE_JWT_FAILED,
  CARDINAL_VALIDATE_JWT_SUCCESS,
  CARDINAL_LOOKUP,
  CARDINAL_LOOKUP_FAILED,
  CARDINAL_LOOKUP_SUCCESS
} from '../../constants/actionTypes';

export const cardinalGetJWT = (payload) => ({ payload, type: CARDINAL_GET_JWT });
export const cardinalGetJWTFailed = (payload) => ({ payload, type: CARDINAL_GET_JWT_FAILED });
export const cardinalGetJWTSuccess = (payload) => ({ payload, type: CARDINAL_GET_JWT_SUCCESS });

export const cardinalValidateJWT = (payload) => ({ payload, type: CARDINAL_VALIDATE_JWT });
export const cardinalValidateJWTFailed = (payload) => ({ payload, type: CARDINAL_VALIDATE_JWT_FAILED });
export const cardinalValidateJWTSuccess = (payload) => ({ payload, type: CARDINAL_VALIDATE_JWT_SUCCESS });

export const cardinalLookup = (payload) => ({ payload, type: CARDINAL_LOOKUP });
export const cardinalLookupFailed = (payload) => ({ payload, type: CARDINAL_LOOKUP_FAILED });
export const cardinalLookupSuccess = (payload) => ({ payload, type: CARDINAL_LOOKUP_SUCCESS });
