import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import fetchJSON from '../../utils/fetchJSON';

import {
  CREATE_SUBSCRIPTION,
  READ_SUBSCRIPTION_ID,
  READ_SUBSCRIPTION_ID_CALENDAR,
  READ_SUBSCRIPTION_ID_CALENDAR_DETAILS,
  READ_ALL_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION
} from '../../constants/actionTypes';

import {
  createSubscriptionFailed,
  createSubscriptionSuccess,
  readSubscriptionIDFailed,
  readSubscriptionIDSuccess,
  readSubscriptionIDCalendarFailed,
  readSubscriptionIDCalendarSuccess,
  readSubscriptionIDCalendarDetailsFailed,
  readSubscriptionIDCalendarDetailsSuccess,
  readAllSubscriptionsFailed,
  readAllSubscriptionsSuccess,
  updateSubscriptionFailed,
  updateSubscriptionSuccess,
  deleteSubscriptionFailed,
  deleteSubscriptionSuccess
} from './actions';

function* createSubscription({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, `/subscriptions`, options);

    yield put(createSubscriptionSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createSubscriptionFailed(message));
  }
}
function* readSubscriptionID({ id, params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/subscriptions/${id}`, options);

    yield put(readSubscriptionIDSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(readSubscriptionIDFailed(message));
  }
}
function* readSubscriptionIDCalendar({ id, params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/subscriptions/${id}/calendar`, options);

    yield put(readSubscriptionIDCalendarSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(readSubscriptionIDCalendarFailed(message));
  }
}
function* readSubscriptionIDCalendarDetails({ id, chargeID, params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/subscriptions/${id}/calendar/${chargeID}`, options);

    yield put(readSubscriptionIDCalendarDetailsSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(readSubscriptionIDCalendarDetailsFailed(message));
  }
}
function* readAllSubscriptions({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/subscriptions`, options);

    yield put(readAllSubscriptionsSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(readAllSubscriptionsFailed(message));
  }
}
function* updateSubscription({ payload: data, subscriptionID }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/subscriptions/${subscriptionID}`, options);

    yield put(updateSubscriptionSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(updateSubscriptionFailed(message));
  }
}
function* deleteSubscription({ payload: data, subscriptionID }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/subscriptions/${subscriptionID}`, options);

    yield put(deleteSubscriptionSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(deleteSubscriptionFailed(message));
  }
}

export function* watchCreateSubscription() {
  yield takeEvery(CREATE_SUBSCRIPTION, createSubscription);
}
export function* watchReadSubscriptionID() {
  yield takeEvery(READ_SUBSCRIPTION_ID, readSubscriptionID);
}
export function* watchReadSubscriptionIDCalendar() {
  yield takeEvery(READ_SUBSCRIPTION_ID_CALENDAR, readSubscriptionIDCalendar);
}
export function* watchReadSubscriptionIDCalendarDetails() {
  yield takeEvery(READ_SUBSCRIPTION_ID_CALENDAR_DETAILS, readSubscriptionIDCalendarDetails);
}
export function* watchReadAllSubscriptions() {
  yield takeEvery(READ_ALL_SUBSCRIPTIONS, readAllSubscriptions);
}
export function* watchUpdateSubscription() {
  yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscription);
}
export function* watchDeleteSubscription() {
  yield takeEvery(DELETE_SUBSCRIPTION, deleteSubscription);
}

export default function* subscriptionsSaga() {
  yield all([
    fork(watchCreateSubscription),
    fork(watchReadSubscriptionID),
    fork(watchReadSubscriptionIDCalendar),
    fork(watchReadSubscriptionIDCalendarDetails),
    fork(watchReadAllSubscriptions),
    fork(watchUpdateSubscription),
    fork(watchDeleteSubscription)
  ]);
}
