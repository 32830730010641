export const SET_COUNT_CATEGORIES = 'SET_COUNT_CATEGORIES';

export const CREATE_CATEGORIES = 'CREATE_CATEGORIES';
export const CREATE_CATEGORIES_FAILED = 'CREATE_CATEGORIES_FAILED';
export const CREATE_CATEGORIES_SUCCESS = 'CREATE_CATEGORIES_SUCCESS';

export const CATEGORIES_REFRESH = 'CATEGORIES_REFRESH';
export const CATEGORIES_REFRESH_SUCCESS = 'CATEGORIES_REFRESH_SUCCESS';
export const CATEGORIES_REFRESH_FAILED = 'CATEGORIES_REFRESH_FAILED';

export const READ_CATEGORIES = 'READ_CATEGORIES';
export const READ_CATEGORIES_FAILED = 'READ_CATEGORIES_FAILED';
export const READ_CATEGORIES_SUCCESS = 'READ_CATEGORIES_SUCCESS';

export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const UPDATE_CATEGORIES_FAILED = 'UPDATE_CATEGORIES_FAILED';
export const UPDATE_CATEGORIES_SUCCESS = 'UPDATE_CATEGORIES_SUCCESS';

export const UPDATE_STATUS_CATEGORY = 'UPDATE_STATUS_CATEGORY';
export const UPDATE_STATUS_CATEGORY_FAILED = 'UPDATE_STATUS_CATEGORY_FAILED';
export const UPDATE_STATUS_CATEGORY_SUCCESS = 'UPDATE_STATUS_CATEGORY_SUCCESS';

export const UPLOAD_IMAGE_CATEGORY = 'UPLOAD_IMAGE_CATEGORY';
export const UPLOAD_IMAGE_CATEGORY_FAILED = 'UPLOAD_IMAGE_CATEGORY_FAILED';
export const UPLOAD_IMAGE_CATEGORY_SUCCESS = 'UPLOAD_IMAGE_CATEGORY_SUCCESS';

export const DELETE_CATEGORIES = 'DELETE_CATEGORIES';
export const DELETE_CATEGORIES_FAILED = 'DELETE_CATEGORIES_FAILED';
export const DELETE_CATEGORIES_SUCCESS = 'DELETE_CATEGORIES_SUCCESS';

//FOR MODAL
export const MODAL_NEW_CATEGORIES_OPEN = 'MODAL_NEW_CATEGORIES_OPEN';
export const MODAL_UPDATE_CATEGORIES_OPEN = 'MODAL_UPDATE_CATEGORIES_OPEN';
export const MODAL_CATEGORIES_CLOSE = 'MODAL_CATEGORIES_CLOSE';
