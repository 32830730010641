import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS, createNotification } from 'react-redux-notify';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import fetchJSON from '../../utils/fetchJSON';

import { createMessageNotify } from '../../utils/notify';

import {
  createUserAccountFailed,
  createUserAccountSuccess,
  deleteUserAccountFailed,
  deleteUserAccountSuccess,
  readUserAccountIDFailed,
  readUserAccountIDSuccess,
  readUsersAccount as readUsersAccountAction,
  readUsersAccountFailed,
  readUsersAccountSuccess,
  unlockUserAccountFailed,
  unlockUserAccountSuccess,
  updateUserAccountFailed,
  updateUserAccountSuccess
} from './actions';

import {
  CREATE_USER_ACCOUNT,
  DELETE_USER_ACCOUNT,
  READ_USERS_ACCOUNT,
  READ_USER_ACCOUNT_ID,
  UNLOCK_USER_ACCOUNT,
  UPDATE_USER_ACCOUNT
} from '../../constants/actionTypes';

function* createUserAccount({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/userAccount', options);
    yield put(createUserAccountSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(createUserAccountFailed(message));
  }
}

function* readUsersAccount({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const { data } = yield call(fetchJSON, '/userAccount', options);
    yield put(readUsersAccountSuccess(data));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(readUsersAccountFailed(message));
  }
}

function* readUserAccountID({ _id, payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/userAccount/${_id}`, options);
    yield put(readUserAccountIDSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(readUserAccountIDFailed(message));
  }
}

function* updateUserAccount({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/userAccount/${_id}`, options);
    localStorage.setItem('lang', response.language || 'es');
    yield put(updateUserAccountSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(updateUserAccountFailed(message));
  }
}

function* deleteUserAccount({ _id, payload: data }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/userAccount/${_id}`, options);
    yield put(deleteUserAccountSuccess(response));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, response.message || response)));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(deleteUserAccountFailed(message));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message)));
  }
}

function* unlockUsersAccount({ _id, payload }) {
  const options = {
    method: 'PUT'
  };
  try {
    const response = yield call(fetchJSON, `/v2/admin/userAccounts/${_id}/unlock`, options);
    yield put(unlockUserAccountSuccess(response));
    yield put(readUsersAccountAction(payload));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Usuario desbloqueado correctamente')));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(unlockUserAccountFailed(message));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message)));
  }
}

export function* watchCreateUserAccount() {
  yield takeEvery(CREATE_USER_ACCOUNT, createUserAccount);
}

export function* watchReadUsersAccount() {
  yield takeEvery(READ_USERS_ACCOUNT, readUsersAccount);
}

export function* watchReadUserAccountID() {
  yield takeEvery(READ_USER_ACCOUNT_ID, readUserAccountID);
}

export function* watchUpdateUserAccount() {
  yield takeEvery(UPDATE_USER_ACCOUNT, updateUserAccount);
}

export function* watchDeleteUserAccount() {
  yield takeEvery(DELETE_USER_ACCOUNT, deleteUserAccount);
}

export function* watchUnlockUsersAccount() {
  yield takeEvery(UNLOCK_USER_ACCOUNT, unlockUsersAccount);
}

function* usersAccountSaga() {
  yield all([
    fork(watchCreateUserAccount),
    fork(watchReadUsersAccount),
    fork(watchReadUserAccountID),
    fork(watchUpdateUserAccount),
    fork(watchDeleteUserAccount),
    fork(watchUnlockUsersAccount)
  ]);
}

export default usersAccountSaga;
