import {
  SET_COUNT_PAYMENTMETHOD,
  CREATE_PAYMENTMETHOD,
  CREATE_PAYMENTMETHOD_FAILED,
  CREATE_PAYMENTMETHOD_SUCCESS,
  READ_PAYMENTMETHODS,
  READ_PAYMENTMETHODS_FAILED,
  READ_PAYMENTMETHODS_SUCCESS,
  UPDATE_PAYMENTMETHOD,
  UPDATE_PAYMENTMETHOD_FAILED,
  UPDATE_PAYMENTMETHOD_SUCCESS,
  DELETE_PAYMENTMETHOD,
  DELETE_PAYMENTMETHOD_FAILED,
  DELETE_PAYMENTMETHOD_SUCCESS
} from '../../constants/actionTypes';

export const setCountPaymentMethods = (payload) => ({ payload, type: SET_COUNT_PAYMENTMETHOD });

export const createPaymentMethod = (payload) => ({ payload, type: CREATE_PAYMENTMETHOD });
export const createPaymentMethodFailed = (payload) => ({ payload, type: CREATE_PAYMENTMETHOD_FAILED });
export const createPaymentMethodSuccess = (payload) => ({ payload, type: CREATE_PAYMENTMETHOD_SUCCESS });

export const readPaymentMethods = (payload) => ({ payload, type: READ_PAYMENTMETHODS });
export const readPaymentMethodsFailed = (payload) => ({ payload, type: READ_PAYMENTMETHODS_FAILED });
export const readPaymentMethodsSuccess = (payload) => ({ payload, type: READ_PAYMENTMETHODS_SUCCESS });

export const updatePaymentMethod = (_id, payload) => ({ _id, payload, type: UPDATE_PAYMENTMETHOD });
export const updatePaymentMethodFailed = (payload) => ({ payload, type: UPDATE_PAYMENTMETHOD_FAILED });
export const updatePaymentMethodSuccess = (payload) => ({ payload, type: UPDATE_PAYMENTMETHOD_SUCCESS });

export const deletePaymentMethod = (_id) => ({ _id, type: DELETE_PAYMENTMETHOD });
export const deletePaymentMethodFailed = (payload) => ({ payload, type: DELETE_PAYMENTMETHOD_FAILED });
export const deletePaymentMethodSuccess = (payload) => ({ payload, type: DELETE_PAYMENTMETHOD_SUCCESS });
