import { AxiosError } from 'axios';
import apiRequest from '../../utils/httpRequest';
import { IUserAccount } from '../interfaces/UserAccount';

export const updateUserAccount = async (_id: string, payload: Partial<IUserAccount>) => {
  try {
    const { data } = await apiRequest.put(`/userAccount/${_id}`, payload);

    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    const responseData: any = axiosError.response?.data;

    throw new Error(responseData?.message || axiosError?.message);
  }
};
