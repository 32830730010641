import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { t } from 'i18next';
import fetchJSON from '../../utils/fetchJSON';

import {
  RETRY_CLOSURE,
  RETRY_CLOSURES_BANCATLAN,
  READ_CLOSURES_CONCILIATION,
  UPLOAD_RESUME_CLOSURE,
  GET_RESUMES_TO_EXPORT
} from '../../constants/actionTypes';

import {
  setCountClosuresConciliation,
  retryClosureBancatlanFailed,
  retryClosureBancatlanSuccess,
  readClosuresConciliationFailed,
  readClosuresConciliationSuccess,
  retryClosureSuccess,
  retryClosureFailed,
  uploadResumeFailed,
  uploadResumeSuccess,
  getResumesToExportFailed,
  getResumesToExportSuccess
} from './actions';

import { createMessageNotify } from '../../utils/notify';
import {
  createNotification,
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS
} from 'react-redux-notify';
import { resumeMapper } from '../../pages/reports/closuresConciliations/UploadResume/mappers';

function* readClosuresConciliation({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/reports/closure`, options);
    const { rows = 0, data } = response;

    yield put(readClosuresConciliationSuccess(data || response));
    yield put(setCountClosuresConciliation(rows));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(readClosuresConciliationFailed(message));
  }
}

function* retryClosure({ _id, payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(
      fetchJSON,
      `/reports/closure/retry/${_id}`,
      options
    );

    yield put(retryClosureSuccess(response));
    yield put(
      createNotification(
        createMessageNotify(
          NOTIFICATION_TYPE_SUCCESS,
          'Cierre realizado correctamente'
        )
      )
    );
  } catch (error) {
    let message;

    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default: {
        const {
          message: { message: messageApi }
        } = error;
        message = messageApi || error.message || 'Not method';
      }
    }
    yield put(retryClosureFailed(message));
    yield put(
      createNotification(
        createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)
      )
    );
  }
}

function* retryClosuresBancatlan({ _id }) {
  const options = {
    method: 'POST'
  };

  try {
    const response = yield call(
      fetchJSON,
      `/reports/closure/retry/${_id}`,
      options
    );

    yield put(retryClosureBancatlanSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(retryClosureBancatlanFailed(message));
  }
}

function* uploadResume({ payload, setLoader }) {
  const options = {
    method: 'POST',
    data: payload
  };

  try {
    const response = yield call(fetchJSON, `/reports/closure/resumes`, options);

    const resumes = response.extract
      ? response.extract.map((resume) => resumeMapper(resume))
      : [];

    yield put(uploadResumeSuccess(response, resumes));
    yield put(
      createNotification(
        createMessageNotify(
          NOTIFICATION_TYPE_SUCCESS,
          t('closures.uploadResume.actions.upload')
        )
      )
    );
  } catch (error) {
    let message;

    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(uploadResumeFailed(message));
    yield put(
      createNotification(
        createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)
      )
    );
  } finally {
    setLoader({
      loading: false,
      disabled: false,
      message: ''
    });
  }
}

function* getResumesToExport({ payload }) {
  const options = {
    method: 'GET',
    params: {
      ...payload
    }
  };

  try {
    const response = yield call(
      fetchJSON,
      '/reports/closure/resumes/export',
      options
    );
    const { data } = response;
    yield put(getResumesToExportSuccess(data || response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(getResumesToExportFailed(message));
    yield put(
      createNotification(
        createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)
      )
    );
  }
}

export function* watchReadClosuresConciliation() {
  yield takeEvery(READ_CLOSURES_CONCILIATION, readClosuresConciliation);
}

export function* watchRetryClosure() {
  yield takeEvery(RETRY_CLOSURE, retryClosure);
}

export function* watchRetryClosureBancatlan() {
  yield takeEvery(RETRY_CLOSURES_BANCATLAN, retryClosuresBancatlan);
}

export function* watchUploadResume() {
  yield takeEvery(UPLOAD_RESUME_CLOSURE, uploadResume);
}

export function* watchGetResumesToExport() {
  yield takeEvery(GET_RESUMES_TO_EXPORT, getResumesToExport);
}

export default function* reportsSaga() {
  yield all([
    fork(watchRetryClosure),
    fork(watchRetryClosureBancatlan),
    fork(watchReadClosuresConciliation),
    fork(watchUploadResume),
    fork(watchGetResumesToExport)
  ]);
}
