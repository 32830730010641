import {
  SET_COUNT_BLACKLIST,
  READ_BLACKLIST,
  READ_BLACKLIST_FAILED,
  READ_BLACKLIST_SUCCESS,
  UPDATE_BLACKLIST_BY_ID,
  UPDATE_BLACKLIST_BY_ID_FAILED,
  UPDATE_BLACKLIST_BY_ID_SUCCESS
} from '../../constants/actionTypes';

export const setCountBlacklist = (payload) => ({ payload, type: SET_COUNT_BLACKLIST });

export const readBlacklist = (payload) => ({ payload, type: READ_BLACKLIST });
export const readBlacklistFailed = (payload) => ({ payload, type: READ_BLACKLIST_FAILED });
export const readBlacklistSuccess = (payload) => ({ payload, type: READ_BLACKLIST_SUCCESS });

export const updateBlacklistById = (_id, payload) => ({ _id, payload, type: UPDATE_BLACKLIST_BY_ID });
export const updateBlacklistByIdFailed = (payload) => ({ payload, type: UPDATE_BLACKLIST_BY_ID_FAILED });
export const updateBlacklistByIdSuccess = (payload) => ({ payload, type: UPDATE_BLACKLIST_BY_ID_SUCCESS });
