import {
  SET_COUNT_SECURITY_PROVIDERS,
  CREATE_SECURITY_PROVIDER,
  CREATE_SECURITY_PROVIDER_FAILED,
  CREATE_SECURITY_PROVIDER_SUCCESS,
  READ_SECURITY_PROVIDERS,
  READ_SECURITY_PROVIDERS_FAILED,
  READ_SECURITY_PROVIDERS_SUCCESS,
  UPDATE_SECURITY_PROVIDER,
  UPDATE_SECURITY_PROVIDER_FAILED,
  UPDATE_SECURITY_PROVIDER_SUCCESS,
  DELETE_SECURITY_PROVIDER,
  DELETE_SECURITY_PROVIDER_FAILED,
  DELETE_SECURITY_PROVIDER_SUCCESS
} from '../../constants/actionTypes';

export const setCountSecurityProviders = (payload) => ({ payload, type: SET_COUNT_SECURITY_PROVIDERS });

export const createSecurityProvider = (payload) => ({ payload, type: CREATE_SECURITY_PROVIDER });
export const createSecurityProviderFailed = (payload) => ({ payload, type: CREATE_SECURITY_PROVIDER_FAILED });
export const createSecurityProviderSuccess = (payload) => ({ payload, type: CREATE_SECURITY_PROVIDER_SUCCESS });

export const readSecurityProviders = (payload) => ({ payload, type: READ_SECURITY_PROVIDERS });
export const readSecurityProvidersFailed = (payload) => ({ payload, type: READ_SECURITY_PROVIDERS_FAILED });
export const readSecurityProvidersSuccess = (payload) => ({ payload, type: READ_SECURITY_PROVIDERS_SUCCESS });

export const updateSecurityProvider = (_id, payload) => ({ _id, payload, type: UPDATE_SECURITY_PROVIDER });
export const updateSecurityProviderFailed = (payload) => ({ payload, type: UPDATE_SECURITY_PROVIDER_FAILED });
export const updateSecurityProviderSuccess = (payload) => ({ payload, type: UPDATE_SECURITY_PROVIDER_SUCCESS });

export const deleteSecurityProvider = (_id) => ({ _id, type: DELETE_SECURITY_PROVIDER });
export const deleteSecurityProviderFailed = (payload) => ({ payload, type: DELETE_SECURITY_PROVIDER_FAILED });
export const deleteSecurityProviderSuccess = (payload) => ({ payload, type: DELETE_SECURITY_PROVIDER_SUCCESS });
