/** ACCOUNT **/

export const SET_COUNT_ACCOUNTS = 'SET_COUNT_ACCOUNTS';

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_FAILED = 'CREATE_ACCOUNT_FAILED';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';

export const GET_MY_ACCOUNT = 'GET_MY_ACCOUNT';
export const GET_MY_ACCOUNT_FAILED = 'GET_MY_ACCOUNT_FAILED';
export const GET_MY_ACCOUNT_SUCCESS = 'GET_MY_ACCOUNT_SUCCESS';

export const READ_ACCOUNT_ID = 'READ_ACCOUNT_ID';
export const READ_ACCOUNT_ID_FAILED = 'READ_ACCOUNT_ID_FAILED';
export const READ_ACCOUNT_ID_SUCCESS = 'READ_ACCOUNT_ID_SUCCESS';

export const GET_ALL_ACCOUNTS = 'GET_ALL_ACCOUNTS';
export const GET_ALL_ACCOUNTS_FAILED = 'GET_ALL_ACCOUNTS_FAILED';
export const GET_ALL_ACCOUNTS_SUCCESS = 'GET_ALL_ACCOUNTS_SUCCESS';

export const REGISTER_ACCOUNT = 'REGISTER_ACCOUNT';
export const REGISTER_ACCOUNT_FAILED = 'REGISTER_ACCOUNT_FAILED';
export const REGISTER_ACCOUNT_SUCCESS = 'REGISTER_ACCOUNT_SUCCESS';

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_FAILED = 'UPDATE_ACCOUNT_FAILED';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';

/* LOGO IMG */
export const UPLOAD_LOGO_ACCOUNT = 'UPLOAD_LOGO_ACCOUNT';
export const UPLOAD_LOGO_ACCOUNT_FAILED = 'UPLOAD_LOGO_ACCOUNT_FAILED';
export const UPLOAD_LOGO_ACCOUNT_SUCCESS = 'UPLOAD_LOGO_ACCOUNT_SUCCESS';

/** TERMINALS **/
export const GET_ACCOUNT_TERMINALS = 'GET_ACCOUNT_TERMINALS';
export const GET_ACCOUNT_TERMINALS_FAILED = 'GET_ACCOUNT_TERMINALS_FAILED';
export const GET_ACCOUNT_TERMINALS_SUCCESS = 'GET_ACCOUNT_TERMINALS_SUCCESS';

export const CREATE_ACCOUNT_TERMINAL = 'CREATE_ACCOUNT_TERMINAL';
export const CREATE_ACCOUNT_TERMINAL_FAILED = 'CREATE_ACCOUNT_TERMINAL_FAILED';
export const CREATE_ACCOUNT_TERMINAL_SUCCESS = 'CREATE_ACCOUNT_TERMINAL_SUCCESS';

export const UPDATE_ACCOUNT_TERMINAL = 'UPDATE_ACCOUNT_TERMINAL';
export const UPDATE_ACCOUNT_TERMINAL_FAILED = 'UPDATE_ACCOUNT_TERMINAL_FAILED';
export const UPDATE_ACCOUNT_TERMINAL_SUCCESS = 'UPDATE_ACCOUNT_TERMINAL_SUCCESS';

export const DELETE_ACCOUNT_TERMINAL = 'DELETE_ACCOUNT_TERMINAL';
export const DELETE_ACCOUNT_TERMINAL_FAILED = 'DELETE_ACCOUNT_TERMINAL_FAILED';
export const DELETE_ACCOUNT_TERMINAL_SUCCESS = 'DELETE_ACCOUNT_TERMINAL_SUCCESS';
