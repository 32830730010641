import {
  SET_COUNT_COUNTRIES,
  CREATE_COUNTRY,
  CREATE_COUNTRY_FAILED,
  CREATE_COUNTRY_SUCCESS,
  SEARCH_COUNTRY,
  SEARCH_COUNTRY_FAILED,
  SEARCH_COUNTRY_SUCCESS,
  RESET_SEARCH_COUNTRY,
  READ_COUNTRIES,
  READ_COUNTRIES_FAILED,
  READ_COUNTRIES_SUCCESS,
  UPDATE_COUNTRY,
  UPDATE_COUNTRY_FAILED,
  UPDATE_COUNTRY_SUCCESS,
  DELETE_COUNTRY,
  DELETE_COUNTRY_FAILED,
  DELETE_COUNTRY_SUCCESS
} from '../../constants/actionTypes';

export const setCountCountries = (payload) => ({ payload, type: SET_COUNT_COUNTRIES });

export const createCountry = (payload) => ({ payload, type: CREATE_COUNTRY });
export const createCountryFailed = (payload) => ({ payload, type: CREATE_COUNTRY_FAILED });
export const createCountrySuccess = (payload) => ({ payload, type: CREATE_COUNTRY_SUCCESS });

export const resetSearchCountry = () => ({ type: RESET_SEARCH_COUNTRY });
export const searchCountry = (payload) => ({ payload, type: SEARCH_COUNTRY });
export const searchCountryFailed = (payload) => ({ payload, type: SEARCH_COUNTRY_FAILED });
export const searchCountrySuccess = (payload) => ({ payload, type: SEARCH_COUNTRY_SUCCESS });

export const readCountries = (payload) => ({ payload, type: READ_COUNTRIES });
export const readCountriesFailed = (payload) => ({ payload, type: READ_COUNTRIES_FAILED });
export const readCountriesSuccess = (payload) => ({ payload, type: READ_COUNTRIES_SUCCESS });

export const updateCountry = (_id, payload) => ({ _id, payload, type: UPDATE_COUNTRY });
export const updateCountryFailed = (payload) => ({ payload, type: UPDATE_COUNTRY_FAILED });
export const updateCountrySuccess = (payload) => ({ payload, type: UPDATE_COUNTRY_SUCCESS });

export const deleteCountry = (_id) => ({ _id, type: DELETE_COUNTRY });
export const deleteCountryFailed = (payload) => ({ payload, type: DELETE_COUNTRY_FAILED });
export const deleteCountrySuccess = (payload) => ({ payload, type: DELETE_COUNTRY_SUCCESS });
