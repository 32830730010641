import {
  CREATE_USER_ACCOUNT,
  CREATE_USER_ACCOUNT_FAILED,
  CREATE_USER_ACCOUNT_SUCCESS,
  READ_USERS_ACCOUNT,
  READ_USERS_ACCOUNT_FAILED,
  READ_USERS_ACCOUNT_SUCCESS,
  READ_USER_ACCOUNT_ID,
  READ_USER_ACCOUNT_ID_FAILED,
  READ_USER_ACCOUNT_ID_SUCCESS,
  UPDATE_USER_ACCOUNT,
  UPDATE_USER_ACCOUNT_FAILED,
  UPDATE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_ACCOUNT,
  DELETE_USER_ACCOUNT_FAILED,
  DELETE_USER_ACCOUNT_SUCCESS,
  UNLOCK_USER_ACCOUNT,
  UNLOCK_USER_ACCOUNT_FAILED,
  UNLOCK_USER_ACCOUNT_SUCCESS
} from '../../constants/actionTypes';

const INIT_STATE = {
  loading: false,
  error: undefined,
  userAccount: undefined,
  usersAccount: undefined
};

export const CreateUserAccount = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_USER_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: undefined,
        userAccount: undefined
      };
    case CREATE_USER_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        userAccount: undefined
      };
    case CREATE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        userAccount: payload
      };
    default:
      return { ...state };
  }
};

export const ReadUsersAccount = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case READ_USERS_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: undefined,
        usersAccount: undefined
      };
    case READ_USERS_ACCOUNT_FAILED:
      return {
        loading: false,
        error: payload,
        usersAccount: undefined
      };
    case READ_USERS_ACCOUNT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        usersAccount: payload
      };
    default:
      return { ...state };
  }
};

export const ReadUserAccountID = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case READ_USER_ACCOUNT_ID:
      return {
        ...state,
        loading: true,
        error: undefined,
        userAccount: undefined
      };
    case READ_USER_ACCOUNT_ID_FAILED:
      return {
        loading: false,
        error: payload,
        userAccount: undefined
      };
    case READ_USER_ACCOUNT_ID_SUCCESS:
      return {
        loading: false,
        error: undefined,
        userAccount: payload
      };
    default:
      return { ...state };
  }
};

export const UpdateUserAccount = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_USER_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: undefined,
        userAccount: undefined
      };
    case UPDATE_USER_ACCOUNT_FAILED:
      return {
        loading: false,
        error: payload,
        userAccount: undefined
      };
    case UPDATE_USER_ACCOUNT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        userAccount: payload
      };
    default:
      return { ...state };
  }
};

export const DeleteUserAccount = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case DELETE_USER_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: undefined,
        userAccount: undefined
      };
    case DELETE_USER_ACCOUNT_FAILED:
      return {
        loading: false,
        error: payload,
        userAccount: undefined
      };
    case DELETE_USER_ACCOUNT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        userAccount: payload
      };
    default:
      return { ...state };
  }
};

export const UnlockUsersAccount = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case UNLOCK_USER_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: undefined,
        userAccount: undefined
      };
    case UNLOCK_USER_ACCOUNT_FAILED:
      return {
        loading: false,
        error: payload,
        userAccount: undefined
      };
    case UNLOCK_USER_ACCOUNT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        userAccount: payload
      };
    default:
      return state;
  }
};
