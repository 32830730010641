import {
  SET_COUNT_CATEGORIES,
  CREATE_CATEGORIES,
  CREATE_CATEGORIES_FAILED,
  CREATE_CATEGORIES_SUCCESS,
  CATEGORIES_REFRESH,
  CATEGORIES_REFRESH_SUCCESS,
  CATEGORIES_REFRESH_FAILED,
  READ_CATEGORIES,
  READ_CATEGORIES_FAILED,
  READ_CATEGORIES_SUCCESS,
  UPDATE_CATEGORIES,
  UPDATE_CATEGORIES_FAILED,
  UPDATE_CATEGORIES_SUCCESS,
  UPDATE_STATUS_CATEGORY,
  UPDATE_STATUS_CATEGORY_FAILED,
  UPDATE_STATUS_CATEGORY_SUCCESS,
  UPLOAD_IMAGE_CATEGORY,
  UPLOAD_IMAGE_CATEGORY_FAILED,
  UPLOAD_IMAGE_CATEGORY_SUCCESS,
  DELETE_CATEGORIES,
  DELETE_CATEGORIES_FAILED,
  DELETE_CATEGORIES_SUCCESS,
  MODAL_NEW_CATEGORIES_OPEN,
  MODAL_UPDATE_CATEGORIES_OPEN,
  MODAL_CATEGORIES_CLOSE
} from '../../constants/actionTypes';

export const setCountCategories = (payload) => ({ payload, type: SET_COUNT_CATEGORIES });

export const createCategory = (payload) => ({ payload, type: CREATE_CATEGORIES });
export const createCategoryFailed = (payload) => ({ payload, type: CREATE_CATEGORIES_FAILED });
export const createCategorySuccess = (payload) => ({ payload, type: CREATE_CATEGORIES_SUCCESS });

export const categoriesRefresh = (payload) => ({ payload, type: CATEGORIES_REFRESH });
export const categoriesRefreshSuccess = (payload) => ({ payload, type: CATEGORIES_REFRESH_SUCCESS });
export const categoriesRefreshFaliled = () => ({ type: CATEGORIES_REFRESH_FAILED });

export const readCategories = (payload) => ({ payload, type: READ_CATEGORIES });
export const readCategoriesFailed = (payload) => ({ payload, type: READ_CATEGORIES_FAILED });
export const readCategoriesSuccess = (payload) => ({ payload, type: READ_CATEGORIES_SUCCESS });

export const updateCategories = (_id, payload) => ({ _id, payload, type: UPDATE_CATEGORIES });
export const updateCategoriesFailed = (payload) => ({ payload, type: UPDATE_CATEGORIES_FAILED });
export const updateCategoriesSuccess = (payload) => ({ payload, type: UPDATE_CATEGORIES_SUCCESS });

export const updateStatusCategory = (_id, payload) => ({ _id, payload, type: UPDATE_STATUS_CATEGORY });
export const updateStatusCategoryFailed = (payload) => ({ payload, type: UPDATE_STATUS_CATEGORY_FAILED });
export const updateStatusCategorySuccess = (payload) => ({ payload, type: UPDATE_STATUS_CATEGORY_SUCCESS });

export const uploadImageCategory = (_id, payload) => ({ _id, payload, type: UPLOAD_IMAGE_CATEGORY });
export const uploadImageCategoryFailed = (payload) => ({ payload, type: UPLOAD_IMAGE_CATEGORY_FAILED });
export const uploadImageCategorySuccess = (payload) => ({ payload, type: UPLOAD_IMAGE_CATEGORY_SUCCESS });

export const deleteCategories = (_id) => ({ _id, type: DELETE_CATEGORIES });
export const deleteCategoriesFailed = (payload) => ({ payload, type: DELETE_CATEGORIES_FAILED });
export const deleteCategoriesSuccess = (payload) => ({ payload, type: DELETE_CATEGORIES_SUCCESS });

export const modalNewCategoriesOpen = () => ({ type: MODAL_NEW_CATEGORIES_OPEN });
export const modalUpdateCategoriesOpen = () => ({ type: MODAL_UPDATE_CATEGORIES_OPEN });
export const modalCategoriesClose = () => ({ type: MODAL_CATEGORIES_CLOSE });
