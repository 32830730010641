export const SET_COUNT_ISSUER_BINS = 'SET_COUNT_ISSUER_BINS';

export const CREATE_ISSUER_BINS = 'CREATE_ISSUER_BINS';
export const CREATE_ISSUER_BINS_FAILED = 'CREATE_ISSUER_BINS_FAILED';
export const CREATE_ISSUER_BINS_SUCCESS = 'CREATE_ISSUER_BINS_SUCCESS';

export const SEARCH_ISSUER_BINS = 'SEARCH_ISSUER_BINS';
export const SEARCH_ISSUER_BINS_FAILED = 'SEARCH_ISSUER_BINS_FAILED';
export const SEARCH_ISSUER_BINS_SUCCESS = 'SEARCH_ISSUER_BINS_SUCCESS';
export const RESET_SEARCH_ISSUER_BINS = 'RESET_SEARCH_ISSUER_BINS';

export const READ_ISSUER_BINS = 'READ_ISSUER_BINS';
export const READ_ISSUER_BINS_FAILED = 'READ_ISSUER_BINS_FAILED';
export const READ_ISSUER_BINS_SUCCESS = 'READ_ISSUER_BINS_SUCCESS';

export const UPDATE_STATUS_ISSUER_BINS = 'UPDATE_STATUS_ISSUER_BINS';
export const UPDATE_STATUS_ISSUER_BINS_FAILED = 'UPDATE_STATUS_ISSUER_BINS_FAILED';
export const UPDATE_STATUS_ISSUER_BINS_SUCCESS = 'UPDATE_STATUS_ISSUER_BINS_SUCCESS';

export const UPDATE_ISSUER_BINS = 'UPDATE_ISSUER_BINS';
export const UPDATE_ISSUER_BINS_FAILED = 'UPDATE_ISSUER_BINS_FAILED';
export const UPDATE_ISSUER_BINS_SUCCESS = 'UPDATE_ISSUER_BINS_SUCCESS';

export const DELETE_ISSUER_BINS = 'DELETE_ISSUER_BINS';
export const DELETE_ISSUER_BINS_FAILED = 'DELETE_ISSUER_BINS_FAILED';
export const DELETE_ISSUER_BINS_SUCCESS = 'DELETE_ISSUER_BINS_SUCCESS';
