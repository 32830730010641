export const SET_COUNT_BANKS = 'SET_COUNT_BANKS';

export const RESET_CREATED_BANK = 'RESET_CREATED_BANK';

export const CREATE_BANK = 'CREATE_BANK';
export const CREATE_BANK_FAILED = 'CREATE_BANK_FAILED';
export const CREATE_BANK_SUCCESS = 'CREATE_BANK_SUCCESS';

export const READ_BANKS = 'READ_BANKS';
export const READ_BANKS_FAILED = 'READ_BANKS_FAILED';
export const READ_BANKS_SUCCESS = 'READ_BANKS_SUCCESS';

export const UPDATE_BANK = 'UPDATE_BANK';
export const UPDATE_BANK_FAILED = 'UPDATE_BANK_FAILED';
export const UPDATE_BANK_SUCCESS = 'UPDATE_BANK_SUCCESS';

export const DELETE_BANK = 'DELETE_BANK';
export const DELETE_BANK_FAILED = 'DELETE_BANK_FAILED';
export const DELETE_BANK_SUCCESS = 'DELETE_BANK_SUCCESS';
