import fetchJSON from '../../utils/fetchJSON';
import { createMessageNotify } from '../../utils/notify';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { createNotification, NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify';

import { CREATE_SECURITY_PROVIDER, READ_SECURITY_PROVIDERS, UPDATE_SECURITY_PROVIDER, DELETE_SECURITY_PROVIDER } from '../../constants/actionTypes';

import {
  setCountSecurityProviders,
  createSecurityProviderFailed,
  createSecurityProviderSuccess,
  readSecurityProvidersFailed,
  readSecurityProvidersSuccess,
  updateSecurityProviderFailed,
  updateSecurityProviderSuccess,
  deleteSecurityProviderFailed,
  deleteSecurityProviderSuccess
} from './actions';

const BASE_URL = '/v2/admin/providers/services/security';

function* createSecurityProvider({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, BASE_URL, options);

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Proveedor de seguridad creado correctamente')));
    yield put(createSecurityProviderSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(createSecurityProviderFailed(message));
  }
}

function* readSecurityProviders({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, BASE_URL, options);
    const { rows = 0, data } = response;
    yield put(readSecurityProvidersSuccess(data || response));
    yield put(setCountSecurityProviders(rows));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Security Providers not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(readSecurityProvidersFailed(message));
  }
}

function* updateSecurityProvider({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `${BASE_URL}/${_id}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Proveedor de seguridad actualizado correctamente')));
    yield put(updateSecurityProviderSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(updateSecurityProviderFailed(message));
  }
}

function* deleteSecurityProvider({ _id: providerID }) {
  const options = {
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `${BASE_URL}/${providerID}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Proveedor de seguridad actualizado correctamente')));
    yield put(deleteSecurityProviderSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(deleteSecurityProviderFailed(message));
  }
}

export function* watchCreateSecurityProvider() {
  yield takeEvery(CREATE_SECURITY_PROVIDER, createSecurityProvider);
}

export function* watchReadSecurityProviders() {
  yield takeEvery(READ_SECURITY_PROVIDERS, readSecurityProviders);
}

export function* watchUpdateSecurityProvider() {
  yield takeEvery(UPDATE_SECURITY_PROVIDER, updateSecurityProvider);
}

export function* watchDeleteSecurityProvider() {
  yield takeEvery(DELETE_SECURITY_PROVIDER, deleteSecurityProvider);
}

function* SecurityProvidersSaga() {
  yield all([
    fork(watchCreateSecurityProvider),
    fork(watchReadSecurityProviders),
    fork(watchUpdateSecurityProvider),
    fork(watchDeleteSecurityProvider)
  ]);
}

export default SecurityProvidersSaga;
