import { useSelector } from 'react-redux';
import { Notify } from 'react-redux-notify';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { Suspense, lazy, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { getLoggedInUser } from '../helpers/authUtils';
import { Loading as loading } from '../components/Loader';

const Topbar = lazy(() => import('./Topbar'));
const LeftSidebar = lazy(() => import('./LeftSidebar'));

const DefaultLayout = (props) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.Auth.user);

  const [authState, setAuthState] = useState({
    isCondensed: false,
    user: user || getLoggedInUser()
  });

  const location = useLocation();

  const toggleMenu = (e) => {
    e.preventDefault();

    setAuthState({ ...authState, isCondensed: !authState.isCondensed });
  };

  return (
    <div className="app" data-cy={'main-dashboard'}>
      <Suspense fallback={loading()}>
        <LeftSidebar
          isCondensed={authState.isCondensed}
          location={location}
          {...props}
        />
      </Suspense>

      <div id="wrapper">
        <Suspense fallback={loading()}>
          <Topbar
            menuToggle={toggleMenu}
            title={t(`pageTitles.${props.pageTitle}`)}
          />
        </Suspense>

        <div className="content-page">
          {!authState.user?.account?.isActive && (
            <Alert
              variant="danger"
              className="bg-danger text-white border-0 mt-1">
              Cuenta inactiva
            </Alert>
          )}

          <div className="content">
            <Container fluid>
              <Suspense fallback={loading()}>
                <Notify />
                <ToastContainer
                  data-cy={'notification-toast'}
                  theme={'colored'}
                  closeOnClick={false}
                  position={'top-right'}
                  autoClose={3000}
                />
                {props.children}
              </Suspense>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
