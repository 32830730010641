import {
  GET_GLOBAL_CONFIGS,
  GET_GLOBAL_CONFIGS_FAILED,
  GET_GLOBAL_CONFIGS_SUCCESS,
  UPDATE_GLOBAL_CONFIGS,
  UPDATE_GLOBAL_CONFIGS_FAILED,
  UPDATE_GLOBAL_CONFIGS_SUCCESS
} from '../../constants/actionTypes';

const INIT_STATE_GET_GLOBAL_CONFIGS = {
  loading: false,
  error: undefined,
  config: undefined,
  configs: undefined
};

export const GetGlobalConfigs = (state = INIT_STATE_GET_GLOBAL_CONFIGS, { type, payload }) => {
  switch (type) {
    case GET_GLOBAL_CONFIGS:
      return {
        ...state,
        loading: true,
        error: undefined,
        configs: undefined
      };
    case GET_GLOBAL_CONFIGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        configs: payload
      };
    case GET_GLOBAL_CONFIGS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        configs: undefined
      };
    default:
      return { ...state };
  }
};
export const UpdateGlobalConfigs = (state = INIT_STATE_GET_GLOBAL_CONFIGS, { type, payload }) => {
  switch (type) {
    case UPDATE_GLOBAL_CONFIGS:
      return {
        loading: true,
        error: undefined,
        config: undefined
      };
    case UPDATE_GLOBAL_CONFIGS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        config: payload
      };
    case UPDATE_GLOBAL_CONFIGS_FAILED:
      return {
        loading: false,
        error: payload,
        config: undefined
      };
    default:
      return { ...state };
  }
};
