import fetchJSON from '../../utils/fetchJSON';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { createNotification, NOTIFICATION_TYPE_ERROR } from 'react-redux-notify';

import { CARDINAL_GET_JWT, CARDINAL_VALIDATE_JWT, CARDINAL_LOOKUP } from '../../constants/actionTypes';

import {
  cardinalGetJWTFailed,
  cardinalGetJWTSuccess,
  cardinalValidateJWTFailed,
  cardinalValidateJWTSuccess,
  cardinalLookupFailed,
  cardinalLookupSuccess
} from './actions';

import { createMessageNotify } from '../../utils/notify';

function* cardinalGetJWT() {
  const options = {
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/v2/cardinal/encode', options);
    yield put(cardinalGetJWTSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(cardinalGetJWTFailed(message));
  }
}

function* cardinalValidateJWT({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/v2/cardinal/decode', options);
    yield put(cardinalValidateJWTSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(cardinalValidateJWTFailed(message));
  }
}

function* cardinalLookup({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/v2/cardinal/lookup', options);
    yield put(cardinalLookupSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(cardinalLookupFailed(message));
  }
}

export function* watchCardinalGetJWT() {
  yield takeEvery(CARDINAL_GET_JWT, cardinalGetJWT);
}

export function* watchCardinalValidateJWT() {
  yield takeEvery(CARDINAL_VALIDATE_JWT, cardinalValidateJWT);
}

export function* watchCardinalLookup() {
  yield takeEvery(CARDINAL_LOOKUP, cardinalLookup);
}

export default function* CardinalGetJWTSaga() {
  yield all([fork(watchCardinalGetJWT), fork(watchCardinalValidateJWT), fork(watchCardinalLookup)]);
}
