import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import fetchJSON from '../../utils/fetchJSON';

import { CREATE_PRODUCT, DELETE_PRODUCT, READ_PRODUCTS, UPDATE_PRODUCT } from '../../constants/actionTypes';

import {
  createProductFailed,
  createProductSuccess,
  deleteProductFailed,
  deleteProductSuccess,
  readProductsFailed,
  readProductsSuccess,
  updateProductFailed,
  updateProductSuccess
} from './actions';

function* createProduct({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, `/products`, options);

    yield put(createProductSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createProductFailed(message));
  }
}
function* readProducts({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const { data } = yield call(fetchJSON, `/products`, options);

    yield put(readProductsSuccess(data));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(readProductsFailed(message));
  }
}
function* updateProduct({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/products/${_id}`, options);

    yield put(updateProductSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(updateProductFailed(message));
  }
}
function* deleteProduct({ _id }) {
  const options = {
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/products/${_id}`, options);

    yield put(deleteProductSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(deleteProductFailed(message));
  }
}

export function* watchCreateProduct() {
  yield takeEvery(CREATE_PRODUCT, createProduct);
}
export function* watchReadProducts() {
  yield takeEvery(READ_PRODUCTS, readProducts);
}
export function* watchUpdateProduct() {
  yield takeEvery(UPDATE_PRODUCT, updateProduct);
}
export function* watchDeleteProduct() {
  yield takeEvery(DELETE_PRODUCT, deleteProduct);
}

export default function* productsSaga() {
  yield all([fork(watchCreateProduct), fork(watchReadProducts), fork(watchUpdateProduct), fork(watchDeleteProduct)]);
}
