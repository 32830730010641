import fetchJSON from '../../utils/fetchJSON';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';

import { COUNT_CUSTOMERS, CREATE_CUSTOMER, READ_CUSTOMERS, UPDATE_CUSTOMER, DELETE_CUSTOMER } from '../../constants/actionTypes';

import {
  countCustomersFailed,
  countCustomersSuccess,
  createCustomerFailed,
  createCustomerSuccess,
  readCustomersFailed,
  readCustomersSuccess,
  updateCustomerFailed,
  updateCustomerSuccess,
  deleteCustomerFailed,
  deleteCustomerSuccess
} from './actions';

function* countCustomers({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/customers/count', options);

    yield put(countCustomersSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(countCustomersFailed(message));
  }
}
function* createCustomer({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/customers', options);
    yield put(createCustomerSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(createCustomerFailed(message));
  }
}
function* readCustomers({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/customers', options);
    yield put(readCustomersSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(readCustomersFailed(message));
  }
}
function* updateCustomer({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/customers/${_id}`, options);
    yield put(updateCustomerSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(updateCustomerFailed(message));
  }
}
function* deleteCustomer({ _id, payload: data }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/customers/${_id}`, options);
    yield put(deleteCustomerSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(deleteCustomerFailed(message));
  }
}

export function* watchCountCustomers() {
  yield takeEvery(COUNT_CUSTOMERS, countCustomers);
}
export function* watchCreateCustomer() {
  yield takeEvery(CREATE_CUSTOMER, createCustomer);
}
export function* watchReadCustomers() {
  yield takeEvery(READ_CUSTOMERS, readCustomers);
}
export function* watchUpdateCustomer() {
  yield takeEvery(UPDATE_CUSTOMER, updateCustomer);
}
export function* watchDeleteCustomer() {
  yield takeEvery(DELETE_CUSTOMER, deleteCustomer);
}

function* CustomersSaga() {
  yield all([fork(watchCountCustomers), fork(watchCreateCustomer), fork(watchReadCustomers), fork(watchUpdateCustomer), fork(watchDeleteCustomer)]);
}

export default CustomersSaga;
