/** CARDINAL **/
export const CARDINAL_GET_JWT = 'CARDINAL_GET_JWT';
export const CARDINAL_GET_JWT_FAILED = 'CARDINAL_GET_JWT_FAILED';
export const CARDINAL_GET_JWT_SUCCESS = 'CARDINAL_GET_JWT_SUCCESS';

export const CARDINAL_VALIDATE_JWT = 'CARDINAL_VALIDATE_JWT';
export const CARDINAL_VALIDATE_JWT_FAILED = 'CARDINAL_VALIDATE_JWT_FAILED';
export const CARDINAL_VALIDATE_JWT_SUCCESS = 'CARDINAL_VALIDATE_JWT_SUCCESS';

export const CARDINAL_LOOKUP = 'CARDINAL_LOOKUP';
export const CARDINAL_LOOKUP_FAILED = 'CARDINAL_LOOKUP_FAILED';
export const CARDINAL_LOOKUP_SUCCESS = 'CARDINAL_LOOKUP_SUCCESS';

export const CARDINAL_ONBOARDING = 'CARDINAL_ONBOARBING';
export const CARDINAL_ONBOARBING_FAILED = 'CARDINAL_ONBOARBING_FAILED';
export const CARDINAL_ONBOARBING_SUCCESS = 'CARDINAL_ONBOARBING_SUCCESS';
