/** AUTH **/
export const LOGOUT_USER = 'LOGOUT_USER';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

/* RECUPERAR CONTRASEÑA */
export const RECOVERY_USER_PASSWORD = 'RECOVERY_USER_PASSWORD';
export const RECOVERY_USER_PASSWORD_FAILED = 'RECOVERY_USER_PASSWORD_FAILED';
export const RECOVERY_USER_PASSWORD_SUCCESS = 'RECOVERY_USER_PASSWORD_SUCCESS';

export const RECOVERY_USER_PASSWORD_DECRYPT = 'RECOVERY_USER_PASSWORD_DECRYPT';
export const RECOVERY_USER_PASSWORD_DECRYPT_FAILED = 'RECOVERY_USER_PASSWORD_DECRYPT_FAILED';
export const RECOVERY_USER_PASSWORD_DECRYPT_SUCCESS = 'RECOVERY_USER_PASSWORD_DECRYPT_SUCCESS';

export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_USER_PASSWORD_FAILED = 'UPDATE_USER_PASSWORD_FAILED';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
