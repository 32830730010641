import {
  SET_COUNT_CLOSURES_CONCILIATION,
  RETRY_CLOSURES_BANCATLAN,
  RETRY_CLOSURES_BANCATLAN_FAILED,
  RETRY_CLOSURES_BANCATLAN_SUCCESS,
  RETRY_CLOSURE,
  RETRY_CLOSURE_FAILED,
  RETRY_CLOSURE_SUCCESS,
  READ_CLOSURES_CONCILIATION,
  READ_CLOSURES_CONCILIATION_FAILED,
  READ_CLOSURES_CONCILIATION_SUCCESS,
  UPLOAD_RESUME_CLOSURE,
  UPLOAD_RESUME_CLOSURE_FAILED,
  UPLOAD_RESUME_CLOSURE_SUCCESS,
  UPLOAD_RESUME_CLOSURE_RESET,
  GET_RESUMES_TO_EXPORT,
  GET_RESUMES_TO_EXPORT_FAILED,
  GET_RESUMES_TO_EXPORT_SUCCESS
} from '../../constants/actionTypes';

export const setCountClosuresConciliation = (payload) => ({ payload, type: SET_COUNT_CLOSURES_CONCILIATION });

export const readClosuresConciliation = (payload) => ({ payload, type: READ_CLOSURES_CONCILIATION });
export const readClosuresConciliationFailed = (payload) => ({ payload, type: READ_CLOSURES_CONCILIATION_FAILED });
export const readClosuresConciliationSuccess = (payload) => ({ payload, type: READ_CLOSURES_CONCILIATION_SUCCESS });

export const retryClosure = (_id, payload) => ({ _id, payload, type: RETRY_CLOSURE });
export const retryClosureFailed = (payload) => ({ payload, type: RETRY_CLOSURE_FAILED });
export const retryClosureSuccess = (payload) => ({ payload, type: RETRY_CLOSURE_SUCCESS });

export const uploadResume = (payload, setLoader) => ({ payload, setLoader, type: UPLOAD_RESUME_CLOSURE });
export const uploadResumeFailed = (payload) => ({ payload, type: UPLOAD_RESUME_CLOSURE_FAILED });
export const uploadResumeSuccess = (payload, resumes) => ({ payload, resumes, type: UPLOAD_RESUME_CLOSURE_SUCCESS });
export const uploadResumeReset = (payload) => ({ payload, type: UPLOAD_RESUME_CLOSURE_RESET });

export const getResumesToExport = (payload) => ({ payload, type: GET_RESUMES_TO_EXPORT });
export const getResumesToExportFailed = (payload) => ({ payload, type: GET_RESUMES_TO_EXPORT_FAILED });
export const getResumesToExportSuccess = (payload) => ({ payload, type: GET_RESUMES_TO_EXPORT_SUCCESS });

export const retryClosureBancatlan = (_id, payload) => ({ _id, payload, type: RETRY_CLOSURES_BANCATLAN });
export const retryClosureBancatlanFailed = (payload) => ({ payload, type: RETRY_CLOSURES_BANCATLAN_FAILED });
export const retryClosureBancatlanSuccess = (payload) => ({ payload, type: RETRY_CLOSURES_BANCATLAN_SUCCESS });
