import { t } from 'i18next';
import moment from 'moment';

import Badge from '../../../../../components/Badge';
import { AmountFormat } from '../../../../../components/helpers';

export const ReconciliationValue = (reconciliation) => {
  if (!reconciliation) return <Badge label={t('closures.uploadResume.statuses.noReconciled')} variant={'danger'} />;

  return <Badge label={t('closures.uploadResume.statuses.reconciled')} variant={'success'} />;
};

export const DifferenceValue = ({ bankCommissions, closureBankCommission, currency }) => {
  const difference = closureBankCommission - bankCommissions;
  
  const unsignedDifference = Math.abs(difference);
  const differenceFormat = AmountFormat({ value: unsignedDifference.toString(), currency });

  if (difference < 0) return <Badge label={differenceFormat} variant={'danger'} includeDot={false} />;

  return <Badge label={differenceFormat} variant={'success'} includeDot={false} />;
};

export const transactionMapper = (transactionObject, currency) => ({
  date: moment(transactionObject.date).format('DD/MM/YYYY HH:mm'),
  transactionID: transactionObject.transactionID,
  reconciliation: ReconciliationValue(transactionObject.reconciliation),
  bankCommission: AmountFormat({ value: transactionObject.bankCommission, currency }),
  amount: AmountFormat({ value: transactionObject.amount, currency }),
  total: AmountFormat({ value: transactionObject.total, currency }),
  bankCommissionRate: `${transactionObject.bankCommissionRate}%`,
  clinpaysCommissionRate: `${transactionObject.clinpaysCommissionRate}%`,
  difference: DifferenceValue({ bankCommissions: +transactionObject.bankCommission, closureBankCommission: +transactionObject.clinpaysCommission, currency }),
});

export const resumeMapper = (resumeObject) => ({
  account: resumeObject.account ? resumeObject.account?.displayName : resumeObject.affiliatedName,
  amount: AmountFormat({ value: resumeObject.amount, currency: resumeObject.currency }),
  date: moment(resumeObject.date).format('DD/MM/YYYY HH:mm'),
  bankCommissions: AmountFormat({ value: resumeObject.bankCommissions, currency: resumeObject.currency }),
  bankCommissionRate: Array.isArray(resumeObject.commissionsRates?.bank) ? resumeObject.commissionsRates.bank.map(c => `${c}%`).join(', ') : `${resumeObject.commissionsRates.bank}%`,
  clinpaysCommissionRate: Array.isArray(resumeObject.commissionsRates?.clinpays) ? resumeObject.commissionsRates.clinpays.map(c => `${c}%`).join(', ') : `${resumeObject.commissionsRates.clinpays}%`,
  difference: DifferenceValue({ bankCommissions: +resumeObject.bankCommissions, closureBankCommission: +resumeObject.closureConciliationBankCommission, currency: resumeObject.currency }),
  total: AmountFormat({ value: resumeObject.total, currency: resumeObject.currency }),
  reconciliation: ReconciliationValue(resumeObject.reconciliation),
  transactions: resumeObject.transactions ? resumeObject.transactions.map((transaction) => transactionMapper(transaction, resumeObject.currency)) : []
});
