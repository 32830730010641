import fetchJSON from '../../utils/fetchJSON';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  REVERT_TRANSACTION,
  GET_ONE_TRANSACTION,
  GET_ALL_TRANSACTIONS,
  GET_ALL_WALLET_TRANSACTIONS,
  GET_TRANSACTIONS_TO_EXPORT,
  REPORT_COMMISSIONS_TRANSACTIONS,
  CHARGEBACK,
  GET_PENDING_CLOSURE_TRANSACTIONS,
  RECALCULATE_TRANSACTIONS_FEES
} from '../../constants/actionTypes';

import {
  setCountTransactions,
  setCountWalletTransactions,
  getTransactionsFailed,
  getTransactionsSuccess,
  getWalletTransactionsFailed,
  getWalletTransactionsSuccess,
  revertTransactionFailed,
  revertTransactionSuccess,
  getOneTransactionFailed,
  getOneTransactionSuccess,
  getTransactionsToExportFailed,
  getTransactionsToExportSuccess,
  getPendingClosureTransactionsFailed,
  getPendingClosureTransactionsSuccess,
  getReportCommissionsTransactionsFailed,
  getReportCommissionsTransactionsSuccess,
  chargeBackSuccess,
  chargeBackFailed,
  recalculateTransactionsFeesFailed,
  recalculateTransactionsFeesSuccess
} from './actions';
import { createNotification, NOTIFICATION_TYPE_ERROR } from 'react-redux-notify';
import { createMessageNotify } from '../../utils/notify';
import { getCookie, setCookie } from '../../helpers/browserCookieManager';

function* getTransactionsToExport({ payload }) {
  const options = {
    method: 'GET',
    params: {
      ...payload,
      include: 'account'
    }
  };

  try {
    const response = yield call(fetchJSON, `/payments/reports/export`, options);
    const { data } = response;
    yield put(getTransactionsToExportSuccess(data || response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(getTransactionsToExportFailed(message));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
  }
}

function* getTransactionsByDate({ payload }) {
  const options = {
    method: 'GET',
    params: {
      ...payload,
      include: 'account'
    }
  };

  try {
    const response = yield call(fetchJSON, `/payments`, options);
    const { rows = 0, data = [], amount = 0 } = response;
    yield put(setCountTransactions({ rows, amount }));
    yield put(getTransactionsSuccess(data));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(getTransactionsFailed(message));
  }
}

function* getWalletTransactions({ payload }) {
  const options = {
    method: 'GET',
    params: {
      ...payload
    }
  };

  try {
    const response = yield call(fetchJSON, '/payments/wallets', options);
    const { data, rows } = response;
    yield put(getWalletTransactionsSuccess(data));
    yield put(setCountWalletTransactions({ rows }));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(getWalletTransactionsFailed(message));
  }
}

function* getPendingClosure({ payload }) {
  const options = {
    method: 'GET',
    params: {
      ...payload
    }
  };

  try {
    const response = yield call(fetchJSON, '/payments/pendingClosure', options);

    if (Object.keys(response).length > 0) {
      let systemNotifications = getCookie('systemNotifications') || [];
      systemNotifications = systemNotifications.filter((notification) => notification.type !== 'pendingClosure');
      systemNotifications.push(response);
      setCookie('systemNotifications', JSON.stringify(systemNotifications));

      yield put(getPendingClosureTransactionsSuccess(response));
    }
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(getPendingClosureTransactionsFailed(message));
  }
}

function* revertTransaction({ transactionID, payload: data }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/payments/${transactionID}`, options);

    yield put(revertTransactionSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(revertTransactionFailed(message));
  }
}

function* chargeBack({ transactionID, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/payments/${transactionID}/chargeback`, options);

    yield put(chargeBackSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(chargeBackFailed(message));
  }
}

function* getTransactionByID({ payload }) {
  const { transactionID } = payload;

  const options = {
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/payments/${transactionID}`, options);

    yield put(getOneTransactionSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(getOneTransactionFailed(message));
  }
}

function* getReportCommissionsTransactions({ payload }) {
  const { filter, ...params } = payload;

  const options = {
    method: 'GET',
    params
  };

  try {
    const response = yield call(fetchJSON, `/payments/reports/commissions/${filter}`, options);

    yield put(getReportCommissionsTransactionsSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(getReportCommissionsTransactionsFailed(message));
  }
}

function* recalculateTransactionsFees({ payload: data }) {
  const options = {
    method: 'POST',
    data
  };

  try {
    const response = yield call(fetchJSON, `/payments/recalculate`, options);

    yield put(recalculateTransactionsFeesSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(recalculateTransactionsFeesFailed(message));
  }
}

export function* watchGetTransactionsToExport() {
  yield takeEvery(GET_TRANSACTIONS_TO_EXPORT, getTransactionsToExport);
}

export function* watchGetTransactions() {
  yield takeEvery(GET_ALL_TRANSACTIONS, getTransactionsByDate);
}

export function* watchGetWalletTransactions() {
  yield takeEvery(GET_ALL_WALLET_TRANSACTIONS, getWalletTransactions);
}

export function* watchRevertTransaction() {
  yield takeEvery(REVERT_TRANSACTION, revertTransaction);
}
export function* watchChargeBackTransaction() {
  yield takeEvery(CHARGEBACK, chargeBack);
}

export function* watchGetOneTransaction() {
  yield takeEvery(GET_ONE_TRANSACTION, getTransactionByID);
}

export function* watchGetReportCommissionsTransactions() {
  yield takeEvery(REPORT_COMMISSIONS_TRANSACTIONS, getReportCommissionsTransactions);
}

export function* watchGetPendingClosureTransactions() {
  yield takeEvery(GET_PENDING_CLOSURE_TRANSACTIONS, getPendingClosure);
}

export function* watchRecalculateTransactionsFees() {
  yield takeEvery(RECALCULATE_TRANSACTIONS_FEES, recalculateTransactionsFees);
}

function* transactionSaga() {
  yield all([
    fork(watchGetTransactions),
    fork(watchGetWalletTransactions),
    fork(watchRevertTransaction),
    fork(watchChargeBackTransaction),
    fork(watchGetOneTransaction),
    fork(watchGetReportCommissionsTransactions),
    fork(watchGetTransactionsToExport),
    fork(watchGetPendingClosureTransactions),
    fork(watchRecalculateTransactionsFees)
  ]);
}

export default transactionSaga;
