import fetchJSON from '../../utils/fetchJSON';
import { createMessageNotify } from '../../utils/notify';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { createNotification, NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify';

import { CREATE_BANK, READ_BANKS, UPDATE_BANK, DELETE_BANK } from '../../constants/actionTypes';

import {
  setCountBanks,
  createBankFailed,
  createBankSuccess,
  readBanksFailed,
  readBanksSuccess,
  updateBankFailed,
  updateBankSuccess,
  deleteBankFailed,
  deleteBankSuccess
} from './actions';

function* createBank({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/banks', options);

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Banco creado correctamente')));
    yield put(createBankSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(createBankFailed(message));
  }
}

function* readBanks({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/banks', options);
    const { rows = 0, data } = response;
    yield put(readBanksSuccess(data || response));
    yield put(setCountBanks(rows));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Banks not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(readBanksFailed(message));
  }
}

function* updateBank({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/banks/${_id}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Banco actualizado correctamente')));
    yield put(updateBankSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(updateBankFailed(message));
  }
}

function* deleteBank({ _id, payload: data }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/banks/${_id}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Banco actualizado correctamente')));
    yield put(deleteBankSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(deleteBankFailed(message));
  }
}

export function* watchCreateBank() {
  yield takeEvery(CREATE_BANK, createBank);
}

export function* watchReadBanks() {
  yield takeEvery(READ_BANKS, readBanks);
}

export function* watchUpdateBank() {
  yield takeEvery(UPDATE_BANK, updateBank);
}

export function* watchDeleteBank() {
  yield takeEvery(DELETE_BANK, deleteBank);
}

function* BanksSaga() {
  yield all([fork(watchCreateBank), fork(watchReadBanks), fork(watchUpdateBank), fork(watchDeleteBank)]);
}

export default BanksSaga;
