import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS, createNotification } from 'react-redux-notify';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import fetchJSON from '../../utils/fetchJSON';
import { createMessageNotify } from '../../utils/notify';

import { CREATE_VENUES, DELETE_VENUES, READ_VENUES, UPDATE_STATUS_VENUES, UPDATE_VENUES, UPLOAD_LOGO_VENUES } from '../../constants/actionTypes';

import {
  createVenuesFailed,
  createVenuesSuccess,
  deleteVenuesFailed,
  deleteVenuesSuccess,

  // modalVENUESOpen,
  modalVenuesClose,
  readVenues as readVenuesAction,
  readVenuesFailed,
  readVenuesSuccess,
  setCountVenues,
  updateStatusVenuesFailed,
  updateStatusVenuesSuccess,
  updateVenuesFailed,
  updateVenuesSuccess,
  uploadLogoVenues as uploadLogoVenuesAction,
  uploadLogoVenuesFailed,
  uploadLogoVenuesSuccess
} from './actions';

function* createVenue({ payload: data }) {
  const { dataToSend, paramsGetVenues, logo } = data;
  const options = {
    data: dataToSend,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/venues', options);
    
    if (logo.exists) {
      yield put(uploadLogoVenuesAction(response._id, { paramsToSend: logo.form, paramsGetVenues }));
    } else {
      yield put(readVenuesAction(paramsGetVenues));
    }
    yield put(createVenuesSuccess(response));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Sucursal creada correctamente')));
    yield put(modalVenuesClose());
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }
    yield put(createVenuesFailed(message));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
  }
}
function* readVenues({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/venues', options);
    const { rows = 0, data = [] } = response;

    yield put(readVenuesSuccess(data));
    yield put(setCountVenues(rows));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(readVenuesFailed(message));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
  }
}

function* updateVenue({ _id, payload: data }) {
  const { dataToSend, paramsGetVenues, logo } = data;

  const options = {
    data: dataToSend,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/venues/${_id}`, options);
    if (logo.exists) {
      yield put(uploadLogoVenuesAction(_id, { paramsToSend: logo.form, paramsGetVenues }));
    } else {
      yield put(readVenuesAction(paramsGetVenues));
    }
    yield put(updateVenuesSuccess(response));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Sucursal actualizada correctamente')));
    yield put(modalVenuesClose());
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(updateVenuesFailed(message));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
  }
}

function* updateStatusVenue({ _id, payload: data }) {
  const { paramsGetVenues } = data;

  const options = {
    data: {},
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/venues/${_id}/state`, options);
    yield put(updateStatusVenuesSuccess(response));
    yield put(readVenuesAction(paramsGetVenues));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Estado sucursal actualizado correctamente')));
    yield put(modalVenuesClose());
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(updateStatusVenuesFailed(message));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
  }
}

function* uploadLogoVenue({ _id, payload: data }) {
  const { paramsToSend, paramsGetVenues } = data;

  const options = {
    data: paramsToSend,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/venues/${_id}/logo`, options);

    yield put(uploadLogoVenuesSuccess(response));
    yield put(readVenuesAction(paramsGetVenues));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'logo sucursal actualizado correctamente')));
    yield put(modalVenuesClose());
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(uploadLogoVenuesFailed(message));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
  }
}

function* deleteVenue({ _id, payload: data }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/venues/${_id}`, options);
    yield put(deleteVenuesSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(deleteVenuesFailed(message));
  }
}

export function* watchCreateVenue() {
  yield takeEvery(CREATE_VENUES, createVenue);
}
export function* watchReadVenues() {
  yield takeEvery(READ_VENUES, readVenues);
}
export function* watchUpdateVenue() {
  yield takeEvery(UPDATE_VENUES, updateVenue);
}
export function* watchUpdateStatusVenue() {
  yield takeEvery(UPDATE_STATUS_VENUES, updateStatusVenue);
}
export function* watchUploadLogoVenue() {
  yield takeEvery(UPLOAD_LOGO_VENUES, uploadLogoVenue);
}
export function* watchDeleteVenue() {
  yield takeEvery(DELETE_VENUES, deleteVenue);
}

function* VenuesSaga() {
  yield all([
    fork(watchCreateVenue),
    fork(watchReadVenues),
    fork(watchUpdateVenue),
    fork(watchUpdateStatusVenue),
    fork(watchUploadLogoVenue),
    fork(watchDeleteVenue)
  ]);
}

export default VenuesSaga;
