export const SET_COUNT_WALLETS = 'SET_COUNT_WALLETS';

export const RESET_CREATED_WALLET = 'RESET_CREATED_WALLET';

export const CREATE_WALLET = 'CREATE_WALLET';
export const CREATE_WALLET_FAILED = 'CREATE_WALLET_FAILED';
export const CREATE_WALLET_SUCCESS = 'CREATE_WALLET_SUCCESS';

export const READ_WALLETS = 'READ_WALLETS';
export const READ_WALLETS_FAILED = 'READ_WALLETS_FAILED';
export const READ_WALLETS_SUCCESS = 'READ_WALLETS_SUCCESS';

export const UPDATE_WALLET = 'UPDATE_WALLET';
export const UPDATE_WALLET_FAILED = 'UPDATE_WALLET_FAILED';
export const UPDATE_WALLET_SUCCESS = 'UPDATE_WALLET_SUCCESS';

export const DELETE_WALLET = 'DELETE_WALLET';
export const DELETE_WALLET_FAILED = 'DELETE_WALLET_FAILED';
export const DELETE_WALLET_SUCCESS = 'DELETE_WALLET_SUCCESS';
