import {
  SET_COUNT_WALLETS,
  RESET_CREATED_WALLET,
  CREATE_WALLET,
  CREATE_WALLET_FAILED,
  CREATE_WALLET_SUCCESS,
  READ_WALLETS,
  READ_WALLETS_FAILED,
  READ_WALLETS_SUCCESS,
  UPDATE_WALLET,
  UPDATE_WALLET_FAILED,
  UPDATE_WALLET_SUCCESS,
  DELETE_WALLET,
  DELETE_WALLET_FAILED,
  DELETE_WALLET_SUCCESS
} from '../../constants/actionTypes';

export const resetCreatedWallet = () => ({ type: RESET_CREATED_WALLET });
export const setCountWallets = (payload) => ({ payload, type: SET_COUNT_WALLETS });

export const createWallet = (payload) => ({ payload, type: CREATE_WALLET });
export const createWalletFailed = (payload) => ({ payload, type: CREATE_WALLET_FAILED });
export const createWalletSuccess = (payload) => ({ payload, type: CREATE_WALLET_SUCCESS });

export const readWallets = (payload) => ({ payload, type: READ_WALLETS });
export const readWalletsFailed = (payload) => ({ payload, type: READ_WALLETS_FAILED });
export const readWalletsSuccess = (payload) => ({ payload, type: READ_WALLETS_SUCCESS });

export const updateWallet = (_id, payload) => ({ _id, payload, type: UPDATE_WALLET });
export const updateWalletFailed = (payload) => ({ payload, type: UPDATE_WALLET_FAILED });
export const updateWalletSuccess = (payload) => ({ payload, type: UPDATE_WALLET_SUCCESS });

export const toggleStateWallet = (_id) => ({ _id, type: DELETE_WALLET });
export const toggleStateWalletFailed = (payload) => ({ payload, type: DELETE_WALLET_FAILED });
export const toggleStateWalletSuccess = (payload) => ({ payload, type: DELETE_WALLET_SUCCESS });
