/** VENUES **/

export const SET_COUNT_VENUES = 'SET_COUNT_VENUES';
export const SET_ORDER_VENUES = 'SET_ORDER_VENUES';

export const CREATE_VENUES = 'CREATE_VENUES';
export const CREATE_VENUES_FAILED = 'CREATE_VENUES_FAILED';
export const CREATE_VENUES_SUCCESS = 'CREATE_VENUES_SUCCESS';

export const READ_VENUES = 'READ_VENUES';
export const READ_VENUES_FAILED = 'READ_VENUES_FAILED';
export const READ_VENUES_SUCCESS = 'READ_VENUES_SUCCESS';

export const UPDATE_VENUES = 'UPDATE_VENUES';
export const UPDATE_VENUES_FAILED = 'UPDATE_VENUES_FAILED';
export const UPDATE_VENUES_SUCCESS = 'UPDATE_VENUES_SUCCESS';

export const UPLOAD_LOGO_VENUES = 'UPLOAD_LOGO_VENUES';
export const UPLOAD_LOGO_VENUES_FAILED = 'UPLOAD_LOGO_VENUES_FAILED';
export const UPLOAD_LOGO_VENUES_SUCCESS = 'UPLOAD_LOGO_VENUES_SUCCESS';

export const UPDATE_STATUS_VENUES = 'UPDATE_STATUS_VENUES';
export const UPDATE_STATUS_VENUES_FAILED = 'UPDATE_STATUS_VENUES_FAILED';
export const UPDATE_STATUS_VENUES_SUCCESS = 'UPDATE_STATUS_VENUES_SUCCES';

export const DELETE_VENUES = 'DELETE_VENUES';
export const DELETE_VENUES_FAILED = 'DELETE_VENUES_FAILED';
export const DELETE_VENUES_SUCCESS = 'DELETE_VENUES_SUCCESS';

//FOR MODAL
export const MODAL_NEW_VENUES_OPEN = 'MODAL_NEW_VENUES_OPEN';
export const MODAL_UPDATE_VENUES_OPEN = 'MODAL_UPDATE_VENUES_OPEN';
export const MODAL_VENUES_CLOSE = 'MODAL_VENUES_CLOSE';
