import {
  COUNT_CUSTOMERS,
  COUNT_CUSTOMERS_FAILED,
  COUNT_CUSTOMERS_SUCCESS,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_FAILED,
  CREATE_CUSTOMER_SUCCESS,
  READ_CUSTOMERS,
  READ_CUSTOMERS_FAILED,
  READ_CUSTOMERS_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_FAILED,
  UPDATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_FAILED,
  DELETE_CUSTOMER_SUCCESS
} from '../../constants/actionTypes';

export const countCustomers = (payload) => ({ payload, type: COUNT_CUSTOMERS });
export const countCustomersFailed = (payload) => ({ payload, type: COUNT_CUSTOMERS_FAILED });
export const countCustomersSuccess = (payload) => ({ payload, type: COUNT_CUSTOMERS_SUCCESS });

export const createCustomer = (payload) => ({ payload, type: CREATE_CUSTOMER });
export const createCustomerFailed = (payload) => ({ payload, type: CREATE_CUSTOMER_FAILED });
export const createCustomerSuccess = (payload) => ({ payload, type: CREATE_CUSTOMER_SUCCESS });

export const readCustomers = (payload) => ({ payload, type: READ_CUSTOMERS });
export const readCustomersFailed = (payload) => ({ payload, type: READ_CUSTOMERS_FAILED });
export const readCustomersSuccess = (payload) => ({ payload, type: READ_CUSTOMERS_SUCCESS });

export const updateCustomer = (_id, payload) => ({ _id, payload, type: UPDATE_CUSTOMER });
export const updateCustomerFailed = (payload) => ({ payload, type: UPDATE_CUSTOMER_FAILED });
export const updateCustomerSuccess = (payload) => ({ payload, type: UPDATE_CUSTOMER_SUCCESS });

export const deleteCustomer = (_id) => ({ _id, type: DELETE_CUSTOMER });
export const deleteCustomerFailed = (payload) => ({ payload, type: DELETE_CUSTOMER_FAILED });
export const deleteCustomerSuccess = (payload) => ({ payload, type: DELETE_CUSTOMER_SUCCESS });
