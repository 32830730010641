import fetchJSON from '../../utils/fetchJSON';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';

import { CREATE_CURRENCY, READ_CURRENCIES, UPDATE_CURRENCY, DELETE_CURRENCY } from '../../constants/actionTypes';

import {
  setCountCurrencies,
  createCurrencyFailed,
  createCurrencySuccess,
  readCurrenciesFailed,
  readCurrenciesSuccess,
  updateCurrencyFailed,
  updateCurrencySuccess,
  deleteCurrencyFailed,
  deleteCurrencySuccess
} from './actions';
import { createNotification, NOTIFICATION_TYPE_ERROR } from 'react-redux-notify';
import { createMessageNotify } from '../../utils/notify';

function* createCurrency({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/currencies', options);
    yield put(createCurrencySuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(createCurrencyFailed(message));
  }
}

function* readCurrencies({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/currencies', options);
    const { rows = 0, data } = response;
    yield put(readCurrenciesSuccess(data || response));
    yield put(setCountCurrencies(rows));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(readCurrenciesFailed(message));
  }
}

function* updateCurrency({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/currencies/${_id}`, options);
    const { rows = 0, data } = response;

    yield put(updateCurrencySuccess({ rows, currencies: data || response }));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(updateCurrencyFailed(message));
  }
}

function* deleteCurrency({ _id }) {
  const options = {
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/currencies/${_id}`, options);
    yield put(deleteCurrencySuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(deleteCurrencyFailed(message));
  }
}

export function* watchCreateCurrency() {
  yield takeEvery(CREATE_CURRENCY, createCurrency);
}

export function* watchReadCurrencies() {
  yield takeEvery(READ_CURRENCIES, readCurrencies);
}

export function* watchUpdateCurrency() {
  yield takeEvery(UPDATE_CURRENCY, updateCurrency);
}

export function* watchDeleteCurrency() {
  yield takeEvery(DELETE_CURRENCY, deleteCurrency);
}

function* CurrenciesSaga() {
  yield all([fork(watchCreateCurrency), fork(watchReadCurrencies), fork(watchUpdateCurrency), fork(watchDeleteCurrency)]);
}

export default CurrenciesSaga;
