import fetchJSON from '../../utils/fetchJSON';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';

import { CREATE_PAYMENTMETHOD, READ_PAYMENTMETHODS, UPDATE_PAYMENTMETHOD, DELETE_PAYMENTMETHOD } from '../../constants/actionTypes';

import {
  setCountPaymentMethods,
  createPaymentMethodFailed,
  createPaymentMethodSuccess,
  readPaymentMethodsFailed,
  readPaymentMethodsSuccess,
  updatePaymentMethodFailed,
  updatePaymentMethodSuccess,
  deletePaymentMethodFailed,
  deletePaymentMethodSuccess
} from './actions';
import { createNotification, NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify';
import { createMessageNotify } from '../../utils/notify';

function* createPaymentMethod({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/methods', options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Medio de pago creado correctamente')));
    yield put(createPaymentMethodSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(createPaymentMethodFailed(message));
  }
}

function* readPaymentMethods({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/methods', options);
    const { rows = 0, data } = response;
    yield put(readPaymentMethodsSuccess(data || response));
    yield put(setCountPaymentMethods(rows));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(readPaymentMethodsFailed(message));
  }
}

function* updatePaymentMethod({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/methods/${_id}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Medio de pago actualizado correctamente')));
    yield put(updatePaymentMethodSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(updatePaymentMethodFailed(message));
  }
}

function* deletePaymentMethod({ _id, payload: data }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/methods/${_id}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Medio de pago actualizado correctamente')));
    yield put(deletePaymentMethodSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(deletePaymentMethodFailed(message));
  }
}

export function* watchCreatePaymentMethod() {
  yield takeEvery(CREATE_PAYMENTMETHOD, createPaymentMethod);
}

export function* watchReadPaymentMethods() {
  yield takeEvery(READ_PAYMENTMETHODS, readPaymentMethods);
}

export function* watchUpdatePaymentMethod() {
  yield takeEvery(UPDATE_PAYMENTMETHOD, updatePaymentMethod);
}

export function* watchDeletePaymentMethod() {
  yield takeEvery(DELETE_PAYMENTMETHOD, deletePaymentMethod);
}

function* PaymentMethodsSaga() {
  yield all([fork(watchCreatePaymentMethod), fork(watchReadPaymentMethods), fork(watchUpdatePaymentMethod), fork(watchDeletePaymentMethod)]);
}

export default PaymentMethodsSaga;
