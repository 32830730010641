import axios from 'axios';
import { getLoggedInUser, isUserAuthenticated } from '../helpers/authUtils';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000
});

api.interceptors.request.use(
  (config) => {
    const isAuthTokenValid = isUserAuthenticated();

    if (isAuthTokenValid) {
      const user = getLoggedInUser();
      const authToken = user?.token;

      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) {
      console.error('Error de respuesta del servidor:', error.response.status, error.response.data);
    } else if (error.request) {
      console.error('No se recibió respuesta del servidor:', error.request);
    } else {
      console.error('Error al realizar la solicitud:', error.message);
    }

    return Promise.reject(error);
  }
);

export default api;
