/** LIQUIDACIONES **/
export const SET_COUNT_PAID_LIQUIDATIONS = 'SET_COUNT_PAID_LIQUIDATIONS';
export const SET_COUNT_PENDING_LIQUIDATIONS = 'SET_COUNT_PENDING_LIQUIDATIONS';

export const CREATE_LIQUIDATIONS = 'CREATE_LIQUIDATIONS';
export const CREATE_LIQUIDATIONS_FAILED = 'CREATE_LIQUIDATIONS_FAILED';
export const CREATE_LIQUIDATIONS_SUCCESS = 'CREATE_LIQUIDATIONS_SUCCESS';

export const GET_ALL_LIQUIDATIONS = 'GET_ALL_LIQUIDATIONS';
export const GET_ALL_LIQUIDATIONS_FAILED = 'GET_ALL_LIQUIDATIONS_FAILED';
export const GET_ALL_LIQUIDATIONS_SUCCESS = 'GET_ALL_LIQUIDATIONS_SUCCESS';

export const GET_ALL_PAID_LIQUIDATIONS = 'GET_ALL_PAID_LIQUIDATIONS';
export const GET_ALL_PAID_LIQUIDATIONS_FAILED = 'GET_ALL_PAID_LIQUIDATIONS_FAILED';
export const GET_ALL_PAID_LIQUIDATIONS_SUCCESS = 'GET_ALL_PAID_LIQUIDATIONS_SUCCESS';

export const READ_LIQUIDATION = 'READ_LIQUIDATION';
export const READ_LIQUIDATION_FAILED = 'READ_LIQUIDATION_FAILED';
export const READ_LIQUIDATION_SUCCESS = 'READ_LIQUIDATION_SUCCESS';

export const UPDATE_LIQUIDATION = 'UPDATE_LIQUIDATION';
export const UPDATE_LIQUIDATION_FAILED = 'UPDATE_LIQUIDATION_FAILED';
export const UPDATE_LIQUIDATION_SUCCESS = 'UPDATE_LIQUIDATION_SUCCESS';

export const PAY_LIQUIDATIONS = 'PAY_LIQUIDATIONS';
export const PAY_LIQUIDATIONS_FAILED = 'PAY_LIQUIDATIONS_FAILED';
export const PAY_LIQUIDATIONS_SUCCESS = 'PAY_LIQUIDATIONS_SUCCESS';

export const CALCULATE_LIQUIDATIONS = 'CALCULATE_LIQUIDATIONS';
export const CALCULATE_LIQUIDATIONS_FAILED = 'CALCULATE_LIQUIDATIONS_FAILED';
export const CALCULATE_LIQUIDATIONS_SUCCESS = 'CALCULATE_LIQUIDATIONS_SUCCESS';

export const EXPORT_LIQUIDATIONS = 'EXPORT_LIQUIDATIONS';
export const EXPORT_LIQUIDATIONS_FAILED = 'EXPORT_LIQUIDATIONS_FAILED';
export const EXPORT_LIQUIDATIONS_SUCCESS = 'EXPORT_LIQUIDATIONS_SUCCESS';

export const EXPORT_PAID_LIQUIDATIONS = 'EXPORT_PAID_LIQUIDATIONS';
export const EXPORT_PAID_LIQUIDATIONS_FAILED = 'EXPORT_PAID_LIQUIDATIONS_FAILED';
export const EXPORT_PAID_LIQUIDATIONS_SUCCESS = 'EXPORT_PAID_LIQUIDATIONS_SUCCESS';

export const CANCEL_LIQUIDATION = 'CANCEL_LIQUIDATION';
export const CANCEL_LIQUIDATION_FAILED = 'CANCEL_LIQUIDATION_FAILED';
export const CANCEL_LIQUIDATION_SUCCESS = 'CANCEL_LIQUIDATION_SUCCESS';

export const RECALCULATE_COMMISSIONS = 'RECALCULATE_COMMISSIONS';
export const RECALCULATE_COMMISSIONS_FAILED = 'RECALCULATE_COMMISSIONS_FAILED';
export const RECALCULATE_COMMISSIONS_SUCCESS = 'RECALCULATE_COMMISSIONS_SUCCESS';

export const CHANGE_TO_PENDING_LIQUIDATION = 'CHANGE_TO_PENDING_LIQUIDATION';
export const CHANGE_TO_PENDING_LIQUIDATION_FAILED = 'CHANGE_TO_PENDING_LIQUIDATION_FAILED';
export const CHANGE_TO_PENDING_LIQUIDATION_SUCCESS = 'CHANGE_TO_PENDING_LIQUIDATION_SUCCESS';
