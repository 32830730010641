import fetchJSON from '../../utils/fetchJSON';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';

import { CREATE_CUSTOMER_CARD, READ_CUSTOMER_CARDS, DELETE_CUSTOMER_CARD } from '../../constants/actionTypes';

import {
  createCustomerCardFailed,
  createCustomerCardSuccess,
  readCustomerCardsFailed,
  readCustomerCardsSuccess,
  deleteCustomerCardFailed,
  deleteCustomerCardSuccess
} from './actions';

function* createCustomerCard({ customerID, payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, `/customers/${customerID}/cards`, options);
    yield put(createCustomerCardSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(createCustomerCardFailed(message));
  }
}
function* readCustomerCards({ customerID, payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/customers/${customerID}/cards`, options);
    yield put(readCustomerCardsSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(readCustomerCardsFailed(message));
  }
}
function* deleteCustomerCard({ _id, customerID, payload: data }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/customers/${customerID}/cards/${_id}`, options);
    yield put(deleteCustomerCardSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(deleteCustomerCardFailed(message));
  }
}

export function* watchCreateCustomerCard() {
  yield takeEvery(CREATE_CUSTOMER_CARD, createCustomerCard);
}
export function* watchReadCustomerCards() {
  yield takeEvery(READ_CUSTOMER_CARDS, readCustomerCards);
}
export function* watchDeleteCustomerCard() {
  yield takeEvery(DELETE_CUSTOMER_CARD, deleteCustomerCard);
}

function* CustomerCardsSaga() {
  yield all([fork(watchCreateCustomerCard), fork(watchReadCustomerCards), fork(watchDeleteCustomerCard)]);
}

export default CustomerCardsSaga;
