import {
  SET_COUNT_BLACKLIST,
  READ_BLACKLIST,
  READ_BLACKLIST_FAILED,
  READ_BLACKLIST_SUCCESS,
  UPDATE_BLACKLIST_BY_ID,
  UPDATE_BLACKLIST_BY_ID_FAILED,
  UPDATE_BLACKLIST_BY_ID_SUCCESS
} from '../../constants/actionTypes';

const INIT_COUNT_BLACKLIST = {
  totalBlacklist: 0
};

const INIT_READ_BLACKLIST_STATE = {
  loading: false,
  error: undefined,
  blacklist: undefined,
  unlock: undefined
};

export const CountBlacklist = (state = INIT_COUNT_BLACKLIST, { type, payload }) => {
  switch (type) {
    case SET_COUNT_BLACKLIST:
      return {
        totalBlacklist: payload
      };
    default:
      return state;
  }
};

export const ReadBlacklist = (state = INIT_READ_BLACKLIST_STATE, { type, payload }) => {
  switch (type) {
    case READ_BLACKLIST:
      return {
        loading: true,
        error: undefined,
        blacklist: undefined
      };
    case READ_BLACKLIST_FAILED:
      return {
        loading: false,
        error: payload,
        blacklist: undefined
      };
    case READ_BLACKLIST_SUCCESS:
      return {
        loading: false,
        error: undefined,
        blacklist: payload
      };
    default:
      return state;
  }
};

export const UpdateBlacklistById = (state = INIT_READ_BLACKLIST_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_BLACKLIST_BY_ID:
      return {
        loading: true,
        error: undefined,
        unlock: undefined
      };
    case UPDATE_BLACKLIST_BY_ID_FAILED:
      return {
        loading: false,
        error: payload,
        unlock: undefined
      };
    case UPDATE_BLACKLIST_BY_ID_SUCCESS:
      return {
        loading: false,
        error: undefined,
        unlock: payload
      };
    default:
      return state;
  }
};
