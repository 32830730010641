import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import accountSaga from './accounts/saga';
import cardinalSaga from './cardinal/saga';
import productsSaga from './products/saga';
import customersSaga from './customers/saga';
import virtualPOSSaga from './virtualPOS/saga';
import liquidationsSaga from './liquidations/saga';
import transactionsSaga from './transactions/saga';
import usersAccountSaga from './usersAccount/saga';
import globalConfigsSaga from './globalConfigs/saga';
import subscriptionsSaga from './subscriptions/saga';
import customerCardsSaga from './customerCards/saga';
import reportsSaga from './reports/saga';
import VenuesSaga from './venues/saga';
import categoriesSaga from './categories/saga';
import banksSaga from './banks/saga';
import countries from './countries/saga';
import paymentMethods from './paymentMethods/saga';
import currencies from './currencies/saga';
import channels from './channels/saga';
import issuerBins from './issuerBins/saga';
import securityProvidersSaga from './securityProviders/saga';
import walletsSaga from './wallets/saga';
import blacklistSaga from './blacklist/saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    accountSaga(),
    cardinalSaga(),
    productsSaga(),
    customersSaga(),
    virtualPOSSaga(),
    liquidationsSaga(),
    transactionsSaga(),
    usersAccountSaga(),
    globalConfigsSaga(),
    subscriptionsSaga(),
    customerCardsSaga(),
    reportsSaga(),
    VenuesSaga(),
    categoriesSaga(),
    banksSaga(),
    countries(),
    paymentMethods(),
    currencies(),
    channels(),
    issuerBins(),
    securityProvidersSaga(),
    walletsSaga(),
    blacklistSaga()
  ]);
}
