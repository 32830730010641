export const SET_COUNT_PAYMENTMETHOD = 'SET_COUNT_PAYMENTMETHOD';

export const CREATE_PAYMENTMETHOD = 'CREATE_PAYMENTMETHOD';
export const CREATE_PAYMENTMETHOD_FAILED = 'CREATE_PAYMENTMETHOD_FAILED';
export const CREATE_PAYMENTMETHOD_SUCCESS = 'CREATE_PAYMENTMETHOD_SUCCESS';

export const READ_PAYMENTMETHODS = 'READ_PAYMENTMETHODS';
export const READ_PAYMENTMETHODS_FAILED = 'READ_PAYMENTMETHODS_FAILED';
export const READ_PAYMENTMETHODS_SUCCESS = 'READ_PAYMENTMETHODS_SUCCESS';

export const UPDATE_PAYMENTMETHOD = 'UPDATE_PAYMENTMETHOD';
export const UPDATE_PAYMENTMETHOD_FAILED = 'UPDATE_PAYMENTMETHOD_FAILED';
export const UPDATE_PAYMENTMETHOD_SUCCESS = 'UPDATE_PAYMENTMETHOD_SUCCESS';

export const DELETE_PAYMENTMETHOD = 'DELETE_PAYMENTMETHOD';
export const DELETE_PAYMENTMETHOD_FAILED = 'DELETE_PAYMENTMETHOD_FAILED';
export const DELETE_PAYMENTMETHOD_SUCCESS = 'DELETE_PAYMENTMETHOD_SUCCESS';
