import {
  COUNT_CUSTOMERS,
  COUNT_CUSTOMERS_FAILED,
  COUNT_CUSTOMERS_SUCCESS,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_FAILED,
  CREATE_CUSTOMER_SUCCESS,
  READ_CUSTOMERS,
  READ_CUSTOMERS_FAILED,
  READ_CUSTOMERS_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_FAILED,
  UPDATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_FAILED,
  DELETE_CUSTOMER_SUCCESS
} from '../../constants/actionTypes';

const INIT_COUNT_CUSTOMERS_STATE = {
  loading: false,
  error: undefined,
  customer: undefined
};
const INIT_CREATE_CUSTOMER_STATE = {
  loading: false,
  error: undefined,
  customer: undefined
};
const INIT_READ_CUSTOMERS_STATE = {
  loading: false,
  error: undefined,
  customers: undefined
};

export const CountCustomers = (state = INIT_COUNT_CUSTOMERS_STATE, { type, payload }) => {
  switch (type) {
    case COUNT_CUSTOMERS:
      return {
        loading: true,
        error: undefined,
        customer: undefined
      };
    case COUNT_CUSTOMERS_FAILED:
      return {
        loading: false,
        error: payload,
        customer: undefined
      };
    case COUNT_CUSTOMERS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        customer: payload
      };
    default:
      return { ...state };
  }
};
export const CreateCustomer = (state = INIT_CREATE_CUSTOMER_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_CUSTOMER:
      return {
        loading: true,
        error: undefined,
        customer: undefined
      };
    case CREATE_CUSTOMER_FAILED:
      return {
        loading: false,
        error: payload,
        customer: undefined
      };
    case CREATE_CUSTOMER_SUCCESS:
      return {
        loading: false,
        error: undefined,
        customer: payload
      };
    default:
      return { ...state };
  }
};
export const ReadCustomers = (state = INIT_READ_CUSTOMERS_STATE, { type, payload }) => {
  switch (type) {
    case READ_CUSTOMERS:
      return {
        loading: true,
        error: undefined,
        customers: undefined
      };
    case READ_CUSTOMERS_FAILED:
      return {
        loading: false,
        error: payload,
        customers: undefined
      };
    case READ_CUSTOMERS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        customers: payload
      };
    default:
      return { ...state };
  }
};
export const UpdateCustomer = (state = INIT_CREATE_CUSTOMER_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_CUSTOMER:
      return {
        loading: true,
        error: undefined,
        customer: undefined
      };
    case UPDATE_CUSTOMER_FAILED:
      return {
        loading: false,
        error: payload,
        customer: undefined
      };
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        loading: false,
        error: undefined,
        customer: payload
      };
    default:
      return { ...state };
  }
};
export const DeleteCustomer = (state = INIT_CREATE_CUSTOMER_STATE, { type, payload }) => {
  switch (type) {
    case DELETE_CUSTOMER:
      return {
        loading: true,
        error: undefined,
        customer: undefined
      };
    case DELETE_CUSTOMER_FAILED:
      return {
        loading: false,
        error: payload,
        customer: undefined
      };
    case DELETE_CUSTOMER_SUCCESS:
      return {
        loading: false,
        error: undefined,
        customer: payload
      };
    default:
      return { ...state };
  }
};
