import {
  LOGOUT_USER,
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  REGISTER_USER_ACCOUNT,
  REGISTER_USER_ACCOUNT_FAILED,
  REGISTER_USER_ACCOUNT_SUCCESS,
  RECOVERY_USER_PASSWORD,
  RECOVERY_USER_PASSWORD_FAILED,
  RECOVERY_USER_PASSWORD_SUCCESS,
  RECOVERY_USER_PASSWORD_DECRYPT,
  RECOVERY_USER_PASSWORD_DECRYPT_FAILED,
  RECOVERY_USER_PASSWORD_DECRYPT_SUCCESS,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_FAILED,
  UPDATE_USER_PASSWORD_SUCCESS
} from '../../constants/actionTypes';

const INIT_STATE = {
  loading: false,
  data: undefined,
  user: undefined,
  error: undefined
};

export const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        user: undefined,
        error: undefined,
        userLoggedIn: false
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        userLoggedIn: true,
        user: action.payload
      };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        loading: false,
        user: undefined,
        userLoggedIn: false,
        error: action.payload
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: undefined
      };
    case REGISTER_USER_ACCOUNT:
      return {
        ...state,
        loading: true,
        user: undefined,
        error: undefined
      };
    case REGISTER_USER_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
        user: undefined,
        error: action.payload
      };
    case REGISTER_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        user: action.payload
      };
    default:
      return { ...state };
  }
};

export const RecoveryUserPassword = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case RECOVERY_USER_PASSWORD:
      return {
        ...state,
        loading: true,
        user: undefined,
        error: undefined
      };
    case RECOVERY_USER_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        user: undefined,
        error: payload
      };
    case RECOVERY_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
        error: undefined
      };
    default:
      return { ...state };
  }
};
export const RecoveryUserPasswordDecrypt = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case RECOVERY_USER_PASSWORD_DECRYPT:
      return {
        ...state,
        loading: true,
        data: undefined,
        error: undefined
      };
    case RECOVERY_USER_PASSWORD_DECRYPT_FAILED:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: payload
      };
    case RECOVERY_USER_PASSWORD_DECRYPT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
        error: undefined
      };
    default:
      return { ...state };
  }
};
export const UpdateUserPassword = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_USER_PASSWORD:
      return {
        ...state,
        loading: true,
        user: undefined,
        error: undefined
      };
    case UPDATE_USER_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        user: undefined,
        error: payload
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
        error: undefined
      };
    default:
      return { ...state };
  }
};
