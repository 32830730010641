import {
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_FAILED,
  CREATE_SUBSCRIPTION_SUCCESS,
  READ_SUBSCRIPTION_ID,
  READ_SUBSCRIPTION_ID_FAILED,
  READ_SUBSCRIPTION_ID_SUCCESS,
  READ_SUBSCRIPTION_ID_CALENDAR,
  READ_SUBSCRIPTION_ID_CALENDAR_FAILED,
  READ_SUBSCRIPTION_ID_CALENDAR_SUCCESS,
  READ_SUBSCRIPTION_ID_CALENDAR_DETAILS,
  READ_SUBSCRIPTION_ID_CALENDAR_DETAILS_FAILED,
  READ_SUBSCRIPTION_ID_CALENDAR_DETAILS_SUCCESS,
  READ_ALL_SUBSCRIPTIONS,
  READ_ALL_SUBSCRIPTIONS_FAILED,
  READ_ALL_SUBSCRIPTIONS_SUCCESS,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_FAILED,
  UPDATE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_FAILED,
  DELETE_SUBSCRIPTION_SUCCESS
} from '../../constants/actionTypes';

const INIT_STATE_CREATE_SUBSCRIPTION = {
  loading: false,
  error: undefined,
  subscription: undefined
};
const INIT_STATE_DELETE_SUBSCRIPTION = {
  loading: false,
  error: undefined,
  subscription: undefined
};
const INIT_STATE_UPDATE_SUBSCRIPTION = {
  loading: false,
  error: undefined,
  subscription: undefined
};
const INIT_STATE_READ_ALL_SUBSCRIPTIONS = {
  loading: false,
  error: undefined,
  subscriptions: undefined
};
const INIT_STATE_READ_SUBSCRIPTION_ID = {
  loading: false,
  error: undefined,
  subscription: undefined
};
const INIT_STATE_READ_SUBSCRIPTION_ID_CALENDAR = {
  loading: false,
  error: undefined,
  calendar: undefined
};
const INIT_STATE_READ_SUBSCRIPTION_ID_CALENDAR_DETAILS = {
  loading: false,
  error: undefined,
  charge: undefined
};

export const CreateSubscription = (state = INIT_STATE_CREATE_SUBSCRIPTION, { type, payload }) => {
  switch (type) {
    case CREATE_SUBSCRIPTION:
      return {
        loading: true,
        error: undefined,
        subscription: undefined
      };
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        loading: false,
        error: undefined,
        subscription: payload
      };
    case CREATE_SUBSCRIPTION_FAILED:
      return {
        loading: false,
        error: payload,
        subscription: undefined
      };
    default:
      return { ...state };
  }
};
export const ReadSubscriptionID = (state = INIT_STATE_READ_SUBSCRIPTION_ID, { type, payload }) => {
  switch (type) {
    case READ_SUBSCRIPTION_ID:
      return {
        loading: true,
        error: undefined,
        subscription: undefined
      };
    case READ_SUBSCRIPTION_ID_SUCCESS:
      return {
        loading: false,
        error: undefined,
        subscription: payload
      };
    case READ_SUBSCRIPTION_ID_FAILED:
      return {
        loading: false,
        error: payload,
        subscription: undefined
      };
    default:
      return { ...state };
  }
};
export const ReadSubscriptionIDCalendar = (state = INIT_STATE_READ_SUBSCRIPTION_ID_CALENDAR, { type, payload }) => {
  switch (type) {
    case READ_SUBSCRIPTION_ID_CALENDAR:
      return {
        loading: true,
        error: undefined,
        calendar: undefined
      };
    case READ_SUBSCRIPTION_ID_CALENDAR_SUCCESS:
      return {
        loading: false,
        error: undefined,
        calendar: payload
      };
    case READ_SUBSCRIPTION_ID_CALENDAR_FAILED:
      return {
        loading: false,
        error: payload,
        calendar: undefined
      };
    default:
      return { ...state };
  }
};
export const ReadSubscriptionIDCalendarDetails = (state = INIT_STATE_READ_SUBSCRIPTION_ID_CALENDAR_DETAILS, { type, payload }) => {
  switch (type) {
    case READ_SUBSCRIPTION_ID_CALENDAR_DETAILS:
      return {
        loading: true,
        error: undefined,
        charge: undefined
      };
    case READ_SUBSCRIPTION_ID_CALENDAR_DETAILS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        charge: payload
      };
    case READ_SUBSCRIPTION_ID_CALENDAR_DETAILS_FAILED:
      return {
        loading: false,
        error: payload,
        charge: undefined
      };
    default:
      return { ...state };
  }
};
export const ReadAllSubscriptions = (state = INIT_STATE_READ_ALL_SUBSCRIPTIONS, { type, payload }) => {
  switch (type) {
    case READ_ALL_SUBSCRIPTIONS:
      return {
        loading: true,
        error: undefined,
        subscriptions: undefined
      };
    case READ_ALL_SUBSCRIPTIONS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        subscriptions: payload
      };
    case READ_ALL_SUBSCRIPTIONS_FAILED:
      return {
        loading: false,
        error: payload,
        subscriptions: undefined
      };
    default:
      return { ...state };
  }
};
export const UpdateSubscription = (state = INIT_STATE_UPDATE_SUBSCRIPTION, { type, payload }) => {
  switch (type) {
    case UPDATE_SUBSCRIPTION:
      return {
        loading: true,
        error: undefined,
        subscription: undefined
      };
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        loading: false,
        error: undefined,
        subscription: payload
      };
    case UPDATE_SUBSCRIPTION_FAILED:
      return {
        loading: false,
        error: payload,
        subscription: undefined
      };
    default:
      return { ...state };
  }
};
export const DeleteSubscription = (state = INIT_STATE_DELETE_SUBSCRIPTION, { type, payload }) => {
  switch (type) {
    case DELETE_SUBSCRIPTION:
      return {
        loading: true,
        error: undefined,
        subscription: undefined
      };
    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        loading: false,
        error: undefined,
        subscription: payload
      };
    case DELETE_SUBSCRIPTION_FAILED:
      return {
        loading: false,
        error: payload,
        subscription: undefined
      };
    default:
      return { ...state };
  }
};
