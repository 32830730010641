import jwtDecode from 'jwt-decode';
import { getCookie } from './browserCookieManager';

/**
 * Checks if user is authenticated
 */
export const isUserAuthenticated = () => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }
  const decoded = jwtDecode(user.token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn('access token expired');
    return false;
  } else {
    return true;
  }
};

/**
 * Returns the logged in user
 */
export const getLoggedInUser = () => {
  return getCookie('user');
};
