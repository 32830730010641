import {
  SET_COUNT_CHANNELS,
  CREATE_CHANNEL,
  CREATE_CHANNEL_FAILED,
  CREATE_CHANNEL_SUCCESS,
  READ_CHANNELS,
  READ_CHANNELS_FAILED,
  READ_CHANNELS_SUCCESS,
  UPDATE_CHANNEL,
  UPDATE_CHANNEL_FAILED,
  UPDATE_CHANNEL_SUCCESS,
  DELETE_CHANNEL,
  DELETE_CHANNEL_FAILED,
  DELETE_CHANNEL_SUCCESS
} from '../../constants/actionTypes';

const INIT_COUNT_CHANNELS = {
  totalChannels: 0
};

const INIT_CREATE_CHANNEL_STATE = {
  loading: false,
  error: undefined,
  channel: undefined
};
const INIT_READ_CHANNELS_STATE = {
  loading: false,
  error: undefined,
  channels: undefined
};

export const CreateChannel = (state = INIT_CREATE_CHANNEL_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_CHANNEL:
      return {
        loading: true,
        error: undefined,
        channel: undefined
      };
    case CREATE_CHANNEL_FAILED:
      return {
        loading: false,
        error: payload,
        channel: undefined
      };
    case CREATE_CHANNEL_SUCCESS:
      return {
        loading: false,
        error: undefined,
        channel: payload
      };
    default:
      return state;
  }
};

export const CountChannels = (state = INIT_COUNT_CHANNELS, { type, payload }) => {
  switch (type) {
    case SET_COUNT_CHANNELS:
      return {
        totalChannels: payload
      };
    default:
      return state;
  }
};

export const ReadChannels = (state = INIT_READ_CHANNELS_STATE, { type, payload }) => {
  switch (type) {
    case READ_CHANNELS:
      return {
        loading: true,
        error: undefined,
        channels: undefined
      };
    case READ_CHANNELS_FAILED:
      return {
        loading: false,
        error: payload,
        channels: undefined
      };
    case READ_CHANNELS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        channels: payload
      };
    default:
      return state;
  }
};
export const UpdateChannel = (state = INIT_CREATE_CHANNEL_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_CHANNEL:
      return {
        loading: true,
        error: undefined,
        channel: undefined
      };
    case UPDATE_CHANNEL_FAILED:
      return {
        loading: false,
        error: payload,
        channel: undefined
      };
    case UPDATE_CHANNEL_SUCCESS:
      return {
        loading: false,
        error: undefined,
        channel: payload
      };
    default:
      return state;
  }
};
export const DeleteChannel = (state = INIT_CREATE_CHANNEL_STATE, { type, payload }) => {
  switch (type) {
    case DELETE_CHANNEL:
      return {
        loading: true,
        error: undefined,
        channel: undefined
      };
    case DELETE_CHANNEL_FAILED:
      return {
        loading: false,
        error: payload,
        channel: undefined
      };
    case DELETE_CHANNEL_SUCCESS:
      return {
        loading: false,
        error: undefined,
        channel: payload
      };
    default:
      return state;
  }
};
