import fetchJSON from '../../utils/fetchJSON';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';

import { READ_BLACKLIST, UPDATE_BLACKLIST_BY_ID } from '../../constants/actionTypes';

import { setCountBlacklist, readBlacklistFailed, readBlacklistSuccess, updateBlacklistByIdSuccess, updateBlacklistByIdFailed } from './actions';
import { createNotification, NOTIFICATION_TYPE_ERROR } from 'react-redux-notify';
import { createMessageNotify } from '../../utils/notify';

function* readBlacklist({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/blacklist', options);
    const { rows = 0, data } = response;

    yield put(readBlacklistSuccess(data || response));
    yield put(setCountBlacklist(rows));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(readBlacklistFailed(message));
  }
}

function* updateBlacklistById({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/blacklist/${_id}`, options);
    yield put(updateBlacklistByIdSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(updateBlacklistByIdFailed(message));
  }
}

export function* watchReadBlacklist() {
  yield takeEvery(READ_BLACKLIST, readBlacklist);
}

export function* watchUpdateBlacklistById() {
  yield takeEvery(UPDATE_BLACKLIST_BY_ID, updateBlacklistById);
}

function* BlacklistSaga() {
  yield all([fork(watchReadBlacklist), fork(watchUpdateBlacklistById)]);
}

export default BlacklistSaga;
