import * as Auth from './auth/reducers';
import notifyReducer from 'react-redux-notify';
import * as Accounts from './accounts/reducers';
import * as Cardinal from './cardinal/reducers';
import * as Products from './products/reducers';
import * as Customers from './customers/reducers';
import * as Liquidations from './liquidations/reducers';
import * as Transactions from './transactions/reducers';
import * as POSLinkPayment from './virtualPOS/reducers';
import * as UsersAccount from './usersAccount/reducers';
import * as GlobalConfigs from './globalConfigs/reducers';
import * as Subscriptions from './subscriptions/reducers';
import * as CustomerCards from './customerCards/reducers';

import * as Venues from './venues/reducers';
import * as Categories from './categories/reducers';

import * as Reports from './reports/reducers';

import * as Banks from './banks/reducer';
import * as Countries from './countries/reducer';
import * as PaymentMethods from './paymentMethods/reducer';
import * as Currencies from './currencies/reducer';
import * as Channels from './channels/reducer';
import * as IssuerBins from './issuerBins/reducer';
import * as SecurityProviders from './securityProviders/reducer';
import * as Wallets from './wallets/reducer';
import * as Blacklist from './blacklist/reducer';

import { combineReducers } from 'redux';

export default combineReducers({
  ...Auth,
  ...Reports,
  ...Accounts,
  ...Cardinal,
  ...Products,
  ...Customers,
  ...Liquidations,
  ...Transactions,
  ...UsersAccount,
  ...GlobalConfigs,
  ...Subscriptions,
  ...CustomerCards,
  ...POSLinkPayment,
  ...Venues,
  ...Categories,
  ...Banks,
  ...Countries,
  ...PaymentMethods,
  ...Currencies,
  ...Channels,
  ...IssuerBins,
  ...SecurityProviders,
  ...Wallets,
  ...Blacklist,
  notifications: notifyReducer
});
