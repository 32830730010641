/** TRANSACTIONS / PAYMENTS **/

export const SET_COUNT_TRANSACTIONS = 'SET_COUNT_TRANSACTIONS';
export const SET_COUNT_WALLET_TRANSACTIONS = 'SET_COUNT_WALLET_TRANSACTIONS';

export const GET_TRANSACTIONS_TO_EXPORT = 'GET_TRANSACTIONS_TO_EXPORT';
export const GET_TRANSACTIONS_TO_EXPORT_FAILED = 'GET_TRANSACTIONS_TO_EXPORT_FAILED';
export const GET_TRANSACTIONS_TO_EXPORT_SUCCESS = 'GET_TRANSACTIONS_TO_EXPORT_SUCCESS';

export const GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS';
export const GET_ALL_TRANSACTIONS_FAILED = 'GET_ALL_TRANSACTIONS_FAILED';
export const GET_ALL_TRANSACTIONS_SUCCESS = 'GET_ALL_TRANSACTIONS_SUCCESS';

export const GET_ALL_WALLET_TRANSACTIONS = 'GET_ALL_WALLET_TRANSACTIONS';
export const GET_ALL_WALLET_TRANSACTIONS_FAILED = 'GET_ALL_WALLET_TRANSACTIONS_FAILED';
export const GET_ALL_WALLET_TRANSACTIONS_SUCCESS = 'GET_ALL_WALLET_TRANSACTIONS_SUCCESS';

export const GET_ONE_TRANSACTION = 'GET_ONE_TRANSACTION';
export const GET_ONE_TRANSACTION_FAILED = 'GET_ONE_TRANSACTION_FAILED';
export const GET_ONE_TRANSACTION_SUCCESS = 'GET_ONE_TRANSACTION_SUCCESS';

export const REVERT_TRANSACTION = 'REVERT_TRANSACTION';
export const REVERT_TRANSACTION_FAILED = 'REVERT_TRANSACTION_FAILED';
export const REVERT_TRANSACTION_SUCCESS = 'REVERT_TRANSACTION_SUCCESS';

export const CHARGEBACK = 'CHARGEBACK';
export const CHARGEBACK_FAILED = 'CHARGEBACK_FAILED';
export const CHARGEBACK_SUCCESS = 'CHARGEBACK_SUCCESS';

export const REGISTER_POS_TRANSACTION = 'REGISTER_POS_TRANSACTION';
export const REGISTER_POS_TRANSACTION_FAILED = 'REGISTER_POS_TRANSACTION_FAILED';
export const REGISTER_POS_TRANSACTION_SUCCESS = 'REGISTER_POS_TRANSACTION_SUCCESS';

export const REPORT_COMMISSIONS_TRANSACTIONS = 'REPORT_COMMISSIONS_TRANSACTIONS';
export const REPORT_COMMISSIONS_TRANSACTIONS_FAILED = 'REPORT_COMMISSIONS_TRANSACTIONS_FAILED';
export const REPORT_COMMISSIONS_TRANSACTIONS_SUCCESS = 'REPORT_COMMISSIONS_TRANSACTIONS_SUCCESS';

export const GET_PENDING_CLOSURE_TRANSACTIONS = 'GET_PENDING_CLOSURE_TRANSACTIONS';
export const GET_PENDING_CLOSURE_TRANSACTIONS_FAILED = 'GET_PENDING_CLOSURE_TRANSACTIONS_FAILED';
export const GET_PENDING_CLOSURE_TRANSACTIONS_SUCCESS = 'GET_PENDING_CLOSURE_TRANSACTIONS_SUCCESS';

export const RECALCULATE_TRANSACTIONS_FEES = 'RECALCULATE_TRANSACTIONS_FEES';
export const RECALCULATE_TRANSACTIONS_FEES_FAILED = 'RECALCULATE_TRANSACTIONS_FEES_FAILED';
export const RECALCULATE_TRANSACTIONS_FEES_SUCCESS = 'RECALCULATE_TRANSACTIONS_FEES_SUCCESS';
