import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { createNotification, NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_ERROR } from 'react-redux-notify';
import { t } from 'i18next';

import {
  UPDATE_LIQUIDATION,
  PAY_LIQUIDATIONS,
  CREATE_LIQUIDATIONS,
  GET_ALL_LIQUIDATIONS,
  GET_ALL_PAID_LIQUIDATIONS,
  READ_LIQUIDATION,
  CALCULATE_LIQUIDATIONS,
  EXPORT_LIQUIDATIONS,
  CANCEL_LIQUIDATION,
  EXPORT_PAID_LIQUIDATIONS,
  RECALCULATE_COMMISSIONS,
  CHANGE_TO_PENDING_LIQUIDATION
} from '../../constants/actionTypes';

import {
  setCountPaidLiquidations,
  setCountPendingLiquidations,
  updateLiquidationFailed,
  updateLiquidationSuccess,
  payLiquidationsFailed,
  payLiquidationsSuccess,
  createLiquidationsFailed,
  createLiquidationsSuccess,
  getAllLiquidationsFailed,
  getAllLiquidationsSuccess,
  calculateLiquidationsFailed,
  calculateLiquidationsSuccess,
  exportLiquidationsFailed,
  exportLiquidationsSuccess,
  readLiquidationFailed,
  readLiquidationSuccess,
  cancelLiquidationFailed,
  cancelLiquidationSuccess,
  getAllPaidLiquidationsSuccess,
  getAllPaidLiquidationsFailed,
  getExportPaidLiquidationsSuccess,
  getExportPaidLiquidationsFailed,
  recalculateCommissionsFailed,
  recalculateCommissionsSuccess,
  changeToPendingLiquidationFailed,
  changeToPendingLiquidationSuccess
} from './actions';

import { createMessageNotify } from '../../utils/notify';

import fetchJSON from '../../utils/fetchJSON';

function* payLiquidations({ payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/liquidations/pay`, options);
    yield put(payLiquidationsSuccess(response));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, t('liquidations.alerts.update'))));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(payLiquidationsFailed(message));
  }
}

function* updateLiquidation({ liquidationID, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/liquidations/${liquidationID}`, options);
    yield put(updateLiquidationSuccess(response));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, t('liquidations.alerts.update'))));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(updateLiquidationFailed(message));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message)));
  }
}

function* createLiquidation({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, `/liquidations`, options);

    yield put(createLiquidationsSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createLiquidationsFailed(message));
  }
}

function* getAllLiquidation({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/liquidations`, options);
    const { data, rows = 0 } = response;

    yield put(getAllLiquidationsSuccess(data || response));
    yield put(setCountPendingLiquidations(rows));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(getAllLiquidationsFailed(message));
  }
}

function* getAllPaidLiquidation({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/liquidations/paid`, options);
    const { data, rows = 0 } = response;

    yield put(getAllPaidLiquidationsSuccess(data || response));
    yield put(setCountPaidLiquidations(rows));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(getAllPaidLiquidationsFailed(message));
  }
}

function* readLiquidation({ closureID }) {
  const options = {
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/liquidations/${closureID}`, options);

    yield put(readLiquidationSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(readLiquidationFailed(message));
  }
}

function* cancelLiquidation({ liquidationID, payload: data }) {
  const options = {
    method: 'DELETE',
    data
  };

  try {
    const response = yield call(fetchJSON, `/liquidations/${liquidationID}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Cierre actualizado correctamente')));

    yield put(cancelLiquidationSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(cancelLiquidationFailed(message));
  }
}

function* calculateLiquidations({ accountID: account, payload }) {
  const options = {
    data: {
      account,
      ...payload
    },
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, `/liquidations/calculate`, options);

    yield put(calculateLiquidationsSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(calculateLiquidationsFailed(message));
  }
}

function* exportLiquidations({ closureID }) {
  const options = {
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, `/liquidations/${closureID}/export`, options);

    yield put(exportLiquidationsSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(exportLiquidationsFailed(message));
  }
}

function* getExportPaidLiquidations({ payload: params }) {
  const options = {
    method: 'GET',
    params
  };

  try {
    const response = yield call(fetchJSON, '/liquidations/report/paid', options);

    const { data } = response;

    yield put(getExportPaidLiquidationsSuccess(data || response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(getExportPaidLiquidationsFailed(message));
  }
}

function* recalculatePaymentCommissions({ closureID }) {
  const options = {
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/liquidations/${closureID}/recalculate`, options);

    yield put(recalculateCommissionsSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(recalculateCommissionsFailed(message));
  }
}

function* changeToPendingLiqudiation({ payload: data }) {
  const options = {
    method: 'DELETE',
    data
  };

  try {
    const response = yield call(fetchJSON, `/liquidations/paid`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, t('liquidations.alerts.update'))));

    yield put(changeToPendingLiquidationSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 404:
        message = 'Metodo no existe';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(changeToPendingLiquidationFailed(message));
  }
}

export function* watchPayLiquidations() {
  yield takeEvery(PAY_LIQUIDATIONS, payLiquidations);
}

export function* watchUpdateLiquidation() {
  yield takeEvery(UPDATE_LIQUIDATION, updateLiquidation);
}

export function* watchCreateLiquidation() {
  yield takeEvery(CREATE_LIQUIDATIONS, createLiquidation);
}

export function* watchGetAllLiquidation() {
  yield takeEvery(GET_ALL_LIQUIDATIONS, getAllLiquidation);
}

export function* watchGetAllPaidLiquidation() {
  yield takeEvery(GET_ALL_PAID_LIQUIDATIONS, getAllPaidLiquidation);
}

export function* watchReadLiquidation() {
  yield takeEvery(READ_LIQUIDATION, readLiquidation);
}

export function* watchCalculateLiquidations() {
  yield takeEvery(CALCULATE_LIQUIDATIONS, calculateLiquidations);
}

export function* watchExportLiquidations() {
  yield takeEvery(EXPORT_LIQUIDATIONS, exportLiquidations);
}

export function* watchGetExportPaidLiquidations() {
  yield takeEvery(EXPORT_PAID_LIQUIDATIONS, getExportPaidLiquidations);
}

export function* watchCancelLiquidation() {
  yield takeEvery(CANCEL_LIQUIDATION, cancelLiquidation);
}

export function* watchRecalculateCommissions() {
  yield takeEvery(RECALCULATE_COMMISSIONS, recalculatePaymentCommissions);
}

export function* watchChangeToPendingLiquidation() {
  yield takeEvery(CHANGE_TO_PENDING_LIQUIDATION, changeToPendingLiqudiation);
}

export default function* liquidationsSaga() {
  yield all([
    fork(watchUpdateLiquidation),
    fork(watchPayLiquidations),
    fork(watchCreateLiquidation),
    fork(watchGetAllLiquidation),
    fork(watchGetAllPaidLiquidation),
    fork(watchReadLiquidation),
    fork(watchCalculateLiquidations),
    fork(watchExportLiquidations),
    fork(watchGetExportPaidLiquidations),
    fork(watchCancelLiquidation),
    fork(watchRecalculateCommissions),
    fork(watchChangeToPendingLiquidation)
  ]);
}
