export const SET_COUNT_CHANNELS = 'SET_COUNT_CHANNELS';

export const CREATE_CHANNEL = 'CREATE_CHANNEL';
export const CREATE_CHANNEL_FAILED = 'CREATE_CHANNEL_FAILED';
export const CREATE_CHANNEL_SUCCESS = 'CREATE_CHANNEL_SUCCESS';

export const READ_CHANNELS = 'READ_CHANNELS';
export const READ_CHANNELS_FAILED = 'READ_CHANNELS_FAILED';
export const READ_CHANNELS_SUCCESS = 'READ_CHANNELS_SUCCESS';

export const UPDATE_CHANNEL = 'UPDATE_CHANNEL';
export const UPDATE_CHANNEL_FAILED = 'UPDATE_CHANNEL_FAILED';
export const UPDATE_CHANNEL_SUCCESS = 'UPDATE_CHANNEL_SUCCESS';

export const DELETE_CHANNEL = 'DELETE_CHANNEL';
export const DELETE_CHANNEL_FAILED = 'DELETE_CHANNEL_FAILED';
export const DELETE_CHANNEL_SUCCESS = 'DELETE_CHANNEL_SUCCESS';
