import {
  SET_COUNT_BANKS,
  RESET_CREATED_BANK,
  CREATE_BANK,
  CREATE_BANK_FAILED,
  CREATE_BANK_SUCCESS,
  READ_BANKS,
  READ_BANKS_FAILED,
  READ_BANKS_SUCCESS,
  UPDATE_BANK,
  UPDATE_BANK_FAILED,
  UPDATE_BANK_SUCCESS,
  DELETE_BANK,
  DELETE_BANK_FAILED,
  DELETE_BANK_SUCCESS
} from '../../constants/actionTypes';

export const resetCreatedBank = () => ({ type: RESET_CREATED_BANK });
export const setCountBanks = (payload) => ({ payload, type: SET_COUNT_BANKS });

export const createBank = (payload) => ({ payload, type: CREATE_BANK });
export const createBankFailed = (payload) => ({ payload, type: CREATE_BANK_FAILED });
export const createBankSuccess = (payload) => ({ payload, type: CREATE_BANK_SUCCESS });

export const readBanks = (payload) => ({ payload, type: READ_BANKS });
export const readBanksFailed = (payload) => ({ payload, type: READ_BANKS_FAILED });
export const readBanksSuccess = (payload) => ({ payload, type: READ_BANKS_SUCCESS });

export const updateBank = (_id, payload) => ({ _id, payload, type: UPDATE_BANK });
export const updateBankFailed = (payload) => ({ payload, type: UPDATE_BANK_FAILED });
export const updateBankSuccess = (payload) => ({ payload, type: UPDATE_BANK_SUCCESS });

export const deleteBank = (_id) => ({ _id, type: DELETE_BANK });
export const deleteBankFailed = (payload) => ({ payload, type: DELETE_BANK_FAILED });
export const deleteBankSuccess = (payload) => ({ payload, type: DELETE_BANK_SUCCESS });
