import {
  SET_COUNT_SECURITY_PROVIDERS,
  CREATE_SECURITY_PROVIDER,
  CREATE_SECURITY_PROVIDER_FAILED,
  CREATE_SECURITY_PROVIDER_SUCCESS,
  READ_SECURITY_PROVIDERS,
  READ_SECURITY_PROVIDERS_FAILED,
  READ_SECURITY_PROVIDERS_SUCCESS,
  UPDATE_SECURITY_PROVIDER,
  UPDATE_SECURITY_PROVIDER_FAILED,
  UPDATE_SECURITY_PROVIDER_SUCCESS,
  DELETE_SECURITY_PROVIDER,
  DELETE_SECURITY_PROVIDER_FAILED,
  DELETE_SECURITY_PROVIDER_SUCCESS
} from '../../constants/actionTypes';

const INIT_COUNT_SECURITY_PROVIDERS_STATE = {
  totalSecurityProviders: 0
};

const INIT_CREATE_SECURITY_PROVIDER_STATE = {
  loading: false,
  error: undefined,
  securityProvider: undefined
};

const INIT_READ_SECURITY_PROVIDERS_STATE = {
  loading: false,
  error: undefined,
  securityProviders: undefined,
  totalSecurityProviders: 0
};

export const CreateSecurityProvider = (state = INIT_CREATE_SECURITY_PROVIDER_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_SECURITY_PROVIDER:
      return {
        loading: true,
        error: undefined,
        securityProvider: undefined
      };
    case CREATE_SECURITY_PROVIDER_FAILED:
      return {
        loading: false,
        error: payload,
        securityProvider: undefined
      };
    case CREATE_SECURITY_PROVIDER_SUCCESS:
      return {
        loading: false,
        error: undefined,
        securityProvider: payload
      };
    default:
      return state;
  }
};

export const CountSecurityProviders = (state = INIT_COUNT_SECURITY_PROVIDERS_STATE, { type, payload }) => {
  switch (type) {
    case SET_COUNT_SECURITY_PROVIDERS:
      return {
        ...state,
        totalSecurityProviders: payload
      };
    default:
      return state;
  }
};

export const ReadSecurityProviders = (state = INIT_READ_SECURITY_PROVIDERS_STATE, { type, payload }) => {
  switch (type) {
    case READ_SECURITY_PROVIDERS:
      return {
        loading: true,
        error: undefined,
        securityProviders: undefined
      };
    case READ_SECURITY_PROVIDERS_FAILED:
      return {
        loading: false,
        error: payload,
        securityProviders: undefined
      };
    case READ_SECURITY_PROVIDERS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        securityProviders: payload
      };
    default:
      return state;
  }
};

export const UpdateSecurityProvider = (state = INIT_CREATE_SECURITY_PROVIDER_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_SECURITY_PROVIDER:
      return {
        loading: true,
        error: undefined,
        securityProvider: undefined
      };
    case UPDATE_SECURITY_PROVIDER_FAILED:
      return {
        loading: false,
        error: payload,
        securityProvider: undefined
      };
    case UPDATE_SECURITY_PROVIDER_SUCCESS:
      return {
        loading: false,
        error: undefined,
        securityProvider: payload
      };
    default:
      return state;
  }
};

export const DeleteSecurityProvider = (state = INIT_CREATE_SECURITY_PROVIDER_STATE, { type, payload }) => {
  switch (type) {
    case DELETE_SECURITY_PROVIDER:
      return {
        loading: true,
        error: undefined,
        securityProvider: undefined
      };
    case DELETE_SECURITY_PROVIDER_FAILED:
      return {
        loading: false,
        error: payload,
        securityProvider: undefined
      };
    case DELETE_SECURITY_PROVIDER_SUCCESS:
      return {
        loading: false,
        error: undefined,
        securityProvider: payload
      };
    default:
      return state;
  }
};
