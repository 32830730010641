import React from 'react';
import { Navigate } from 'react-router';

import DefaultLayout from '../layouts/DefaultLayout';
import { getLoggedInUser, isUserAuthenticated } from '../helpers/authUtils';
import { RolesTypeEnum, UserRolesEnum } from '../lib/enums/UserAccount';
import { ServiceChannelsEnum } from '../lib/enums/ServiceChannels';
import { UserAccountProvider } from '../lib/context/UserAccount';

interface IPrivateRouteProps {
  component: any;
  pageTitle?: string;
  roles?: UserRolesEnum[];
  channels?: ServiceChannelsEnum[];
  roleTypes?: RolesTypeEnum[];
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ pageTitle, component: Component, roles, roleTypes = [RolesTypeEnum.ADMIN] }) => {
  if (!isUserAuthenticated()) {
    return <Navigate to={'/auth/login'} state={{ from: location.pathname }} />;
  }

  const user = getLoggedInUser();

  if (roles && roles.indexOf(user?.role) === -1 || roleTypes.indexOf(user.type) === -1) {
    return <Navigate to={user.role == UserRolesEnum.TICKET_USER ? '/tickets' : '/'} />;
  }

  return (
    <UserAccountProvider>
      <DefaultLayout pageTitle={pageTitle}>
        <Component />
      </DefaultLayout>
    </UserAccountProvider>
  );
};

export default PrivateRoute;
