import fetchJSON from '../../utils/fetchJSON';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  createNotification,
  NOTIFICATION_TYPE_ERROR
} from 'react-redux-notify';

import {
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_USER_PASSWORD,
  REGISTER_USER_ACCOUNT,
  RECOVERY_USER_PASSWORD,
  RECOVERY_USER_PASSWORD_DECRYPT
} from '../../constants/actionTypes';

import {
  loginUserSuccess,
  loginUserFailed,
  registerUserAccountFailed,
  registerUserAccountSuccess,
  recoveryUserPasswordFailed,
  recoveryUserPasswordSuccess,
  recoveryUserPasswordDecryptFailed,
  recoveryUserPasswordDecryptSuccess,
  updateUserPasswordFailed,
  updateUserPasswordSuccess
} from './actions';
import { createMessageNotify } from '../../utils/notify';
import { removeCookie, setCookie } from '../../helpers/browserCookieManager';

const setSession = (user) => {
  removeCookie('systemNotifications');
  localStorage.removeItem('refreshNotification');
  if (user) {
    if (user.systemNotifications) {
      setCookie(
        'systemNotifications',
        JSON.stringify(user.systemNotifications)
      );
      delete user.systemNotifications;
    }
    setCookie('user', JSON.stringify(user), true);
    localStorage.setItem('lang', user.language);
  } else {
    removeCookie('user');
    localStorage.removeItem('lang');
  }
};

function* login({ payload }) {
  const options = {
    data: payload,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/auth/login', options);
    setSession(response);

    yield put(loginUserSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }

    yield put(loginUserFailed(message));
    setSession(null);
    yield put(
      createNotification(
        createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)
      )
    );
  }
}
function* logout({ payload: { history } }) {
  try {
    setSession(null);
    yield call(() => {
      history('/auth/login');
    });
  } catch (error) {
    //empty
  }
}
function* registerUserAccount({ payload }) {
  const options = {
    data: payload,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/auth/register_account', options);
    yield put(registerUserAccountSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(registerUserAccountFailed(message));
  }
}
function* recoveryUserPassword({ payload }) {
  const options = {
    data: payload,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/auth/recovery-password', options);
    yield put(recoveryUserPasswordSuccess(response));
  } catch ({ message }) {
    yield put(recoveryUserPasswordFailed(message));
  }
}
function* recoveryUserPasswordDecrypt({ payload }) {
  const options = {
    data: payload,
    method: 'POST'
  };

  try {
    const response = yield call(
      fetchJSON,
      '/auth/recovery-password/decrypt',
      options
    );
    yield put(recoveryUserPasswordDecryptSuccess(response));
  } catch ({ message }) {
    yield put(recoveryUserPasswordDecryptFailed(message));
  }
}
function* updateUserPassword({ hash, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(
      fetchJSON,
      `/auth/recovery-password/${hash}`,
      options
    );
    yield put(updateUserPasswordSuccess(response));
  } catch ({ message }) {
    yield put(updateUserPasswordFailed(message));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}
export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}
export function* watchRegisterUserAccount() {
  yield takeEvery(REGISTER_USER_ACCOUNT, registerUserAccount);
}
export function* watchRecoveryUserPassword() {
  yield takeEvery(RECOVERY_USER_PASSWORD, recoveryUserPassword);
}
export function* watchRecoveryUserPasswordDecrypt() {
  yield takeEvery(RECOVERY_USER_PASSWORD_DECRYPT, recoveryUserPasswordDecrypt);
}
export function* watchUpdateUserPassword() {
  yield takeEvery(UPDATE_USER_PASSWORD, updateUserPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUserAccount),
    fork(watchRecoveryUserPassword),
    fork(watchRecoveryUserPasswordDecrypt),
    fork(watchUpdateUserPassword)
  ]);
}

export default authSaga;
