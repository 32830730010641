import {
  READ_LINK_PAYMENT,
  READ_LINK_PAYMENT_FAILED,
  READ_LINK_PAYMENT_SUCCESS,
  SET_COUNT_LINKS_PAYMENT,
  CREATE_LINK_PAYMENT,
  CREATE_LINK_PAYMENT_FAILED,
  CREATE_LINK_PAYMENT_SUCCESS,
  CANCEL_LINK_PAYMENT,
  CANCEL_LINK_PAYMENT_FAILED,
  CANCEL_LINK_PAYMENT_SUCCESS,
  PROCESS_LINK_PAYMENT,
  PROCESS_LINK_PAYMENT_FAILED,
  PROCESS_LINK_PAYMENT_SUCCESS,
  REGISTER_POS_TRANSACTION,
  REGISTER_POS_TRANSACTION_FAILED,
  REGISTER_POS_TRANSACTION_SUCCESS,
  READ_ALL_LINKS_PAYMENT,
  READ_ALL_LINKS_PAYMENT_FAILED,
  READ_ALL_LINKS_PAYMENT_SUCCESS,
  SEND_EMAIL_POS_LINK_PAYMENT,
  SEND_EMAIL_POS_LINK_PAYMENT_FAILED,
  SEND_EMAIL_POS_LINK_PAYMENT_SUCCESS,
  UPDATE_LINK_PAYMENT,
  UPDATE_LINK_PAYMENT_FAILED,
  UPDATE_LINK_PAYMENT_SUCCESS,
  PROCESS_PUBLIC_POS_LINK_PAYMENT,
  PROCESS_PUBLIC_POS_LINK_PAYMENT_FAILED,
  PROCESS_PUBLIC_POS_LINK_PAYMENT_SUCCESS,
  READ_WALLETS_BY_ACCOUNT,
  READ_WALLETS_BY_ACCOUNT_FAILED,
  READ_WALLETS_BY_ACCOUNT_SUCCESS,
  PAYMENT_VERIFICATION_WALLET,
  PAYMENT_VERIFICATION_WALLET_FAILED,
  PAYMENT_VERIFICATION_WALLET_SUCCESS,
  PROCESS_ACCOUNT_SETTINGS,
  PROCESS_ACCOUNT_SETTINGS_FAILED,
  PROCESS_ACCOUNT_SETTINGS_SUCCESS,
  VALIDATE_OTP_TIGO_MONEY,
  VALIDATE_OTP_TIGO_MONEY_FAILED,
  VALIDATE_OTP_TIGO_MONEY_SUCCESS
} from '../../constants/actionTypes';

export const setCountLinksPayment = (payload) => ({ payload, type: SET_COUNT_LINKS_PAYMENT });

export const createLinkPayment = (payload) => ({
  payload,
  type: CREATE_LINK_PAYMENT
});
export const createLinkPaymentFailed = (error) => ({
  payload: error,
  type: CREATE_LINK_PAYMENT_FAILED
});
export const createLinkPaymentSuccess = (payload) => ({
  payload,
  type: CREATE_LINK_PAYMENT_SUCCESS
});

export const readLinkPayment = (payload, _id) => ({
  _id,
  payload,
  type: READ_LINK_PAYMENT
});
export const readLinkPaymentFailed = (payload) => ({
  payload,
  type: READ_LINK_PAYMENT_FAILED
});
export const readLinkPaymentSuccess = (payload) => ({
  payload,
  type: READ_LINK_PAYMENT_SUCCESS
});

export const readWalletsByAccount = (payload, _id) => ({
  _id,
  payload,
  type: READ_WALLETS_BY_ACCOUNT
});
export const readWalletsByAccountFailed = (payload) => ({
  payload,
  type: READ_WALLETS_BY_ACCOUNT_FAILED
});
export const readWalletsByAccountSuccess = (payload) => ({
  payload,
  type: READ_WALLETS_BY_ACCOUNT_SUCCESS
});

export const processAccountSettings = (payload) => ({
  payload,
  type: PROCESS_ACCOUNT_SETTINGS
});
export const processAccountSettingsFailed = (error) => ({
  payload: error,
  type: PROCESS_ACCOUNT_SETTINGS_FAILED
});
export const processAccountSettingsSuccess = (payload) => ({
  payload,
  type: PROCESS_ACCOUNT_SETTINGS_SUCCESS
});

export const paymentVerificationWallet = (payload) => ({
  payload,
  type: PAYMENT_VERIFICATION_WALLET
});
export const paymentVerificationWalletFailed = (error) => ({
  payload: error,
  type: PAYMENT_VERIFICATION_WALLET_FAILED
});
export const paymentVerificationWalletSuccess = (payload) => ({
  payload,
  type: PAYMENT_VERIFICATION_WALLET_SUCCESS
});

export const readAllLinksPayment = (payload) => ({
  payload,
  type: READ_ALL_LINKS_PAYMENT
});
export const readAllLinksPaymentFailed = (error) => ({
  payload: error,
  type: READ_ALL_LINKS_PAYMENT_FAILED
});
export const readAllLinksPaymentSuccess = (payload) => ({
  payload,
  type: READ_ALL_LINKS_PAYMENT_SUCCESS
});

export const updateLinkPayment = (payload, _id) => ({
  _id,
  payload,
  type: UPDATE_LINK_PAYMENT
});
export const updateLinkPaymentFailed = (error) => ({
  payload: error,
  type: UPDATE_LINK_PAYMENT_FAILED
});
export const updateLinkPaymentSuccess = (payload) => ({
  payload,
  type: UPDATE_LINK_PAYMENT_SUCCESS
});

export const cancelLinkPayment = (payload, _id) => ({
  _id,
  payload,
  type: CANCEL_LINK_PAYMENT
});
export const cancelLinkPaymentFailed = (error) => ({
  payload: error,
  type: CANCEL_LINK_PAYMENT_FAILED
});
export const cancelLinkPaymentSuccess = (payload) => ({
  payload,
  type: CANCEL_LINK_PAYMENT_SUCCESS
});

export const processLinkPayment = (payload, _id) => ({
  _id,
  payload,
  type: PROCESS_LINK_PAYMENT
});
export const processLinkPaymentFailed = (error) => ({
  payload: error,
  type: PROCESS_LINK_PAYMENT_FAILED
});
export const processLinkPaymentSuccess = (payload) => ({
  payload,
  type: PROCESS_LINK_PAYMENT_SUCCESS
});

export const sendEmailPOSLinkPayment = (posLinkID, payload) => ({
  payload,
  posLinkID,
  type: SEND_EMAIL_POS_LINK_PAYMENT
});
export const sendEmailPOSLinkPaymentFailed = (error) => ({
  payload: error,
  type: SEND_EMAIL_POS_LINK_PAYMENT_FAILED
});
export const sendEmailPOSLinkPaymentSuccess = (payload) => ({
  payload,
  type: SEND_EMAIL_POS_LINK_PAYMENT_SUCCESS
});

export const registerPOSTransaction = (payload) => ({
  payload,
  type: REGISTER_POS_TRANSACTION
});
export const registerPOSTransactionFailed = (error) => ({
  payload: error,
  type: REGISTER_POS_TRANSACTION_FAILED
});
export const registerPOSTransactionSuccess = (payload) => ({
  payload,
  type: REGISTER_POS_TRANSACTION_SUCCESS
});

export const processPublicPOSLinkPaymentFailed = (error) => ({
  payload: error,
  type: PROCESS_PUBLIC_POS_LINK_PAYMENT_FAILED
});
export const processPublicPOSLinkPaymentSuccess = (payload) => ({
  payload,
  type: PROCESS_PUBLIC_POS_LINK_PAYMENT_SUCCESS
});
export const processPublicPOSLinkPayment = (posLinkID, payload) => ({
  payload,
  posLinkID,
  type: PROCESS_PUBLIC_POS_LINK_PAYMENT
});

export const validateLinkPaymentOTP = (payload) => ({
  payload,
  type: VALIDATE_OTP_TIGO_MONEY
});
export const validateLinkPaymentOTPFailed = (error) => ({
  payload: error,
  type: VALIDATE_OTP_TIGO_MONEY_FAILED
});
export const validateLinkPaymentOTPSuccess = (payload) => ({
  payload,
  type: VALIDATE_OTP_TIGO_MONEY_SUCCESS
});
