export const SET_COUNT_COUNTRIES = 'SET_COUNT_COUNTRIES';

export const CREATE_COUNTRY = 'CREATE_COUNTRY';
export const CREATE_COUNTRY_FAILED = 'CREATE_COUNTRY_FAILED';
export const CREATE_COUNTRY_SUCCESS = 'CREATE_COUNTRY_SUCCESS';

export const SEARCH_COUNTRY = 'SEARCH_COUNTRY';
export const SEARCH_COUNTRY_FAILED = 'SEARCH_COUNTRY_FAILED';
export const SEARCH_COUNTRY_SUCCESS = 'SEARCH_COUNTRY_SUCCESS';
export const RESET_SEARCH_COUNTRY = 'RESET_SEARCH_COUNTRY';

export const READ_COUNTRIES = 'READ_COUNTRIES';
export const READ_COUNTRIES_FAILED = 'READ_COUNTRIES_FAILED';
export const READ_COUNTRIES_SUCCESS = 'READ_COUNTRIES_SUCCESS';

export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const UPDATE_COUNTRY_FAILED = 'UPDATE_COUNTRY_FAILED';
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS';

export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const DELETE_COUNTRY_FAILED = 'DELETE_COUNTRY_FAILED';
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
