import {
  CARDINAL_GET_JWT,
  CARDINAL_GET_JWT_FAILED,
  CARDINAL_GET_JWT_SUCCESS,
  CARDINAL_VALIDATE_JWT,
  CARDINAL_VALIDATE_JWT_FAILED,
  CARDINAL_VALIDATE_JWT_SUCCESS,
  CARDINAL_LOOKUP,
  CARDINAL_LOOKUP_FAILED,
  CARDINAL_LOOKUP_SUCCESS
} from '../../constants/actionTypes';

const INIT_CARDINAL_GET_JWT_STATE = {
  loading: false,
  error: undefined,
  token: undefined
};
const INIT_CARDINAL_VALIDATE_JWT_STATE = {
  loading: false,
  error: undefined,
  cardinalData: undefined
};
const INIT_CARDINAL_LOOKUP_STATE = {
  loading: false,
  error: undefined,
  cardinalData: undefined
};

export const CardinalGetJWT = (state = INIT_CARDINAL_GET_JWT_STATE, { type, payload }) => {
  switch (type) {
    case CARDINAL_GET_JWT:
      return {
        loading: true,
        error: undefined,
        token: undefined
      };
    case CARDINAL_GET_JWT_FAILED:
      return {
        loading: false,
        error: payload,
        token: undefined
      };
    case CARDINAL_GET_JWT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        token: payload
      };
    default:
      return { ...state };
  }
};
export const CardinalValidateJWT = (state = INIT_CARDINAL_VALIDATE_JWT_STATE, { type, payload }) => {
  switch (type) {
    case CARDINAL_VALIDATE_JWT:
      return {
        loading: true,
        error: undefined,
        cardinalData: undefined
      };
    case CARDINAL_VALIDATE_JWT_FAILED:
      return {
        loading: false,
        error: payload,
        cardinalData: undefined
      };
    case CARDINAL_VALIDATE_JWT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        cardinalData: payload
      };
    default:
      return { ...state };
  }
};
export const CardinalLookup = (state = INIT_CARDINAL_LOOKUP_STATE, { type, payload }) => {
  switch (type) {
    case CARDINAL_LOOKUP:
      return {
        loading: true,
        error: undefined,
        cardinalData: undefined
      };
    case CARDINAL_LOOKUP_FAILED:
      return {
        loading: false,
        error: payload,
        cardinalData: undefined
      };
    case CARDINAL_LOOKUP_SUCCESS:
      return {
        loading: false,
        error: undefined,
        cardinalData: payload
      };
    default:
      return { ...state };
  }
};
