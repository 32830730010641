/** VIRTUAL POS - LINK **/

export const SET_COUNT_LINKS_PAYMENT = 'SET_COUNT_LINKS_PAYMENT';

export const CREATE_LINK_PAYMENT = 'CREATE_LINK_PAYMENT';
export const CREATE_LINK_PAYMENT_FAILED = 'CREATE_LINK_PAYMENT_FAILED';
export const CREATE_LINK_PAYMENT_SUCCESS = 'CREATE_LINK_PAYMENT_SUCCESS';

export const READ_LINK_PAYMENT = 'READ_LINK_PAYMENT';
export const READ_LINK_PAYMENT_FAILED = 'READ_LINK_PAYMENT_FAILED';
export const READ_LINK_PAYMENT_SUCCESS = 'READ_LINK_PAYMENT_SUCCESS';

export const READ_ALL_LINKS_PAYMENT = 'READ_ALL_LINKS_PAYMENT';
export const READ_ALL_LINKS_PAYMENT_FAILED = 'READ_ALL_LINKS_PAYMENT_FAILED';
export const READ_ALL_LINKS_PAYMENT_SUCCESS = 'READ_ALL_LINKS_PAYMENT_SUCCESS';

export const UPDATE_LINK_PAYMENT = 'UPDATE_LINK_PAYMENT';
export const UPDATE_LINK_PAYMENT_FAILED = 'UPDATE_LINK_PAYMENT_FAILED';
export const UPDATE_LINK_PAYMENT_SUCCESS = 'UPDATE_LINK_PAYMENT_SUCCESS';

export const CANCEL_LINK_PAYMENT = 'CANCEL_LINK_PAYMENT';
export const CANCEL_LINK_PAYMENT_FAILED = 'CANCEL_LINK_PAYMENT_FAILED';
export const CANCEL_LINK_PAYMENT_SUCCESS = 'CANCEL_LINK_PAYMENT_SUCCESS';

export const PROCESS_LINK_PAYMENT = 'PROCESS_LINK_PAYMENT';
export const PROCESS_LINK_PAYMENT_FAILED = 'PROCESS_LINK_PAYMENT_FAILED';
export const PROCESS_LINK_PAYMENT_SUCCESS = 'PROCESS_LINK_PAYMENT_SUCCESS';

export const SEND_EMAIL_POS_LINK_PAYMENT = 'SEND_EMAIL_POS_LINK_PAYMENT';
export const SEND_EMAIL_POS_LINK_PAYMENT_FAILED = 'SEND_EMAIL_POS_LINK_PAYMENT_FAILED';
export const SEND_EMAIL_POS_LINK_PAYMENT_SUCCESS = 'SEND_EMAIL_POS_LINK_PAYMENT_SUCCESS';

export const PROCESS_PUBLIC_POS_LINK_PAYMENT = 'PROCESS_PUBLIC_POS_LINK_PAYMENT';
export const PROCESS_PUBLIC_POS_LINK_PAYMENT_FAILED = 'PROCESS_PUBLIC_POS_LINK_PAYMENT_FAILED';
export const PROCESS_PUBLIC_POS_LINK_PAYMENT_SUCCESS = 'PROCESS_PUBLIC_POS_LINK_PAYMENT_SUCCESS';

export const READ_WALLETS_BY_ACCOUNT = 'READ_WALLETS_BY_ACCOUNT';
export const READ_WALLETS_BY_ACCOUNT_FAILED = 'READ_WALLETS_BY_ACCOUNT_FAILED';
export const READ_WALLETS_BY_ACCOUNT_SUCCESS = 'READ_WALLETS_BY_ACCOUNT_SUCCESS';

export const PAYMENT_VERIFICATION_WALLET = 'PAYMENT_VERIFICATION_WALLET';
export const PAYMENT_VERIFICATION_WALLET_FAILED = 'PAYMENT_VERIFICATION_WALLET';
export const PAYMENT_VERIFICATION_WALLET_SUCCESS = 'PAYMENT_VERIFICATION_SUCCESS';

export const PROCESS_ACCOUNT_SETTINGS = 'PROCESS_ACCOUNT_SETTINGS';
export const PROCESS_ACCOUNT_SETTINGS_FAILED = 'PROCESS_ACCOUNT_SETTINGS_FAILED';
export const PROCESS_ACCOUNT_SETTINGS_SUCCESS = 'PROCESS_ACCOUNT_SETTINGS_SUCCESS';

export const VALIDATE_OTP_TIGO_MONEY = 'VALIDATE_OTP_TIGO_MONEY';
export const VALIDATE_OTP_TIGO_MONEY_FAILED = 'VALIDATE_OTP_TIGO_MONEY_FAILED';
export const VALIDATE_OTP_TIGO_MONEY_SUCCESS = 'VALIDATE_OTP_TIGO_MONEY_SUCCESS';
