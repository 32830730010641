import fetchJSON from '../../utils/fetchJSON';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { createNotification, NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_ERROR } from 'react-redux-notify';
import { createMessageNotify } from '../../utils/notify';

import {
  CREATE_CATEGORIES,
  READ_CATEGORIES,
  UPDATE_CATEGORIES,
  DELETE_CATEGORIES,
  UPDATE_STATUS_CATEGORY,
  UPLOAD_IMAGE_CATEGORY,
  CATEGORIES_REFRESH
} from '../../constants/actionTypes';

import {
  modalCategoriesClose,
  setCountCategories,
  createCategoryFailed,
  createCategorySuccess,
  categoriesRefreshSuccess,
  categoriesRefreshFaliled,
  readCategories as readCategoriesAction,
  readCategoriesFailed,
  readCategoriesSuccess,
  updateCategoriesFailed,
  updateCategoriesSuccess,
  updateStatusCategoryFailed,
  updateStatusCategorySuccess,
  uploadImageCategory as uploadImageCategoryAction,
  uploadImageCategoryFailed,
  uploadImageCategorySuccess,
  deleteCategoriesFailed,
  deleteCategoriesSuccess
} from './actions';

function* createCategory({ payload: data }) {
  const { dataToSend, paramsGetCategories, image } = data;
  const options = {
    data: dataToSend,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/categories', options);
    if (image.exists) {
      yield put(uploadImageCategoryAction(response._id, { paramsToSend: image.form, paramsGetCategories }));
    } else {
      yield put(readCategoriesAction(paramsGetCategories));
    }
    yield put(createCategorySuccess(response));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Categoría creada correctamente')));
    yield put(modalCategoriesClose());
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(createCategoryFailed(message));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
  }
}

function* readCategories({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/categories', options);
    const { rows = 0, data = [] } = response;

    yield put(readCategoriesSuccess(data));
    yield put(setCountCategories(rows));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(readCategoriesFailed(message));

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
  }
}

function* categoriesRefresh({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/categories', options);
    const { rows = 0, data = [] } = response;
    yield put(categoriesRefreshSuccess(data));
    yield put(setCountCategories(rows));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(categoriesRefreshFaliled());
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
  }
}

function* updateCategory({ _id, payload: data }) {
  const { dataToSend, paramsGetCategories, image } = data;
  const options = {
    data: dataToSend,
    method: 'PUT'
  };
  try {
    const response = yield call(fetchJSON, `/v2/admin/categories/${_id}`, options);
    if (image.exists) {
      yield put(uploadImageCategoryAction(_id, { paramsToSend: image.form, paramsGetCategories }));
    } else {
      yield put(readCategoriesAction(paramsGetCategories));
    }
    yield put(updateCategoriesSuccess(response));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Categoría actualizada correctamente')));
    yield put(modalCategoriesClose());
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(updateCategoriesFailed(message));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(modalCategoriesClose());
  }
}

function* updateStatusCategory({ _id, payload: data }) {
  const { paramsGetCategories } = data;

  const options = {
    data: {},
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/categories/${_id}/state`, options);
    yield put(updateStatusCategorySuccess(response));
    yield put(readCategoriesAction(paramsGetCategories));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Estado categoría actualizado correctamente')));
    yield put(modalCategoriesClose());
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(updateStatusCategoryFailed(message));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
  }
}

function* uploadImageCategory({ _id, payload: data }) {
  const { paramsToSend, paramsGetCategories } = data;
  const options = {
    data: paramsToSend,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/categories/${_id}/image`, options);
    yield put(uploadImageCategorySuccess(response));
    yield put(readCategoriesAction(paramsGetCategories));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'imagen categoria actualizado correctamente')));
    yield put(modalCategoriesClose());
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(uploadImageCategoryFailed(message));
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
  }
}

function* deleteCategory({ _id, payload: data }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/categories/${_id}`, options);
    yield put(deleteCategoriesSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message;
    }

    yield put(deleteCategoriesFailed(message));
  }
}

// export function* watchCountCategory() {
//   yield takeEvery(SET_COUNT_Category, countCategory);
// }
export function* watchCreateCategory() {
  yield takeEvery(CREATE_CATEGORIES, createCategory);
}
export function* watchReadCategory() {
  yield takeEvery(READ_CATEGORIES, readCategories);
}
export function* watchRefreshCategory() {
  yield takeEvery(CATEGORIES_REFRESH, categoriesRefresh);
}
export function* watchUpdateCategory() {
  yield takeEvery(UPDATE_CATEGORIES, updateCategory);
}
export function* watchUpdateStatusCategory() {
  yield takeEvery(UPDATE_STATUS_CATEGORY, updateStatusCategory);
}
export function* watchUploadImageCategory() {
  yield takeEvery(UPLOAD_IMAGE_CATEGORY, uploadImageCategory);
}
export function* watchDeleteCategory() {
  yield takeEvery(DELETE_CATEGORIES, deleteCategory);
}

function* CategoriesSaga() {
  yield all([
    // fork(watchCountCategory),
    fork(watchCreateCategory),
    fork(watchReadCategory),
    fork(watchRefreshCategory),
    fork(watchUpdateCategory),
    fork(watchUpdateStatusCategory),
    fork(watchUploadImageCategory),
    fork(watchDeleteCategory)
  ]);
}

export default CategoriesSaga;
