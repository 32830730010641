export enum UserRolesEnum {
  API_USER = 'API_USER',
  ADMIN_USER = 'ADMIN_USER',
  SUPER_USER = 'SUPER_USER',
  TICKET_USER = 'TICKET_USER'
}

export enum LanguageEnum {
  English = 'en',
  Spanish = 'es',
  Netherlands = 'nl'
}

export enum RolesTypeEnum {
  ADMIN = 'ADMIN',
  FINANCE = 'FINANCE',
  SUPPORT = 'SUPPORT'
}
