import {
  SET_COUNT_PAYMENTMETHOD,
  CREATE_PAYMENTMETHOD,
  CREATE_PAYMENTMETHOD_FAILED,
  CREATE_PAYMENTMETHOD_SUCCESS,
  READ_PAYMENTMETHODS,
  READ_PAYMENTMETHODS_FAILED,
  READ_PAYMENTMETHODS_SUCCESS,
  UPDATE_PAYMENTMETHOD,
  UPDATE_PAYMENTMETHOD_FAILED,
  UPDATE_PAYMENTMETHOD_SUCCESS,
  DELETE_PAYMENTMETHOD,
  DELETE_PAYMENTMETHOD_FAILED,
  DELETE_PAYMENTMETHOD_SUCCESS
} from '../../constants/actionTypes';

const INIT_COUNT_PAYMENT_METHODS = {
  totalPaymentMethods: 0
};

const INIT_CREATE_PAYMENTMETHOD_STATE = {
  loading: false,
  error: undefined,
  paymentMethods: undefined
};
const INIT_READ_PAYMENTMETHODS_STATE = {
  loading: false,
  error: undefined,
  paymentMethod: undefined
};

export const CreatePaymentMethod = (state = INIT_CREATE_PAYMENTMETHOD_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_PAYMENTMETHOD:
      return {
        loading: true,
        error: undefined,
        paymentMethod: undefined
      };
    case CREATE_PAYMENTMETHOD_FAILED:
      return {
        loading: false,
        error: payload,
        paymentMethod: undefined
      };
    case CREATE_PAYMENTMETHOD_SUCCESS:
      return {
        loading: false,
        error: undefined,
        paymentMethod: payload
      };
    default:
      return state;
  }
};

export const CountPaymentMethods = (state = INIT_COUNT_PAYMENT_METHODS, { type, payload }) => {
  switch (type) {
    case SET_COUNT_PAYMENTMETHOD:
      return {
        totalPaymentMethods: payload
      };
    default:
      return state;
  }
};

export const ReadPaymentMethods = (state = INIT_READ_PAYMENTMETHODS_STATE, { type, payload }) => {
  switch (type) {
    case READ_PAYMENTMETHODS:
      return {
        loading: true,
        error: undefined,
        paymentMethods: undefined
      };
    case READ_PAYMENTMETHODS_FAILED:
      return {
        loading: false,
        error: payload,
        paymentMethods: undefined
      };
    case READ_PAYMENTMETHODS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        paymentMethods: payload
      };
    default:
      return state;
  }
};
export const UpdatePaymentMethod = (state = INIT_CREATE_PAYMENTMETHOD_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_PAYMENTMETHOD:
      return {
        loading: true,
        error: undefined,
        paymentMethod: undefined
      };
    case UPDATE_PAYMENTMETHOD_FAILED:
      return {
        loading: false,
        error: payload,
        paymentMethod: undefined
      };
    case UPDATE_PAYMENTMETHOD_SUCCESS:
      return {
        loading: false,
        error: undefined,
        paymentMethod: payload
      };
    default:
      return state;
  }
};
export const DeletePaymentMethod = (state = INIT_CREATE_PAYMENTMETHOD_STATE, { type, payload }) => {
  switch (type) {
    case DELETE_PAYMENTMETHOD:
      return {
        loading: true,
        error: undefined,
        paymentMethod: undefined
      };
    case DELETE_PAYMENTMETHOD_FAILED:
      return {
        loading: false,
        error: payload,
        paymentMethod: undefined
      };
    case DELETE_PAYMENTMETHOD_SUCCESS:
      return {
        loading: false,
        error: undefined,
        paymentMethod: payload
      };
    default:
      return state;
  }
};
