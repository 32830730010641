import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_FAILED,
  CREATE_PRODUCT_SUCCESS,
  READ_PRODUCTS,
  READ_PRODUCTS_FAILED,
  READ_PRODUCTS_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_FAILED,
  DELETE_PRODUCT_SUCCESS
} from '../../constants/actionTypes';

export const createProduct = (payload) => ({ payload, type: CREATE_PRODUCT });
export const createProductFailed = (payload) => ({ payload, type: CREATE_PRODUCT_FAILED });
export const createProductSuccess = (payload) => ({ payload, type: CREATE_PRODUCT_SUCCESS });

export const readProducts = (payload) => ({ payload, type: READ_PRODUCTS });
export const readProductsFailed = (payload) => ({ payload, type: READ_PRODUCTS_FAILED });
export const readProductsSuccess = (payload) => ({ payload, type: READ_PRODUCTS_SUCCESS });

export const updateProduct = (_id, payload) => ({ _id, payload, type: UPDATE_PRODUCT });
export const updateProductFailed = (payload) => ({ payload, type: UPDATE_PRODUCT_FAILED });
export const updateProductSuccess = (payload) => ({ payload, type: UPDATE_PRODUCT_SUCCESS });

export const deleteProduct = (_id) => ({ _id, type: DELETE_PRODUCT });
export const deleteProductFailed = (payload) => ({ payload, type: DELETE_PRODUCT_FAILED });
export const deleteProductSuccess = (payload) => ({ payload, type: DELETE_PRODUCT_SUCCESS });
