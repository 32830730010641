import {
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_FAILED,
  CREATE_SUBSCRIPTION_SUCCESS,
  READ_SUBSCRIPTION_ID,
  READ_SUBSCRIPTION_ID_FAILED,
  READ_SUBSCRIPTION_ID_SUCCESS,
  READ_SUBSCRIPTION_ID_CALENDAR,
  READ_SUBSCRIPTION_ID_CALENDAR_FAILED,
  READ_SUBSCRIPTION_ID_CALENDAR_SUCCESS,
  READ_SUBSCRIPTION_ID_CALENDAR_DETAILS,
  READ_SUBSCRIPTION_ID_CALENDAR_DETAILS_FAILED,
  READ_SUBSCRIPTION_ID_CALENDAR_DETAILS_SUCCESS,
  READ_ALL_SUBSCRIPTIONS,
  READ_ALL_SUBSCRIPTIONS_FAILED,
  READ_ALL_SUBSCRIPTIONS_SUCCESS,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_FAILED,
  UPDATE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_FAILED,
  DELETE_SUBSCRIPTION_SUCCESS
} from '../../constants/actionTypes';

export const createSubscription = (payload) => ({ payload, type: CREATE_SUBSCRIPTION });
export const createSubscriptionFailed = (payload) => ({ payload, type: CREATE_SUBSCRIPTION_FAILED });
export const createSubscriptionSuccess = (payload) => ({ payload, type: CREATE_SUBSCRIPTION_SUCCESS });

export const readSubscriptionID = (id, payload) => ({ id, payload, type: READ_SUBSCRIPTION_ID });
export const readSubscriptionIDFailed = (payload) => ({ payload, type: READ_SUBSCRIPTION_ID_FAILED });
export const readSubscriptionIDSuccess = (payload) => ({ payload, type: READ_SUBSCRIPTION_ID_SUCCESS });

export const readSubscriptionIDCalendar = (id, payload) => ({ id, payload, type: READ_SUBSCRIPTION_ID_CALENDAR });
export const readSubscriptionIDCalendarFailed = (payload) => ({ payload, type: READ_SUBSCRIPTION_ID_CALENDAR_FAILED });
export const readSubscriptionIDCalendarSuccess = (payload) => ({ payload, type: READ_SUBSCRIPTION_ID_CALENDAR_SUCCESS });

export const readSubscriptionIDCalendarDetails = (id, chargeID, payload) => ({ id, chargeID, payload, type: READ_SUBSCRIPTION_ID_CALENDAR_DETAILS });
export const readSubscriptionIDCalendarDetailsFailed = (payload) => ({ payload, type: READ_SUBSCRIPTION_ID_CALENDAR_DETAILS_FAILED });
export const readSubscriptionIDCalendarDetailsSuccess = (payload) => ({ payload, type: READ_SUBSCRIPTION_ID_CALENDAR_DETAILS_SUCCESS });

export const readAllSubscriptions = (payload) => ({ payload, type: READ_ALL_SUBSCRIPTIONS });
export const readAllSubscriptionsFailed = (payload) => ({ payload, type: READ_ALL_SUBSCRIPTIONS_FAILED });
export const readAllSubscriptionsSuccess = (payload) => ({ payload, type: READ_ALL_SUBSCRIPTIONS_SUCCESS });

export const updateSubscriptionFailed = (payload) => ({ payload, type: UPDATE_SUBSCRIPTION_FAILED });
export const updateSubscriptionSuccess = (payload) => ({ payload, type: UPDATE_SUBSCRIPTION_SUCCESS });
export const updateSubscription = (payload, subscriptionID) => ({ payload, subscriptionID, type: UPDATE_SUBSCRIPTION });

export const deleteSubscriptionFailed = (payload) => ({ payload, type: DELETE_SUBSCRIPTION_FAILED });
export const deleteSubscriptionSuccess = (payload) => ({ payload, type: DELETE_SUBSCRIPTION_SUCCESS });
export const deleteSubscription = (subscriptionID) => ({ subscriptionID, type: DELETE_SUBSCRIPTION });
