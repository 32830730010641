export const SET_COUNT_CURRENCIES = 'SET_COUNT_CURRENCIES';

export const CREATE_CURRENCY = 'CREATE_CURRENCY';
export const CREATE_CURRENCY_FAILED = 'CREATE_CURRENCY_FAILED';
export const CREATE_CURRENCY_SUCCESS = 'CREATE_CURRENCY_SUCCESS';

export const READ_CURRENCIES = 'READ_CURRENCIES';
export const READ_CURRENCIES_FAILED = 'READ_CURRENCIES_FAILED';
export const READ_CURRENCIES_SUCCESS = 'READ_CURRENCIES_SUCCESS';

export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const UPDATE_CURRENCY_FAILED = 'UPDATE_CURRENCY_FAILED';
export const UPDATE_CURRENCY_SUCCESS = 'UPDATE_CURRENCY_SUCCESS';

export const DELETE_CURRENCY = 'DELETE_CURRENCY';
export const DELETE_CURRENCY_FAILED = 'DELETE_CURRENCY_FAILED';
export const DELETE_CURRENCY_SUCCESS = 'DELETE_CURRENCY_SUCCESS';
