import {
  SET_COUNT_PAID_LIQUIDATIONS,
  SET_COUNT_PENDING_LIQUIDATIONS,
  CREATE_LIQUIDATIONS,
  CREATE_LIQUIDATIONS_FAILED,
  CREATE_LIQUIDATIONS_SUCCESS,
  GET_ALL_LIQUIDATIONS,
  GET_ALL_LIQUIDATIONS_FAILED,
  GET_ALL_LIQUIDATIONS_SUCCESS,
  GET_ALL_PAID_LIQUIDATIONS,
  GET_ALL_PAID_LIQUIDATIONS_FAILED,
  GET_ALL_PAID_LIQUIDATIONS_SUCCESS,
  READ_LIQUIDATION,
  READ_LIQUIDATION_FAILED,
  READ_LIQUIDATION_SUCCESS,
  UPDATE_LIQUIDATION,
  UPDATE_LIQUIDATION_FAILED,
  UPDATE_LIQUIDATION_SUCCESS,
  PAY_LIQUIDATIONS,
  PAY_LIQUIDATIONS_FAILED,
  PAY_LIQUIDATIONS_SUCCESS,
  CALCULATE_LIQUIDATIONS,
  CALCULATE_LIQUIDATIONS_FAILED,
  CALCULATE_LIQUIDATIONS_SUCCESS,
  EXPORT_LIQUIDATIONS,
  EXPORT_LIQUIDATIONS_FAILED,
  EXPORT_LIQUIDATIONS_SUCCESS,
  EXPORT_PAID_LIQUIDATIONS,
  EXPORT_PAID_LIQUIDATIONS_FAILED,
  EXPORT_PAID_LIQUIDATIONS_SUCCESS,
  CANCEL_LIQUIDATION,
  CANCEL_LIQUIDATION_FAILED,
  CANCEL_LIQUIDATION_SUCCESS,
  RECALCULATE_COMMISSIONS,
  RECALCULATE_COMMISSIONS_FAILED,
  RECALCULATE_COMMISSIONS_SUCCESS,
  CHANGE_TO_PENDING_LIQUIDATION,
  CHANGE_TO_PENDING_LIQUIDATION_FAILED,
  CHANGE_TO_PENDING_LIQUIDATION_SUCCESS
} from '../../constants/actionTypes';

export const setCountPaidLiquidations = (payload) => ({ payload, type: SET_COUNT_PAID_LIQUIDATIONS });
export const setCountPendingLiquidations = (payload) => ({ payload, type: SET_COUNT_PENDING_LIQUIDATIONS });

export const createLiquidations = (payload) => ({
  payload,
  type: CREATE_LIQUIDATIONS
});
export const createLiquidationsFailed = (error) => ({
  payload: error,
  type: CREATE_LIQUIDATIONS_FAILED
});
export const createLiquidationsSuccess = (payload) => ({
  payload,
  type: CREATE_LIQUIDATIONS_SUCCESS
});

export const getAllLiquidations = (payload) => ({
  payload,
  type: GET_ALL_LIQUIDATIONS
});
export const getAllLiquidationsFailed = (error) => ({
  payload: error,
  type: GET_ALL_LIQUIDATIONS_FAILED
});
export const getAllLiquidationsSuccess = (payload) => ({
  payload,
  type: GET_ALL_LIQUIDATIONS_SUCCESS
});

export const getAllPaidLiquidations = (payload) => ({
  payload,
  type: GET_ALL_PAID_LIQUIDATIONS
});
export const getAllPaidLiquidationsFailed = (error) => ({
  payload: error,
  type: GET_ALL_PAID_LIQUIDATIONS_FAILED
});
export const getAllPaidLiquidationsSuccess = (payload) => ({
  payload,
  type: GET_ALL_PAID_LIQUIDATIONS_SUCCESS
});

export const readLiquidation = (closureID) => ({
  closureID,
  type: READ_LIQUIDATION
});
export const readLiquidationFailed = (error) => ({
  payload: error,
  type: READ_LIQUIDATION_FAILED
});
export const readLiquidationSuccess = (payload) => ({
  payload,
  type: READ_LIQUIDATION_SUCCESS
});

export const updateLiquidation = (liquidationID, payload) => ({ liquidationID, payload, type: UPDATE_LIQUIDATION });
export const updateLiquidationFailed = (payload) => ({ payload, type: UPDATE_LIQUIDATION_FAILED });
export const updateLiquidationSuccess = (payload) => ({ payload, type: UPDATE_LIQUIDATION_SUCCESS });

export const payLiquidations = (payload) => ({ payload, type: PAY_LIQUIDATIONS });
export const payLiquidationsFailed = (payload) => ({ payload, type: PAY_LIQUIDATIONS_FAILED });
export const payLiquidationsSuccess = (payload) => ({ payload, type: PAY_LIQUIDATIONS_SUCCESS });

export const calculateLiquidationsFailed = (payload) => ({ payload, type: CALCULATE_LIQUIDATIONS_FAILED });
export const calculateLiquidationsSuccess = (payload) => ({ payload, type: CALCULATE_LIQUIDATIONS_SUCCESS });
export const calculateLiquidations = (accountID, payload) => ({ payload, accountID, type: CALCULATE_LIQUIDATIONS });

export const exportLiquidations = (closureID, payload) => ({ closureID, payload, type: EXPORT_LIQUIDATIONS });
export const exportLiquidationsFailed = (payload) => ({ payload, type: EXPORT_LIQUIDATIONS_FAILED });
export const exportLiquidationsSuccess = (payload) => ({ payload, type: EXPORT_LIQUIDATIONS_SUCCESS });

export const getExportPaidLiquidations = (payload) => ({
  payload,
  type: EXPORT_PAID_LIQUIDATIONS
});
export const getExportPaidLiquidationsFailed = (payload) => ({
  payload,
  type: EXPORT_PAID_LIQUIDATIONS_FAILED
});
export const getExportPaidLiquidationsSuccess = (payload) => ({
  payload,
  type: EXPORT_PAID_LIQUIDATIONS_SUCCESS
});

export const cancelLiquidation = (liquidationID, payload) => ({ liquidationID, payload, type: CANCEL_LIQUIDATION });
export const cancelLiquidationFailed = (payload) => ({ payload, type: CANCEL_LIQUIDATION_FAILED });
export const cancelLiquidationSuccess = (payload) => ({ payload, type: CANCEL_LIQUIDATION_SUCCESS });

export const recalculateCommissions = (closureID) => ({ closureID, type: RECALCULATE_COMMISSIONS });
export const recalculateCommissionsFailed = (error) => ({ payload: error, type: RECALCULATE_COMMISSIONS_FAILED });
export const recalculateCommissionsSuccess = (payload) => ({ payload, type: RECALCULATE_COMMISSIONS_SUCCESS });

export const changeToPendingLiquidation = (payload) => ({ payload, type: CHANGE_TO_PENDING_LIQUIDATION });
export const changeToPendingLiquidationFailed = (error) => ({ payload: error, type: CHANGE_TO_PENDING_LIQUIDATION_FAILED });
export const changeToPendingLiquidationSuccess = (payload) => ({ payload, type: CHANGE_TO_PENDING_LIQUIDATION_SUCCESS });
