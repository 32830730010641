import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './locales/en.json';
import esTranslation from './locales/es.json';
import nlTranslation from './locales/nl.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    detection: {
      lookupQuerystring: 'lng',
      order: ['querystring', 'navigator'],
      caches: []
    },
    resources: {
      en: { translation: enTranslation },
      es: { translation: esTranslation },
      nl: { translation: nlTranslation }
    }
  });

export default i18n;
