import fetchJSON from '../../utils/fetchJSON';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';

import { CREATE_CHANNEL, READ_CHANNELS, UPDATE_CHANNEL, DELETE_CHANNEL } from '../../constants/actionTypes';

import {
  setCountChannels,
  createChannelFailed,
  createChannelSuccess,
  readChannelsFailed,
  readChannelsSuccess,
  updateChannelFailed,
  updateChannelSuccess,
  deleteChannelFailed,
  deleteChannelSuccess
} from './actions';
import { createNotification, NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify';
import { createMessageNotify } from '../../utils/notify';

function* createChannel({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/channels', options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Canal creado correctamente')));
    yield put(createChannelSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(createChannelFailed(message));
  }
}

function* readChannels({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/channels', options);
    const { rows = 0, data } = response;
    yield put(readChannelsSuccess(data || response));
    yield put(setCountChannels(rows));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(readChannelsFailed(message));
  }
}

function* updateChannel({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/channels/${_id}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Canal actualizado correctamente')));
    yield put(updateChannelSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(updateChannelFailed(message));
  }
}

function* deleteChannel({ _id, payload: data }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/channels/${_id}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'Canal actualizado correctamente')));
    yield put(deleteChannelSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(deleteChannelFailed(message));
  }
}

export function* watchCreateChannel() {
  yield takeEvery(CREATE_CHANNEL, createChannel);
}

export function* watchReadChannels() {
  yield takeEvery(READ_CHANNELS, readChannels);
}

export function* watchUpdateChannel() {
  yield takeEvery(UPDATE_CHANNEL, updateChannel);
}

export function* watchDeleteChannel() {
  yield takeEvery(DELETE_CHANNEL, deleteChannel);
}

function* ChannelsSaga() {
  yield all([fork(watchCreateChannel), fork(watchReadChannels), fork(watchUpdateChannel), fork(watchDeleteChannel)]);
}

export default ChannelsSaga;
