import { ReactNode, Suspense } from 'react';

const loading = () => <div className="text-center">Loading...</div>;

interface NonAuthLayoutProps {
  children: ReactNode;
}

const PublicLayout = ({ children }: NonAuthLayoutProps) => {
  return <Suspense fallback={loading()}>{children}</Suspense>;
};

export default PublicLayout;
