import moment from 'moment';

export const getCookie = (cookieName: string) => {
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${cookieName}=`))
    ?.split('=')[1];

  return cookieValue
    ? typeof decodeURIComponent(cookieValue) == 'object'
      ? cookieValue
      : JSON.parse(decodeURIComponent(cookieValue))
    : null;
};

export const setCookie = (
  name: string,
  data: string | Record<string, unknown>,
  isSession: boolean = false
) => {
  const cookieExpiration = isSession ? '' : moment().add(7, 'days').toDate();
  const cookieContent =
    Object.prototype.toString.call(data) === '[object String]'
      ? encodeURIComponent(data.toString())
      : encodeURIComponent(JSON.stringify(data));

  document.cookie = `${name}=${cookieContent};expires=${cookieExpiration};path=/;Secure`;
};

export const removeCookie = (name: string) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;Secure`;
};

export const clearCookies = () => {
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    const name = cookie.split('=')[0];
    removeCookie(name);
  }
  sessionStorage.removeItem('refreshNotification');
  sessionStorage.removeItem('lang');
};
