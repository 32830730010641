/** CUSTOMERS CARDS **/
export const CREATE_CUSTOMER_CARD = 'CREATE_CUSTOMER_CARD';
export const CREATE_CUSTOMER_CARD_FAILED = 'CREATE_CUSTOMER_CARD_FAILED';
export const CREATE_CUSTOMER_CARD_SUCCESS = 'CREATE_CUSTOMER_CARD_SUCCESS';

export const READ_CUSTOMER_CARDS = 'READ_CUSTOMER_CARDS';
export const READ_CUSTOMER_CARDS_FAILED = 'READ_CUSTOMER_CARDS_FAILED';
export const READ_CUSTOMER_CARDS_SUCCESS = 'READ_CUSTOMER_CARDS_SUCCESS';

export const UPDATE_CUSTOMER_CARD = 'UPDATE_CUSTOMER_CARD';
export const UPDATE_CUSTOMER_CARD_FAILED = 'UPDATE_CUSTOMER_CARD_FAILED';
export const UPDATE_CUSTOMER_CARD_SUCCESS = 'UPDATE_CUSTOMER_CARD_SUCCESS';

export const DELETE_CUSTOMER_CARD = 'DELETE_CUSTOMER_CARD';
export const DELETE_CUSTOMER_CARD_FAILED = 'DELETE_CUSTOMER_CARD_FAILED';
export const DELETE_CUSTOMER_CARD_SUCCESS = 'DELETE_CUSTOMER_CARD_SUCCESS';
