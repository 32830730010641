import {
  SET_COUNT_VENUES,
  SET_ORDER_VENUES,
  CREATE_VENUES,
  CREATE_VENUES_FAILED,
  CREATE_VENUES_SUCCESS,
  READ_VENUES,
  READ_VENUES_FAILED,
  READ_VENUES_SUCCESS,
  UPDATE_VENUES,
  UPDATE_VENUES_FAILED,
  UPDATE_VENUES_SUCCESS,
  UPDATE_STATUS_VENUES,
  UPDATE_STATUS_VENUES_FAILED,
  UPDATE_STATUS_VENUES_SUCCESS,
  UPLOAD_LOGO_VENUES,
  UPLOAD_LOGO_VENUES_FAILED,
  UPLOAD_LOGO_VENUES_SUCCESS,
  DELETE_VENUES,
  DELETE_VENUES_FAILED,
  DELETE_VENUES_SUCCESS,
  MODAL_NEW_VENUES_OPEN,
  MODAL_UPDATE_VENUES_OPEN,
  MODAL_VENUES_CLOSE
} from '../../constants/actionTypes';

export const setCountVenues = (payload) => ({ payload, type: SET_COUNT_VENUES });
export const setOrderVenues = (payload) => ({ payload, type: SET_ORDER_VENUES });

export const createVenues = (payload) => ({ payload, type: CREATE_VENUES });
export const createVenuesFailed = (payload) => ({ payload, type: CREATE_VENUES_FAILED });
export const createVenuesSuccess = (payload) => ({ payload, type: CREATE_VENUES_SUCCESS });

export const readVenues = (payload) => ({ payload, type: READ_VENUES });
export const readVenuesFailed = (payload) => ({ payload, type: READ_VENUES_FAILED });
export const readVenuesSuccess = (payload) => ({ payload, type: READ_VENUES_SUCCESS });

export const updateVenues = (_id, payload) => ({ _id, payload, type: UPDATE_VENUES });
export const updateVenuesFailed = (payload) => ({ payload, type: UPDATE_VENUES_FAILED });
export const updateVenuesSuccess = (payload) => ({ payload, type: UPDATE_VENUES_SUCCESS });

export const updateStatusVenues = (_id, payload) => ({ _id, payload, type: UPDATE_STATUS_VENUES });
export const updateStatusVenuesFailed = (payload) => ({ payload, type: UPDATE_STATUS_VENUES_FAILED });
export const updateStatusVenuesSuccess = (payload) => ({ payload, type: UPDATE_STATUS_VENUES_SUCCESS });

export const uploadLogoVenues = (_id, payload) => ({ _id, payload, type: UPLOAD_LOGO_VENUES });
export const uploadLogoVenuesFailed = (payload) => ({ payload, type: UPLOAD_LOGO_VENUES_FAILED });
export const uploadLogoVenuesSuccess = (payload) => ({ payload, type: UPLOAD_LOGO_VENUES_SUCCESS });

export const deleteVenues = (_id) => ({ _id, type: DELETE_VENUES });
export const deleteVenuesFailed = (payload) => ({ payload, type: DELETE_VENUES_FAILED });
export const deleteVenuesSuccess = (payload) => ({ payload, type: DELETE_VENUES_SUCCESS });

export const modalNewVenuesOpen = () => ({ type: MODAL_NEW_VENUES_OPEN });
export const modalUpdateVenuesOpen = () => ({ type: MODAL_UPDATE_VENUES_OPEN });
export const modalVenuesClose = () => ({ type: MODAL_VENUES_CLOSE });
