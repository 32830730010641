import {
  SET_COUNT_ISSUER_BINS,
  CREATE_ISSUER_BINS,
  CREATE_ISSUER_BINS_FAILED,
  CREATE_ISSUER_BINS_SUCCESS,
  SEARCH_ISSUER_BINS,
  SEARCH_ISSUER_BINS_SUCCESS,
  SEARCH_ISSUER_BINS_FAILED,
  RESET_SEARCH_ISSUER_BINS,
  READ_ISSUER_BINS,
  READ_ISSUER_BINS_FAILED,
  READ_ISSUER_BINS_SUCCESS,
  UPDATE_ISSUER_BINS,
  UPDATE_ISSUER_BINS_FAILED,
  UPDATE_ISSUER_BINS_SUCCESS,
  DELETE_ISSUER_BINS,
  DELETE_ISSUER_BINS_FAILED,
  DELETE_ISSUER_BINS_SUCCESS,
  UPDATE_STATUS_ISSUER_BINS,
  UPDATE_STATUS_ISSUER_BINS_FAILED,
  UPDATE_STATUS_ISSUER_BINS_SUCCESS
} from '../../constants/actionTypes';

export const setCountIssuerBins = (payload) => ({ payload, type: SET_COUNT_ISSUER_BINS });

export const createIssuerBins = (payload) => ({ payload, type: CREATE_ISSUER_BINS });
export const createIssuerBinsFailed = (payload) => ({ payload, type: CREATE_ISSUER_BINS_FAILED });
export const createIssuerBinsSuccess = (payload) => ({ payload, type: CREATE_ISSUER_BINS_SUCCESS });

export const resetSearchIssuerBins = () => ({ type: RESET_SEARCH_ISSUER_BINS });
export const searchIssuerBins = (payload) => ({ payload, type: SEARCH_ISSUER_BINS });
export const searchIssuerBinsFailed = (payload) => ({ payload, type: SEARCH_ISSUER_BINS_FAILED });
export const searchIssuerBinsSuccess = (payload) => ({ payload, type: SEARCH_ISSUER_BINS_SUCCESS });

export const readIssuerBins = (payload) => ({ payload, type: READ_ISSUER_BINS });
export const readIssuerBinsFailed = (payload) => ({ payload, type: READ_ISSUER_BINS_FAILED });
export const readIssuerBinsSuccess = (payload) => ({ payload, type: READ_ISSUER_BINS_SUCCESS });

export const updateStatusIssuerBins = (_id, payload) => ({ _id, payload, type: UPDATE_STATUS_ISSUER_BINS });
export const updateStatusIssuerBinsFailed = (payload) => ({ payload, type: UPDATE_STATUS_ISSUER_BINS_FAILED });
export const updateStatusIssuerBinsSuccess = (payload) => ({ payload, type: UPDATE_STATUS_ISSUER_BINS_SUCCESS });

export const updateIssuerBins = (_id, payload) => ({ _id, payload, type: UPDATE_ISSUER_BINS });
export const updateIssuerBinsFailed = (payload) => ({ payload, type: UPDATE_ISSUER_BINS_FAILED });
export const updateIssuerBinsSuccess = (payload) => ({ payload, type: UPDATE_ISSUER_BINS_SUCCESS });

export const deleteIssuerBins = (_id) => ({ _id, type: DELETE_ISSUER_BINS });
export const deleteIssuerBinsFailed = (payload) => ({ payload, type: DELETE_ISSUER_BINS_FAILED });
export const deleteIssuerBinsSuccess = (payload) => ({ payload, type: DELETE_ISSUER_BINS_SUCCESS });
