import axios from 'axios';
import { getLoggedInUser, isUserAuthenticated } from '../helpers/authUtils';

const fetchJSON = async (path, options = {}) => {
  const url = `${process.env.REACT_APP_API_URL}${path}`;

  const isAuthTokenValid = isUserAuthenticated();

  let headers = {
    'Content-Type': 'application/json'
  };

  if (isAuthTokenValid) {
    const user = getLoggedInUser();
    const userToken = user.token;

    headers['Authorization'] = `Bearer ${userToken}`;
  }

  try {
    const response = await axios({
      ...options,
      url,
      headers
    });

    const { data } = response;

    return data;
  } catch (error) {
    const { message, request, response } = error;
    if (response) {
      const { data } = response;
      throw data;
    } else if (request) {
      throw request;
    } else {
      throw message;
    }
  }
};

export default fetchJSON;
