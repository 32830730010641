import {
  SET_COUNT_CURRENCIES,
  CREATE_CURRENCY,
  CREATE_CURRENCY_FAILED,
  CREATE_CURRENCY_SUCCESS,
  READ_CURRENCIES,
  READ_CURRENCIES_FAILED,
  READ_CURRENCIES_SUCCESS,
  UPDATE_CURRENCY,
  UPDATE_CURRENCY_FAILED,
  UPDATE_CURRENCY_SUCCESS,
  DELETE_CURRENCY,
  DELETE_CURRENCY_FAILED,
  DELETE_CURRENCY_SUCCESS
} from '../../constants/actionTypes';

export const setCountCurrencies = (payload) => ({ payload, type: SET_COUNT_CURRENCIES });

export const createCurrency = (payload) => ({ payload, type: CREATE_CURRENCY });
export const createCurrencyFailed = (payload) => ({ payload, type: CREATE_CURRENCY_FAILED });
export const createCurrencySuccess = (payload) => ({ payload, type: CREATE_CURRENCY_SUCCESS });

export const readCurrencies = (payload) => ({ payload, type: READ_CURRENCIES });
export const readCurrenciesFailed = (payload) => ({ payload, type: READ_CURRENCIES_FAILED });
export const readCurrenciesSuccess = (payload) => ({ payload, type: READ_CURRENCIES_SUCCESS });

export const updateCurrency = (_id, payload) => ({ _id, payload, type: UPDATE_CURRENCY });
export const updateCurrencyFailed = (payload) => ({ payload, type: UPDATE_CURRENCY_FAILED });
export const updateCurrencySuccess = (payload) => ({ payload, type: UPDATE_CURRENCY_SUCCESS });

export const deleteCurrency = (_id) => ({ _id, type: DELETE_CURRENCY });
export const deleteCurrencyFailed = (payload) => ({ payload, type: DELETE_CURRENCY_FAILED });
export const deleteCurrencySuccess = (payload) => ({ payload, type: DELETE_CURRENCY_SUCCESS });
