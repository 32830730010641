import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_FAILED,
  CREATE_PRODUCT_SUCCESS,
  READ_PRODUCTS,
  READ_PRODUCTS_FAILED,
  READ_PRODUCTS_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_FAILED,
  DELETE_PRODUCT_SUCCESS
} from '../../constants/actionTypes';

const INIT_STATE_CREATE_PRODUCT = {
  loading: false,
  error: undefined,
  product: undefined
};
const INIT_STATE_READ_PRODUCTS = {
  loading: false,
  error: undefined,
  products: undefined
};
const INIT_STATE_UPDATE_PRODUCT = {
  loading: false,
  error: undefined,
  product: undefined
};
const INIT_STATE_DELETE_PRODUCT = {
  loading: false,
  error: undefined,
  product: undefined
};

export const CreateProduct = (state = INIT_STATE_CREATE_PRODUCT, { type, payload }) => {
  switch (type) {
    case CREATE_PRODUCT:
      return {
        loading: true,
        error: undefined,
        product: undefined
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        product: payload
      };
    case CREATE_PRODUCT_FAILED:
      return {
        loading: false,
        error: payload,
        product: undefined
      };
    default:
      return { ...state };
  }
};
export const ReadProducts = (state = INIT_STATE_READ_PRODUCTS, { type, payload }) => {
  switch (type) {
    case READ_PRODUCTS:
      return {
        loading: true,
        error: undefined,
        products: undefined
      };
    case READ_PRODUCTS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        products: payload
      };
    case READ_PRODUCTS_FAILED:
      return {
        loading: false,
        error: payload,
        products: undefined
      };
    default:
      return { ...state };
  }
};
export const UpdateProduct = (state = INIT_STATE_UPDATE_PRODUCT, { type, payload }) => {
  switch (type) {
    case UPDATE_PRODUCT:
      return {
        loading: true,
        error: undefined,
        product: undefined
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        product: payload
      };
    case UPDATE_PRODUCT_FAILED:
      return {
        loading: false,
        error: payload,
        product: undefined
      };
    default:
      return { ...state };
  }
};
export const DeleteProduct = (state = INIT_STATE_DELETE_PRODUCT, { type, payload }) => {
  switch (type) {
    case DELETE_PRODUCT:
      return {
        loading: true,
        error: undefined,
        product: undefined
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        loading: false,
        error: undefined,
        product: payload
      };
    case DELETE_PRODUCT_FAILED:
      return {
        loading: false,
        error: payload,
        product: undefined
      };
    default:
      return { ...state };
  }
};
