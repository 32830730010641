import {
  SET_COUNT_ACCOUNTS,
  GET_MY_ACCOUNT,
  GET_MY_ACCOUNT_FAILED,
  GET_MY_ACCOUNT_SUCCESS,
  REGISTER_ACCOUNT,
  REGISTER_ACCOUNT_FAILED,
  REGISTER_ACCOUNT_SUCCESS,
  GET_ALL_ACCOUNTS,
  GET_ALL_ACCOUNTS_FAILED,
  GET_ALL_ACCOUNTS_SUCCESS,
  READ_ACCOUNT_ID,
  READ_ACCOUNT_ID_FAILED,
  READ_ACCOUNT_ID_SUCCESS,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_FAILED,
  UPDATE_ACCOUNT_SUCCESS,
  UPLOAD_LOGO_ACCOUNT,
  UPLOAD_LOGO_ACCOUNT_FAILED,
  UPLOAD_LOGO_ACCOUNT_SUCCESS,
  GET_ACCOUNT_TERMINALS,
  GET_ACCOUNT_TERMINALS_FAILED,
  GET_ACCOUNT_TERMINALS_SUCCESS,
  UPDATE_ACCOUNT_TERMINAL,
  UPDATE_ACCOUNT_TERMINAL_FAILED,
  UPDATE_ACCOUNT_TERMINAL_SUCCESS,
  CREATE_ACCOUNT_TERMINAL,
  CREATE_ACCOUNT_TERMINAL_FAILED,
  CREATE_ACCOUNT_TERMINAL_SUCCESS,
  DELETE_ACCOUNT_TERMINAL,
  DELETE_ACCOUNT_TERMINAL_FAILED,
  DELETE_ACCOUNT_TERMINAL_SUCCESS,
  CARDINAL_ONBOARDING,
  CARDINAL_ONBOARBING_FAILED,
  CARDINAL_ONBOARBING_SUCCESS
} from '../../constants/actionTypes';

const INIT_STATE = {
  loading: false,
  logo: undefined,
  error: undefined,
  account: undefined,
  accounts: undefined
};

const INIT_COUNT_ACCOUNTS_STATE = {
  totalAccounts: 0
};

const INIT_UPDATE_STATE = {
  loading: false,
  error: undefined,
  account: undefined
};
const INIT_READ_ACCOUNT_ID = {
  loading: false,
  error: undefined,
  account: undefined
};
const INIT_READ_ALL_ACCOUNTS = {
  loading: false,
  error: undefined,
  accounts: undefined
};

const INIT_TERMINALS_STATE = {
  loading: false,
  error: undefined,
  terminal: undefined,
  terminals: undefined
};

const INIT_CARDINAL_STATE = {
  loading: false,
  error: undefined,
  service3DS: undefined
};

export const CountAccounts = (state = INIT_COUNT_ACCOUNTS_STATE, { type, payload }) => {
  switch (type) {
    case SET_COUNT_ACCOUNTS:
      return {
        totalAccounts: payload
      };
    default:
      return { ...state };
  }
};

export const ReadAllAccounts = (state = INIT_READ_ALL_ACCOUNTS, { type, payload }) => {
  switch (type) {
    case GET_ALL_ACCOUNTS:
      return {
        loading: true,
        error: undefined,
        accounts: undefined
      };
    case GET_ALL_ACCOUNTS_FAILED:
      return {
        loading: false,
        error: payload,
        accounts: undefined
      };
    case GET_ALL_ACCOUNTS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        accounts: payload
      };
    default:
      return { ...state };
  }
};
export const ReadAccountID = (state = INIT_READ_ACCOUNT_ID, { type, payload }) => {
  switch (type) {
    case READ_ACCOUNT_ID:
      return {
        loading: true,
        error: undefined,
        account: undefined
      };
    case READ_ACCOUNT_ID_FAILED:
      return {
        loading: false,
        error: payload,
        account: undefined
      };
    case READ_ACCOUNT_ID_SUCCESS:
      return {
        loading: false,
        error: undefined,
        account: payload
      };
    default:
      return { ...state };
  }
};

export const Accounts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MY_ACCOUNT:
      return {
        ...state,
        loading: true
      };
    case GET_MY_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_MY_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        account: action.payload
      };
    case REGISTER_ACCOUNT:
      return {
        ...state,
        loading: true
      };
    case REGISTER_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case REGISTER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        account: action.payload
      };
    default:
      return { ...state };
  }
};
export const RegisterAccount = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case REGISTER_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: undefined,
        account: undefined
      };
    case REGISTER_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        account: undefined
      };
    case REGISTER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        account: payload
      };
    default:
      return { ...state };
  }
};
export const UpdateAccount = (state = INIT_UPDATE_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_ACCOUNT:
      return {
        loading: true,
        error: undefined,
        account: undefined
      };
    case UPDATE_ACCOUNT_FAILED:
      return {
        loading: false,
        error: payload,
        account: undefined
      };
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        loading: false,
        account: payload,
        error: undefined
      };
    default:
      return { ...state };
  }
};
export const UploadLogoAccount = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case UPLOAD_LOGO_ACCOUNT:
      return {
        loading: true,
        error: undefined,
        logo: undefined
      };
    case UPLOAD_LOGO_ACCOUNT_FAILED:
      return {
        loading: false,
        error: payload,
        logo: undefined
      };
    case UPLOAD_LOGO_ACCOUNT_SUCCESS:
      return {
        loading: false,
        logo: payload,
        error: undefined
      };
    default:
      return { ...state };
  }
};

export const getAccountTerminals = (state = INIT_TERMINALS_STATE, { type, payload }) => {
  switch (type) {
    case GET_ACCOUNT_TERMINALS:
      return {
        loading: true,
        error: undefined,
        terminals: undefined
      };
    case GET_ACCOUNT_TERMINALS_FAILED:
      return {
        loading: false,
        error: payload,
        terminals: undefined
      };
    case GET_ACCOUNT_TERMINALS_SUCCESS:
      return {
        loading: false,
        terminals: payload,
        error: undefined
      };
    default:
      return { ...state };
  }
};
export const UpdateAccountTerminal = (state = INIT_TERMINALS_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_ACCOUNT_TERMINAL:
      return {
        loading: true,
        error: undefined,
        terminal: undefined
      };
    case UPDATE_ACCOUNT_TERMINAL_FAILED:
      return {
        loading: false,
        error: payload,
        terminal: undefined
      };
    case UPDATE_ACCOUNT_TERMINAL_SUCCESS:
      return {
        loading: false,
        terminal: payload,
        error: undefined
      };
    default:
      return { ...state };
  }
};
export const CreateAccountTerminal = (state = INIT_TERMINALS_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_ACCOUNT_TERMINAL:
      return {
        loading: true,
        error: undefined,
        terminal: undefined,
        terminals: undefined
      };
    case CREATE_ACCOUNT_TERMINAL_FAILED:
      return {
        loading: false,
        error: payload,
        terminal: undefined
      };
    case CREATE_ACCOUNT_TERMINAL_SUCCESS:
      return {
        loading: false,
        terminal: payload,
        error: undefined
      };
    default:
      return { ...state };
  }
};

export const DeleteAccountTerminal = (state = INIT_TERMINALS_STATE, { type, payload }) => {
  switch (type) {
    case DELETE_ACCOUNT_TERMINAL:
      return {
        loading: true,
        error: undefined,
        terminal: undefined
      };
    case DELETE_ACCOUNT_TERMINAL_FAILED:
      return {
        loading: false,
        error: payload,
        terminal: undefined
      };
    case DELETE_ACCOUNT_TERMINAL_SUCCESS:
      return {
        loading: false,
        terminal: payload,
        error: undefined
      };
    default:
      return state;
  }
};

export const GetCardinalOnboarding = (state = INIT_CARDINAL_STATE, { type, payload }) => {
  switch (type) {
    case CARDINAL_ONBOARDING:
      return {
        loading: true,
        error: undefined,
        service3DS: undefined
      };
    case CARDINAL_ONBOARBING_FAILED:
      return {
        loading: false,
        error: payload,
        service3DS: undefined
      };
    case CARDINAL_ONBOARBING_SUCCESS:
      return {
        loading: false,
        service3DS: payload,
        error: undefined
      };
    default:
      return { ...state };
  }
};
