import {
  SET_COUNT_VENUES,
  SET_ORDER_VENUES,
  CREATE_VENUES,
  CREATE_VENUES_FAILED,
  CREATE_VENUES_SUCCESS,
  READ_VENUES,
  READ_VENUES_FAILED,
  READ_VENUES_SUCCESS,
  UPDATE_VENUES,
  UPDATE_VENUES_FAILED,
  UPDATE_VENUES_SUCCESS,
  UPDATE_STATUS_VENUES,
  UPDATE_STATUS_VENUES_FAILED,
  UPDATE_STATUS_VENUES_SUCCESS,
  UPLOAD_LOGO_VENUES,
  UPLOAD_LOGO_VENUES_FAILED,
  UPLOAD_LOGO_VENUES_SUCCESS,
  DELETE_VENUES,
  DELETE_VENUES_FAILED,
  DELETE_VENUES_SUCCESS,
  MODAL_NEW_VENUES_OPEN,
  MODAL_UPDATE_VENUES_OPEN,
  MODAL_VENUES_CLOSE
} from '../../constants/actionTypes';

const INIT_STATE = {
  logo: undefined,
  loading: false,
  error: undefined
};

const INIT_COUNT_VENUES_STATE = {
  totalVenues: undefined
};

const INIT_UPDATE_STATUS_VENUES_STATE = {
  loading: false,
  error: undefined,
  updatedStatus: undefined
};

const INIT_CREATE_VENUES_STATE = {
  loading: false,
  error: undefined,
  venue: undefined
};
const INIT_READ_VENUES_STATE = {
  loading: false,
  error: undefined,
  venues: undefined
};

const INIT_MODAL_NEW_VENUE_STATE = {
  isNewOpen: false
};
const INIT_MODAL_UPDATE_VENUE_STATE = {
  isUpdateOpen: false
};

export const CountVenues = (state = INIT_COUNT_VENUES_STATE, { type, payload }) => {
  switch (type) {
    case SET_COUNT_VENUES:
      return {
        totalVenues: payload
      };
    default:
      return { ...state };
  }
};
export const CreateVenue = (state = INIT_CREATE_VENUES_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_VENUES:
      return {
        loading: true,
        error: undefined,
        venue: undefined
      };
    case CREATE_VENUES_FAILED:
      return {
        loading: false,
        error: payload,
        venue: undefined
      };
    case CREATE_VENUES_SUCCESS:
      return {
        loading: false,
        error: undefined,
        venue: payload
      };
    default:
      return { ...state };
  }
};
export const ReadVenues = (state = INIT_READ_VENUES_STATE, { type, payload }) => {
  switch (type) {
    case READ_VENUES:
      return {
        loading: true,
        error: undefined,
        venues: undefined
      };
    case READ_VENUES_FAILED:
      return {
        loading: false,
        error: payload,
        venues: undefined
      };
    case READ_VENUES_SUCCESS:
      return {
        loading: false,
        error: undefined,
        venues: payload
      };
    case SET_ORDER_VENUES:
      return {
        venues: payload
      };
    default:
      return { ...state };
  }
};
export const UpdateVenue = (state = INIT_CREATE_VENUES_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_VENUES:
      return {
        loading: true,
        error: undefined,
        Venue: undefined
      };
    case UPDATE_VENUES_FAILED:
      return {
        loading: false,
        error: payload,
        Venue: undefined
      };
    case UPDATE_VENUES_SUCCESS:
      return {
        loading: false,
        error: undefined,
        Venue: payload
      };
    default:
      return { ...state };
  }
};
export const UpdateStatusVenue = (state = INIT_UPDATE_STATUS_VENUES_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_VENUES:
      return {
        loading: true,
        error: undefined,
        updatedStatus: undefined
      };
    case UPDATE_STATUS_VENUES_FAILED:
      return {
        loading: false,
        error: payload,
        updatedStatus: false
      };
    case UPDATE_STATUS_VENUES_SUCCESS:
      return {
        loading: false,
        error: undefined,
        updatedStatus: true
      };
    default:
      return { ...state };
  }
};
export const uploadLogoVenue = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case UPLOAD_LOGO_VENUES:
      return {
        ...state,
        loading: true,
        error: undefined,
        logo: undefined
      };
    case UPLOAD_LOGO_VENUES_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        logo: false
      };
    case UPLOAD_LOGO_VENUES_SUCCESS:
      return {
        loading: false,
        error: undefined,
        logo: payload
      };
    default:
      return { ...state };
  }
};

export const DeleteVenue = (state = INIT_CREATE_VENUES_STATE, { type, payload }) => {
  switch (type) {
    case DELETE_VENUES:
      return {
        loading: true,
        error: undefined,
        venue: undefined
      };
    case DELETE_VENUES_FAILED:
      return {
        loading: false,
        error: payload,
        venue: undefined
      };
    case DELETE_VENUES_SUCCESS:
      return {
        loading: false,
        error: undefined,
        venue: payload
      };
    default:
      return { ...state };
  }
};

export const NewVenueModalStatus = (state = INIT_MODAL_NEW_VENUE_STATE, { type }) => {
  switch (type) {
    case MODAL_NEW_VENUES_OPEN:
      return {
        isNewOpen: true
      };
    case MODAL_VENUES_CLOSE:
      return {
        isNewOpen: false
      };
    default:
      return { ...state };
  }
};
export const UpdateVenueModalStatus = (state = INIT_MODAL_UPDATE_VENUE_STATE, { type }) => {
  switch (type) {
    case MODAL_UPDATE_VENUES_OPEN: {
      return {
        isUpdateOpen: true
      };
    }

    case MODAL_VENUES_CLOSE:
      return {
        isUpdateOpen: false
      };
    default:
      return { ...state };
  }
};
