import {
  CREATE_CUSTOMER_CARD,
  CREATE_CUSTOMER_CARD_FAILED,
  CREATE_CUSTOMER_CARD_SUCCESS,
  READ_CUSTOMER_CARDS,
  READ_CUSTOMER_CARDS_FAILED,
  READ_CUSTOMER_CARDS_SUCCESS,
  DELETE_CUSTOMER_CARD,
  DELETE_CUSTOMER_CARD_FAILED,
  DELETE_CUSTOMER_CARD_SUCCESS
} from '../../constants/actionTypes';

export const createCustomerCard = (customerID, payload) => ({ payload, customerID, type: CREATE_CUSTOMER_CARD });
export const createCustomerCardFailed = (payload) => ({ payload, type: CREATE_CUSTOMER_CARD_FAILED });
export const createCustomerCardSuccess = (payload) => ({ payload, type: CREATE_CUSTOMER_CARD_SUCCESS });

export const readCustomerCards = (customerID, payload) => ({ payload, customerID, type: READ_CUSTOMER_CARDS });
export const readCustomerCardsFailed = (payload) => ({ payload, type: READ_CUSTOMER_CARDS_FAILED });
export const readCustomerCardsSuccess = (payload) => ({ payload, type: READ_CUSTOMER_CARDS_SUCCESS });

export const deleteCustomerCard = (customerID, _id) => ({ _id, customerID, type: DELETE_CUSTOMER_CARD });
export const deleteCustomerCardFailed = (payload) => ({ payload, type: DELETE_CUSTOMER_CARD_FAILED });
export const deleteCustomerCardSuccess = (payload) => ({ payload, type: DELETE_CUSTOMER_CARD_SUCCESS });
