import React from 'react';
import propTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

const CustomBadge = ({ label, variant = 'success', includeDot = true }) => {
  return (
    <Badge pill className={`bg-soft-${variant} text-${variant}`} variant={variant}>
      { includeDot && '\u2022'} {label}
    </Badge>
  );
};

CustomBadge.propTypes = {
  label: propTypes.string.isRequired,
  variant: propTypes.string
};

export default CustomBadge;
