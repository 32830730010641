import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import currencyFormatter from 'currency-formatter';
import Badge from '../components/Badge';

export const formatCurrency = (number = 0, currency = 'L') => {
  const num = number.toFixed(2);

  let [integer, decimal] = num.split('.');

  let regx = /(\d+)(\d{3})/;

  while (regx.test(integer)) {
    integer = integer.replace(regx, `$1,$2`);
  }

  return `${currency} ${integer}.${decimal}`;
};

export const thousandsFormat = (value = 0) => {
  let regx = /(\d+)(\d{3})/;

  return String(value).replace(regx, `$1,$2`);
};

export const AmountFormat = ({ value = 0, currency = 'HNL' }) => {
  return currencyFormatter.format(value, { code: currency });
};

export const AccountsIsActiveBadge = ({ status }) => {
  const stateColor = status ? 'success' : 'danger';
  const accountState = status ? 'Activo' : 'Inactivo';

  return <Badge variant={stateColor} label={accountState} />;
};
export const PosLinkStatusBadge = ({ status }) => {
  let variant;
  let label = t(`prompts.statuses.paymentLinks.${status.toLowerCase()}`);

  switch (status) {
    case 'EXPIRED':
      variant = 'warning';
      break;
    case 'CANCELED':
      variant = 'danger';
      break;
    case 'PENDING':
      variant = 'dark';
      break;
    default:
      variant = 'success';
      break;
  }

  return <Badge variant={variant} label={label} />;
};
export const TransactionsStatusBadge = ({ status }) => {
  const { t } = useTranslation();
  let label = t(`prompts.statuses.transactions.${status.toLowerCase()}`);
  let variant = 'success';

  switch (status) {
    case 'PENDING':
      variant = 'dark';
      break;
    case 'DENIED':
      variant = 'danger';
      break;
    case 'CANCELED':
      variant = 'warning';
      break;
    case 'CHARGEBACK':
      variant = 'danger';
      break;
    default:
      break;
  }

  return <Badge label={label} variant={variant} />;
};
export const TransactionsChargebackStatusBadge = ({ status }) => {
  let label = '';
  let variant = 'success';
  const { t } = useTranslation();

  switch (status) {
    case 'PENDING':
      label = t('prompts.statuses.transactions.onClosure');
      variant = 'secondary';
      break;
    case 'PAID':
      label = t('prompts.statuses.transactions.paidClosure');
      variant = 'success';
      break;
    default:
      break;
  }

  return <Badge label={label} variant={variant} />;
};
export const KountStatusBadge = ({ device, authorization }) => {
  let label = t('transactions.statusOptions.approved');
  let variant = 'success';

  if (device !== 'Y' || authorization !== 'A') {
    label = t('transactions.statusOptions.denied');
    variant = 'danger';
  }

  return <Badge variant={variant} label={label} />;
};
export const RecurringChargeStatusBadge = ({ status }) => {
  switch (status) {
    case 'PENDING':
      return (
        <Badge
          variant="dark"
          label={t('subscriptions.recurringCharge.tableColumns.pending')}
        />
      );
    case 'FAILED':
    case 'CANCELED':
      return (
        <Badge
          variant="danger"
          label={t('subscriptions.recurringCharge.tableColumns.failed')}
        />
      );
    case 'COMPLETED':
    case 'FLOATING':
    default:
      return (
        <Badge
          variant="success"
          label={t('subscriptions.recurringCharge.tableColumns.processed')}
        />
      );
  }
};
export const LiquidationsStatusBadge = ({ status }) => {
  const { t } = useTranslation();
  let label = t(`prompts.statuses.liquidations.${status.toLowerCase()}`);
  let variant = '';

  switch (status) {
    case 'PENDING':
      variant = 'dark';
      break;
    case 'COMPLETED':
      variant = 'success';
      break;
    case 'PAID':
      variant = 'success';
      break;
    case 'CANCELED':
      variant = 'warning';
      break;
    case 'INVOICED':
    default:
      variant = 'warning';
      break;
  }

  return <Badge label={label} variant={variant} />;
};
export const TransactionsCreditCardType = ({ paymentType = 'VISA' }) => {
  let creditCardBand;

  switch (paymentType) {
    case 'AMERICAN_EXPRESS':
      creditCardBand = 'amex';
      break;
    case 'DINERS_CLUB':
      creditCardBand = 'diners-club';
      break;
    default:
      creditCardBand = paymentType.toLowerCase();
      break;
  }

  return <i className={`fab fa-cc-${creditCardBand}`} />;
};

export const TransactionsWalletType = () => {
  return <i className={`fa fa-phone-square`} />;
};
export const FrequencyStatus = ({ frequency }) => {
  const { t } = useTranslation();
  
  const frequencyMap = {
    days: {
      1: t('subscriptions.recurringCharge.frequency.daily'),
      15: t('subscriptions.recurringCharge.frequency.biweekly')
    },
    weeks: {
      1: t('subscriptions.recurringCharge.frequency.weekly'),
    },
    months: {
      1: t('subscriptions.recurringCharge.frequency.monthly'),
      2: t('subscriptions.recurringCharge.frequency.bimonthly'),
      3: t('subscriptions.recurringCharge.frequency.trimonthly'),
      4: t('subscriptions.recurringCharge.frequency.quarterly'),
      6: t('subscriptions.recurringCharge.frequency.biannual')
    },
    years: {
      1: t('subscriptions.recurringCharge.frequency.annual')
    }
  };

  let frequencyState = t('subscriptions.recurringCharge.frequency.monthly');

  if (!frequency) frequencyState = 'N/D';

  const { quantity, unit } = frequency;

  const isNormalFrequency = frequencyMap[unit] && frequencyMap[unit][quantity];

  if (isNormalFrequency) frequencyState = frequencyMap[unit][quantity];

  const isVariableQuantity =
    (unit === 'days' && ![1, 15].includes(quantity)) ||
    (unit === 'months' && ![1, 2, 3, 4, 6].includes(quantity)) ||
    (unit === 'years' && quantity !== 1);

  if (isVariableQuantity) frequencyState = frequencyMap[unit][1];

  return (<>{frequencyState}</>);
};
export const PromotionsBadge = ({ status }) => {
  const stateColor = status ? 'success' : 'danger';
  const promotionState = status ? t('settings.promotions.tableColumns.status.active') : t('settings.promotions.tableColumns.status.inactive');

  return <Badge variant={stateColor} label={promotionState} includeDot={false} />;
};

