import {
  SET_COUNT_TRANSACTIONS,
  SET_COUNT_WALLET_TRANSACTIONS,
  GET_TRANSACTIONS_TO_EXPORT,
  GET_TRANSACTIONS_TO_EXPORT_FAILED,
  GET_TRANSACTIONS_TO_EXPORT_SUCCESS,
  GET_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_FAILED,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_WALLET_TRANSACTIONS,
  GET_ALL_WALLET_TRANSACTIONS_FAILED,
  GET_ALL_WALLET_TRANSACTIONS_SUCCESS,
  REVERT_TRANSACTION,
  REVERT_TRANSACTION_FAILED,
  REVERT_TRANSACTION_SUCCESS,
  GET_ONE_TRANSACTION,
  GET_ONE_TRANSACTION_FAILED,
  GET_ONE_TRANSACTION_SUCCESS,
  REPORT_COMMISSIONS_TRANSACTIONS,
  REPORT_COMMISSIONS_TRANSACTIONS_FAILED,
  REPORT_COMMISSIONS_TRANSACTIONS_SUCCESS,
  CHARGEBACK,
  CHARGEBACK_SUCCESS,
  CHARGEBACK_FAILED,
  GET_PENDING_CLOSURE_TRANSACTIONS,
  GET_PENDING_CLOSURE_TRANSACTIONS_FAILED,
  GET_PENDING_CLOSURE_TRANSACTIONS_SUCCESS,
  RECALCULATE_TRANSACTIONS_FEES,
  RECALCULATE_TRANSACTIONS_FEES_FAILED,
  RECALCULATE_TRANSACTIONS_FEES_SUCCESS
} from '../../constants/actionTypes';

const INIT_COUNT_TRANSACTIONS_STATE = {
  totalTransactions: 0,
  amount: 0
};

const INIT_COUNT_WALLET_TRANSACTIONS_STATE = {
  totalWalletTransactions: 0
};

const INIT_TRANSACTIONS_TO_EXPORT_STATE = {
  loading: false,
  error: undefined,
  transactions: undefined
};

const INIT_REVERT_STATE = {
  loading: false,
  error: undefined,
  transaction: undefined
};
const INIT_CHARGEBACK_STATE = {
  loading: false,
  error: undefined,
  transaction: undefined
};

const INIT_STATE_GET_ALL_TRANSACTIONS = {
  loading: false,
  error: undefined,
  transactions: undefined
};

const INIT_STATE_GET_ALL_WALETS_TRANSACTIONS = {
  loading: false,
  error: undefined,
  walletsTransactions: undefined
};

const INIT_GET_ONE_STATE = {
  loading: false,
  transaction: {}
};

const INIT_REPORT_STATE = {
  loading: false,
  report: undefined,
  error: undefined
};

const INIT_STATE_GET_PENDING_CLOSURE_TRANSACTIONS = {
  loading: false,
  error: undefined,
  pendingClosure: undefined
};

const INIT_STATE_RECALCULATE_TRANSACTIONS_FEES = {
  loading: false,
  error: undefined,
  result: undefined
};

export const CountTransactions = (
  state = INIT_COUNT_TRANSACTIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case SET_COUNT_TRANSACTIONS: {
      const { rows, amount } = payload;
      return {
        totalTransactions: rows,
        amount
      };
    }
    default:
      return { ...state };
  }
};

export const CountWalletTransactions = (
  state = INIT_COUNT_WALLET_TRANSACTIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case SET_COUNT_WALLET_TRANSACTIONS: {
      const { rows } = payload;
      return {
        totalWalletTransactions: rows
      };
    }
    default:
      return { ...state };
  }
};

export const GetTransactionsToExport = (
  state = INIT_TRANSACTIONS_TO_EXPORT_STATE,
  { type, payload }
) => {
  switch (type) {
    case GET_TRANSACTIONS_TO_EXPORT:
      return {
        ...state,
        loading: true,
        error: undefined,
        transactions: undefined
      };
    case GET_TRANSACTIONS_TO_EXPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        transactions: payload
      };
    case GET_TRANSACTIONS_TO_EXPORT_FAILED:
      return {
        ...state,
        error: payload,
        loading: false,
        transactions: undefined
      };
    default:
      return state;
  }
};

export const GetAllTransactions = (
  state = INIT_STATE_GET_ALL_TRANSACTIONS,
  { type, payload }
) => {
  switch (type) {
    case GET_ALL_TRANSACTIONS:
      return {
        ...state,
        loading: true,
        error: undefined,
        transactions: undefined
      };
    case GET_ALL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        transactions: payload
      };
    case GET_ALL_TRANSACTIONS_FAILED:
      return {
        ...state,
        error: payload,
        loading: false,
        transactions: undefined
      };
    default:
      return { ...state };
  }
};

export const GetAllWalletTransactions = (
  state = INIT_STATE_GET_ALL_WALETS_TRANSACTIONS,
  { type, payload }
) => {
  switch (type) {
    case GET_ALL_WALLET_TRANSACTIONS:
      return {
        ...state,
        loading: true,
        error: undefined,
        walletsTransactions: undefined
      };
    case GET_ALL_WALLET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        walletsTransactions: payload
      };
    case GET_ALL_WALLET_TRANSACTIONS_FAILED:
      return {
        ...state,
        error: payload,
        loading: false,
        walletsTransactions: undefined
      };
    default:
      return { ...state };
  }
};

export const RevertTransaction = (
  state = INIT_REVERT_STATE,
  { type, payload }
) => {
  switch (type) {
    case REVERT_TRANSACTION:
      return {
        ...state,
        loading: true,
        error: undefined,
        transaction: undefined
      };
    case REVERT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        transaction: payload
      };
    case REVERT_TRANSACTION_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        transaction: undefined
      };
    default:
      return { ...state };
  }
};

export const ChargeBack = (state = INIT_CHARGEBACK_STATE, action) => {
  switch (action.type) {
    case CHARGEBACK:
      return {
        ...state,
        loading: true,
        error: undefined,
        transaction: undefined
      };
    case CHARGEBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        transaction: action.payload
      };
    case CHARGEBACK_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        transaction: undefined
      };
    default:
      return state;
  }
};

export const GetTransaction = (state = INIT_GET_ONE_STATE, action) => {
  switch (action.type) {
    case GET_ONE_TRANSACTION:
      return { ...state, loading: true };
    case GET_ONE_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: action.payload,
        loading: false,
        error: null
      };
    case GET_ONE_TRANSACTION_FAILED:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};

export const GetReportCommissionsTransactions = (
  state = INIT_REPORT_STATE,
  { type, payload }
) => {
  switch (type) {
    case REPORT_COMMISSIONS_TRANSACTIONS:
      return {
        loading: true,
        error: undefined,
        report: undefined
      };
    case REPORT_COMMISSIONS_TRANSACTIONS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        report: payload
      };
    case REPORT_COMMISSIONS_TRANSACTIONS_FAILED:
      return {
        error: payload,
        loading: false,
        report: undefined
      };
    default:
      return { ...state };
  }
};

export const GetPendingClosureTransactions = (
  state = INIT_STATE_GET_PENDING_CLOSURE_TRANSACTIONS,
  { type, payload }
) => {
  switch (type) {
    case GET_PENDING_CLOSURE_TRANSACTIONS:
      return {
        loading: true,
        error: undefined,
        pendingClosure: undefined
      };
    case GET_PENDING_CLOSURE_TRANSACTIONS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        pendingClosure: payload
      };
    case GET_PENDING_CLOSURE_TRANSACTIONS_FAILED:
      return {
        loading: true,
        error: payload,
        pendingClosure: undefined
      };
    default:
      return { ...state };
  }
};

export const RecalculateTransactionsFees = (
  state = INIT_STATE_RECALCULATE_TRANSACTIONS_FEES,
  { type, payload }
) => {
  switch (type) {
    case RECALCULATE_TRANSACTIONS_FEES:
      return {
        loading: true,
        error: undefined,
        result: undefined
      };
    case RECALCULATE_TRANSACTIONS_FEES_SUCCESS:
      return {
        loading: false,
        error: undefined,
        result: payload
      };
    case RECALCULATE_TRANSACTIONS_FEES_FAILED:
      return {
        loading: false,
        error: payload,
        result: undefined
      };
    default:
      return { ...state };
  }
};
