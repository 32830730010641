import fetchJSON from '../../utils/fetchJSON';
import { createMessageNotify } from '../../utils/notify';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { createNotification, NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify';

import {
  READ_ISSUER_BINS,
  SEARCH_ISSUER_BINS,
  CREATE_ISSUER_BINS,
  UPDATE_ISSUER_BINS,
  DELETE_ISSUER_BINS,
  UPDATE_STATUS_ISSUER_BINS
} from '../../constants/actionTypes';

import {
  readBanks,
  setCountIssuerBins,
  createIssuerBinsFailed,
  createIssuerBinsSuccess,
  searchIssuerBinsFailed,
  searchIssuerBinsSuccess,
  readIssuerBinsFailed,
  readIssuerBinsSuccess,
  updateStatusIssuerBinsFailed,
  updateStatusIssuerBinsSuccess,
  updateIssuerBinsFailed,
  updateIssuerBinsSuccess,
  deleteIssuerBinsFailed,
  deleteIssuerBinsSuccess
} from './../actions';

function* createIssuerBins({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/iin', options);

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'BIN bancario creado correctamente')));
    yield put(createIssuerBinsSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(createIssuerBinsFailed(message));
  }
}

function* searchIssuerBins({ payload: params }) {
  const { bin = '', currentBanks = [] } = params;
  const options = {
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/iin/search/' + bin, options);
    const issuerBinFound = Object.keys(response).length > 2;
    yield put(searchIssuerBinsSuccess({ issuerBinFound, issuerBin: response }));

    const { bank = {} } = response;
    const { _id = '' } = bank;
    const exists = currentBanks.filter((bank) => bank._id === _id);

    if (exists.length === 0) {
      yield put(readBanks());
    }
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(searchIssuerBinsFailed(message));
  }
}

function* readIssuerBins({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/iin', options);
    const { rows = 0, data } = response;
    yield put(readIssuerBinsSuccess(data || response));
    yield put(setCountIssuerBins(rows));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(readIssuerBinsFailed(message));
  }
}

function* updateStatusIssuerBins({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/iin/${_id}/state`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'BIN bancario actualizado correctamente')));
    yield put(updateStatusIssuerBinsSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(updateStatusIssuerBinsFailed(message));
  }
}

function* updateIssuerBins({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/iin/${_id}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'BIN bancario actualizado correctamente')));
    yield put(updateIssuerBinsSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(updateIssuerBinsFailed(message));
  }
}

function* deleteBankBins({ _id, payload: data }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/iin/${_id}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, ' BIN bancario eliminado  correctamente')));
    yield put(deleteIssuerBinsSuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(deleteIssuerBinsFailed(message));
  }
}

export function* watchCreateIssuerBins() {
  yield takeEvery(CREATE_ISSUER_BINS, createIssuerBins);
}

export function* watchSearchIssuerBins() {
  yield takeEvery(SEARCH_ISSUER_BINS, searchIssuerBins);
}

export function* watchReadIssuerBins() {
  yield takeEvery(READ_ISSUER_BINS, readIssuerBins);
}

export function* watchUpdateStatusIssuerBins() {
  yield takeEvery(UPDATE_STATUS_ISSUER_BINS, updateStatusIssuerBins);
}

export function* watchUpdateIssuerBins() {
  yield takeEvery(UPDATE_ISSUER_BINS, updateIssuerBins);
}

export function* watchDeleteIssuerBins() {
  yield takeEvery(DELETE_ISSUER_BINS, deleteBankBins);
}

function* IssuerBinsSaga() {
  yield all([
    fork(watchReadIssuerBins),
    fork(watchCreateIssuerBins),
    fork(watchUpdateIssuerBins),
    fork(watchDeleteIssuerBins),
    fork(watchSearchIssuerBins),
    fork(watchUpdateStatusIssuerBins)
  ]);
}

export default IssuerBinsSaga;
