/** CUSTOMERS **/
export const COUNT_CUSTOMERS = 'COUNT_CUSTOMERS';
export const COUNT_CUSTOMERS_FAILED = 'COUNTCUSTOMERS_FAILED';
export const COUNT_CUSTOMERS_SUCCESS = 'COUNT_CUSTOMERS_SUCCESS';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';

export const READ_CUSTOMERS = 'READ_CUSTOMERS';
export const READ_CUSTOMERS_FAILED = 'READ_CUSTOMERS_FAILED';
export const READ_CUSTOMERS_SUCCESS = 'READ_CUSTOMERS_SUCCESS';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_FAILED = 'DELETE_CUSTOMER_FAILED';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
