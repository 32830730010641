import {
  SET_COUNT_COUNTRIES,
  CREATE_COUNTRY,
  CREATE_COUNTRY_FAILED,
  CREATE_COUNTRY_SUCCESS,
  RESET_SEARCH_COUNTRY,
  SEARCH_COUNTRY,
  SEARCH_COUNTRY_FAILED,
  SEARCH_COUNTRY_SUCCESS,
  READ_COUNTRIES,
  READ_COUNTRIES_FAILED,
  READ_COUNTRIES_SUCCESS,
  UPDATE_COUNTRY,
  UPDATE_COUNTRY_FAILED,
  UPDATE_COUNTRY_SUCCESS,
  DELETE_COUNTRY,
  DELETE_COUNTRY_FAILED,
  DELETE_COUNTRY_SUCCESS
} from '../../constants/actionTypes';

const INIT_COUNT_COUNTRIES = {
  totalCountries: 0
};

const INIT_SEARCH_COUNTRY_STATE = {
  loading: false,
  error: undefined,
  currentCountry: undefined
};

const INIT_CREATE_COUNTRY_STATE = {
  loading: false,
  error: undefined,
  countries: undefined
};
const INIT_READ_COUNTRIES_STATE = {
  loading: false,
  error: undefined,
  countries: undefined
};

export const CreateCountry = (
  state = INIT_CREATE_COUNTRY_STATE,
  { type, payload }
) => {
  switch (type) {
    case CREATE_COUNTRY:
      return {
        loading: true,
        error: undefined,
        country: undefined
      };
    case CREATE_COUNTRY_FAILED:
      return {
        loading: false,
        error: payload,
        country: undefined
      };
    case CREATE_COUNTRY_SUCCESS:
      return {
        loading: false,
        error: undefined,
        country: payload
      };
    default:
      return state;
  }
};

export const CountCountries = (
  state = INIT_COUNT_COUNTRIES,
  { type, payload }
) => {
  switch (type) {
    case SET_COUNT_COUNTRIES:
      return {
        totalCountries: payload
      };
    default:
      return state;
  }
};

export const SearchCountry = (
  state = INIT_SEARCH_COUNTRY_STATE,
  { type, payload }
) => {
  switch (type) {
    case SEARCH_COUNTRY:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case SEARCH_COUNTRY_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
        currentCountry: undefined
      };
    case SEARCH_COUNTRY_SUCCESS: {
      const { country } = payload;
      return {
        ...state,
        loading: false,
        error: undefined,
        currentCountry: country
      };
    }
    case RESET_SEARCH_COUNTRY:
      return { ...INIT_SEARCH_COUNTRY_STATE };
    default:
      return state;
  }
};

export const ReadCountries = (
  state = INIT_READ_COUNTRIES_STATE,
  { type, payload }
) => {
  switch (type) {
    case READ_COUNTRIES:
      return {
        loading: true,
        error: undefined,
        countries: undefined
      };
    case READ_COUNTRIES_FAILED:
      return {
        loading: false,
        error: payload,
        countries: undefined
      };
    case READ_COUNTRIES_SUCCESS:
      return {
        loading: false,
        error: undefined,
        countries: payload
      };
    default:
      return state;
  }
};
export const UpdateCountry = (
  state = INIT_CREATE_COUNTRY_STATE,
  { type, payload }
) => {
  switch (type) {
    case UPDATE_COUNTRY:
      return {
        loading: true,
        error: undefined,
        country: undefined
      };
    case UPDATE_COUNTRY_FAILED:
      return {
        loading: false,
        error: payload,
        country: undefined
      };
    case UPDATE_COUNTRY_SUCCESS:
      return {
        loading: false,
        error: undefined,
        country: payload
      };
    default:
      return state;
  }
};
export const DeleteCountry = (
  state = INIT_CREATE_COUNTRY_STATE,
  { type, payload }
) => {
  switch (type) {
    case DELETE_COUNTRY:
      return {
        loading: true,
        error: undefined,
        country: undefined
      };
    case DELETE_COUNTRY_FAILED:
      return {
        loading: false,
        error: payload,
        country: undefined
      };
    case DELETE_COUNTRY_SUCCESS:
      return {
        loading: false,
        error: undefined,
        country: payload
      };
    default:
      return state;
  }
};
