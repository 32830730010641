import fetchJSON from '../../utils/fetchJSON';
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { createNotification, NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_ERROR } from 'react-redux-notify';
import { createMessageNotify } from './../../utils/notify';

import { CREATE_COUNTRY, SEARCH_COUNTRY, READ_COUNTRIES, UPDATE_COUNTRY, DELETE_COUNTRY } from '../../constants/actionTypes';

import {
  createCountryFailed,
  createCountrySuccess,
  searchCountryFailed,
  searchCountrySuccess,
  setCountCountries,
  readCountriesFailed,
  readCountriesSuccess,
  updateCountryFailed,
  updateCountrySuccess,
  deleteCountryFailed,
  deleteCountrySuccess
} from './actions';

function* createCountry({ payload: data }) {
  const options = {
    data,
    method: 'POST'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/countries', options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'País creado correctamente')));
    yield put(createCountrySuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }

    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(createCountryFailed(message));
  }
}

function* searchContry({ payload }) {
  const { country = '' } = payload;
  const options = {
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/countries/search/${country}`, options);
    yield put(searchCountrySuccess({ country: response }));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(searchCountryFailed(message));
  }
}

function* readCountries({ payload: params }) {
  const options = {
    params,
    method: 'GET'
  };

  try {
    const response = yield call(fetchJSON, '/v2/admin/countries', options);
    const { rows = 0, data } = response;
    yield put(readCountriesSuccess(data || response));
    yield put(setCountCountries(rows));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(readCountriesFailed(message));
  }
}

function* updateCountry({ _id, payload: data }) {
  const options = {
    data,
    method: 'PUT'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/countries/${_id}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'País actualizado correctamente')));
    yield put(updateCountrySuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(updateCountryFailed(message));
  }
}

function* deleteCountry({ _id, payload: data }) {
  const options = {
    data,
    method: 'DELETE'
  };

  try {
    const response = yield call(fetchJSON, `/v2/admin/countries/${_id}`, options);
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_SUCCESS, 'País actualizado correctamente')));
    yield put(deleteCountrySuccess(response));
  } catch (error) {
    let message;

    switch (error.status) {
      case 401:
        message = 'Invalid credentials';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      default:
        message = error.message || 'Not method';
    }
    yield put(createNotification(createMessageNotify(NOTIFICATION_TYPE_ERROR, message, true)));
    yield put(deleteCountryFailed(message));
  }
}

export function* watchCreateCountry() {
  yield takeEvery(CREATE_COUNTRY, createCountry);
}

export function* watchSearchCountry() {
  yield takeEvery(SEARCH_COUNTRY, searchContry);
}

export function* watchReadCountries() {
  yield takeEvery(READ_COUNTRIES, readCountries);
}

export function* watchUpdateCountry() {
  yield takeEvery(UPDATE_COUNTRY, updateCountry);
}

export function* watchDeleteCountry() {
  yield takeEvery(DELETE_COUNTRY, deleteCountry);
}

function* CountriesSaga() {
  yield all([fork(watchCreateCountry), fork(watchReadCountries), fork(watchSearchCountry), fork(watchUpdateCountry), fork(watchDeleteCountry)]);
}

export default CountriesSaga;
