/** SUBSCRIPTIONS **/
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_FAILED = 'CREATE_SUBSCRIPTION_FAILED';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';

export const READ_SUBSCRIPTION_ID = 'READ_SUBSCRIPTION_ID';
export const READ_SUBSCRIPTION_ID_FAILED = 'READ_SUBSCRIPTION_ID_FAILED';
export const READ_SUBSCRIPTION_ID_SUCCESS = 'READ_SUBSCRIPTION_ID_SUCCESS';

export const READ_SUBSCRIPTION_ID_CALENDAR = 'READ_SUBSCRIPTION_ID_CALENDAR';
export const READ_SUBSCRIPTION_ID_CALENDAR_FAILED = 'READ_SUBSCRIPTION_ID_CALENDAR_FAILED';
export const READ_SUBSCRIPTION_ID_CALENDAR_SUCCESS = 'READ_SUBSCRIPTION_ID_CALENDAR_SUCCESS';

export const READ_SUBSCRIPTION_ID_CALENDAR_DETAILS = 'READ_SUBSCRIPTION_ID_CALENDAR_DETAILS';
export const READ_SUBSCRIPTION_ID_CALENDAR_DETAILS_FAILED = 'READ_SUBSCRIPTION_ID_CALENDAR_DETAILS_FAILED';
export const READ_SUBSCRIPTION_ID_CALENDAR_DETAILS_SUCCESS = 'READ_SUBSCRIPTION_ID_CALENDAR_DETAILS_SUCCESS';

export const READ_ALL_SUBSCRIPTIONS = 'READ_SUBSCRIPTIONS';
export const READ_ALL_SUBSCRIPTIONS_FAILED = 'READ_SUBSCRIPTIONS_FAILED';
export const READ_ALL_SUBSCRIPTIONS_SUCCESS = 'READ_SUBSCRIPTIONS_SUCCESS';

export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_FAILED = 'UPDATE_SUBSCRIPTION_FAILED';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';

export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION_FAILED = 'DELETE_SUBSCRIPTION_FAILED';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
