import {
  CREATE_USER_ACCOUNT,
  CREATE_USER_ACCOUNT_FAILED,
  CREATE_USER_ACCOUNT_SUCCESS,
  READ_USERS_ACCOUNT,
  READ_USERS_ACCOUNT_FAILED,
  READ_USERS_ACCOUNT_SUCCESS,
  READ_USER_ACCOUNT_ID,
  READ_USER_ACCOUNT_ID_FAILED,
  READ_USER_ACCOUNT_ID_SUCCESS,
  UPDATE_USER_ACCOUNT,
  UPDATE_USER_ACCOUNT_FAILED,
  UPDATE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_ACCOUNT,
  DELETE_USER_ACCOUNT_FAILED,
  DELETE_USER_ACCOUNT_SUCCESS,
  UNLOCK_USER_ACCOUNT,
  UNLOCK_USER_ACCOUNT_FAILED,
  UNLOCK_USER_ACCOUNT_SUCCESS
} from '../../constants/actionTypes';

export const createUserAccount = (payload) => ({ payload, type: CREATE_USER_ACCOUNT });
export const createUserAccountFailed = (payload) => ({ payload, type: CREATE_USER_ACCOUNT_FAILED });
export const createUserAccountSuccess = (payload) => ({ payload, type: CREATE_USER_ACCOUNT_SUCCESS });

export const readUsersAccount = (payload) => ({ payload, type: READ_USERS_ACCOUNT });
export const readUsersAccountFailed = (payload) => ({ payload, type: READ_USERS_ACCOUNT_FAILED });
export const readUsersAccountSuccess = (payload) => ({ payload, type: READ_USERS_ACCOUNT_SUCCESS });

export const readUserAccountID = (_id, payload) => ({ _id, payload, type: READ_USER_ACCOUNT_ID });
export const readUserAccountIDFailed = (payload) => ({ payload, type: READ_USER_ACCOUNT_ID_FAILED });
export const readUserAccountIDSuccess = (payload) => ({ payload, type: READ_USER_ACCOUNT_ID_SUCCESS });

export const updateUserAccount = (_id, payload) => ({ _id, payload, type: UPDATE_USER_ACCOUNT });
export const updateUserAccountFailed = (payload) => ({ payload, type: UPDATE_USER_ACCOUNT_FAILED });
export const updateUserAccountSuccess = (payload) => ({ payload, type: UPDATE_USER_ACCOUNT_SUCCESS });

export const deleteUserAccount = (_id, payload) => ({ _id, payload, type: DELETE_USER_ACCOUNT });
export const deleteUserAccountFailed = (payload) => ({ payload, type: DELETE_USER_ACCOUNT_FAILED });
export const deleteUserAccountSuccess = (payload) => ({ payload, type: DELETE_USER_ACCOUNT_SUCCESS });

export const unlockUserAccount = (_id, payload) => ({ _id, payload, type: UNLOCK_USER_ACCOUNT });
export const unlockUserAccountFailed = (payload) => ({ payload, type: UNLOCK_USER_ACCOUNT_FAILED });
export const unlockUserAccountSuccess = (payload) => ({ payload, type: UNLOCK_USER_ACCOUNT_SUCCESS });
