import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { IBasicAccount, IUserAccount, IUserAccountContext } from '../interfaces/';
import { LanguageEnum } from '../enums/UserAccount';
import { updateUserAccount } from '../api/userAccount';
import {
  clearCookies,
  getCookie,
  setCookie
} from '../../helpers/browserCookieManager';
import i18n from 'i18next';

const UserAccountContext = createContext<IUserAccountContext>({
  isLoading: false,
  userLanguage: LanguageEnum.Spanish,
  changeLanguage: async () => {},
  getStoredUserData: () => {},
  clearStoredUserData: () => {}
});

export const useUserAccount = () => useContext(UserAccountContext);

export const UserAccountProvider: React.FC<any> = ({ children }) => {
  const [user, setUser] = useState<IUserAccount>();
  const [account, setAccount] = useState<IBasicAccount>();
  const [userLanguage, setUserLanguage] = useState<LanguageEnum>(
    LanguageEnum.Spanish
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const storedUserData = getStoredUserData();

    if (storedUserData) {
      const { account, ...userData } = storedUserData;

      setUserLanguage(userData?.language ?? 'es');

      setUser(userData);
      setAccount(account);
    }
  }, []);

  const getStoredUserData = () => {
    return getCookie('user');
  };
  const clearStoredUserData = () => {
    //dispatch(logoutUser());

    clearCookies();

    localStorage.removeItem('refreshNotification');

    const timer = setTimeout(() => {
      window.location.replace('/auth/login');
    }, 1500);

    return () => clearTimeout(timer);
  };
  const changeLanguage = async (language: LanguageEnum) => {
    setIsLoading(true);

    try {
      if (!user) {
        throw new Error('User is missing');
      }

      await updateUserAccount(user._id, { language });
      await i18n.changeLanguage(language);

      setCookie('user', { ...user, account, language });
      setUserLanguage(language);
    } finally {
      setIsLoading(false);
    }
  };

  const memoizedValue = useMemo(() => {
    return {
      user,
      account,
      isLoading,
      userLanguage
    };
  }, [user, account, isLoading, userLanguage]);

  return (
    <UserAccountContext.Provider
      value={{
        ...memoizedValue,
        changeLanguage,
        getStoredUserData,
        clearStoredUserData
      }}>
      {children}
    </UserAccountContext.Provider>
  );
};
